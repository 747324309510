import React, { useEffect, useState } from "react";
import "./UserProfile.css";
import { RootState, actions, useAppSelector } from "../../store/root.store";
import { Link } from "react-router-dom";

const UserProfile: React.FC = () => {
  const [showError, setShowError] = useState<boolean>(false);
  const user = useAppSelector((state: RootState) => state.auth.user);
  const loading = useAppSelector((state: RootState) => state.auth.loading);
  const error = useAppSelector((state: RootState) => state.auth.error);

  useEffect(() => {
    actions.auth.clearAuthError();

    return () => {
      actions.auth.clearAuthError();
    };
  }, []);

  useEffect(() => {
    const handleGetUserProfile = async () => {
      if (user === null) {
        const res = await actions.auth.fetchStandardInfo();
        if (!res.success) {
          setShowError(true);
        }
      }
    };

    handleGetUserProfile();
  }, [user]);

  if (showError) {
    return (
      <div>
        <span>{error}</span> <Link to="/">Click here to go to home</Link>
      </div>
    );
  }

  if (loading || user === null) {
    return <>Loading ...</>;
  }

  return (
    <div className="user-profile-container">
      <div className="profile-header">
        <img
          src="https://t4.ftcdn.net/jpg/00/64/67/27/360_F_64672736_U5kpdGs9keUll8CRQ3p3YaEv2M6qkVY5.jpg"
          alt="default profile"
        />
        <div className="profile-header-title">
          <h2>{`${user.profile.firstName} ${user.profile.lastName}`}</h2>
          <span>{user.email}</span>
        </div>
      </div>
      <div className="profile-control">
        <div className="profile-role">{`Role: ${user.role}`}</div>
        <div className="profile-edit">Edit Profile</div>
      </div>
      <hr className="divider" />
      <div className="profile-trophies">
        <h3>Trophies</h3>
        <div className="trophies-list">
          <img
            src="https://t4.ftcdn.net/jpg/00/64/67/27/360_F_64672736_U5kpdGs9keUll8CRQ3p3YaEv2M6qkVY5.jpg"
            alt="trophy item 1"
          />
          <img
            src="https://t4.ftcdn.net/jpg/00/64/67/27/360_F_64672736_U5kpdGs9keUll8CRQ3p3YaEv2M6qkVY5.jpg"
            alt="trophy item 2"
          />
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
