import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector, RootState } from "../../store/root.store";

const UnAuthProtectedRoutes = () => {
  const notAuthenticated = useAppSelector(
    (state: RootState) => !state.auth.isAuthenticated
  );

  return notAuthenticated ? (
    <Outlet></Outlet>
  ) : (
    <Navigate to={`/`}></Navigate>
  );
};

export default UnAuthProtectedRoutes;
