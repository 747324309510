import React, { useCallback, useMemo, useState } from "react";
import { Descendant, createEditor } from "slate";
import { Editable, RenderElementProps, Slate, withReact } from "slate-react";
import "./InputField.css";

interface InputFieldProps {
  initialValue: Descendant[];
  onChange: (value: Descendant[]) => void;
  title: string;
  placeholder: string;
}
const InputField: React.FC<InputFieldProps> = ({
  initialValue,
  onChange,
  title,
  placeholder,
}) => {
  const editor = useMemo(() => withReact(createEditor()), []);
  const [value, setValue] = useState<Descendant[]>(initialValue);

  const renderElement = useCallback((props: RenderElementProps) => {
    const { attributes, children } = props;
    return <p {...attributes}>{children}</p>;
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <Slate
      editor={editor}
      initialValue={value}
      onChange={(newValue) => {
        setValue(newValue);
        onChange(newValue);
      }}
    >
      <h4>{title}</h4>
      <Editable
        className="editor"
        onKeyDown={handleKeyDown}
        renderElement={renderElement}
        placeholder={placeholder}
        style={{
          overflowX: "auto",
          whiteSpace: "pre",
        }}
      ></Editable>
    </Slate>
  );
};

export default InputField;
