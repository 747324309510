import React, { useState, useRef, useEffect } from "react";
import {
  CompanySignupFormValues,
  validateCompanySignupForm,
  CompanySignupFormValidValues,
} from "./CompanyFormValidator";
import "../../components/Forms/styles/Form.css";
import "./CompanySignup.css";
import "boxicons/css/boxicons.min.css";
import ReCAPTCHA from "react-google-recaptcha";
import { Loader } from "../../components";
import { useNav } from "../../utils/navigation";

/**
 * Company signup page.
 *
 * @page
 * @example
 * (
 *   <CompanySignup />
 * )
 */

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string;

const CompanySignup: React.FC = () => {
  const nameRef = useRef<HTMLInputElement>(null);
  const [values, setValues] = useState<CompanySignupFormValues>({
    companyName: "",
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
  });
  const [focus, setFocus] = useState<
    Partial<Record<keyof CompanySignupFormValues, boolean>>
  >({});
  const [errors, setErrors] = useState<Partial<CompanySignupFormValues>>({});
  const [isValid, setIsValid] = useState<Partial<CompanySignupFormValidValues>>(
    {}
  );
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { goToAdminLoginPage } = useNav();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleRecaptchaChange = (token: string | null) => {
    setRecaptchaToken(token);
    console.log(token);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const validationResult = validateCompanySignupForm(values);

    let valid =
      Object.keys(validationResult.errors).length === 0 &&
      recaptchaToken !== null;

    if (valid) {
      handleSignup();
    } else {
      setShowErrors(true);
      setErrors(validationResult.errors);
      console.log("Invalid form");
    }
  };

  const handleSignup = async () => {
    setLoading(true);
  };

  useEffect(() => {
    if (nameRef.current) {
      nameRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const validationResult = validateCompanySignupForm(values);
    setErrors(validationResult.errors);
    setIsValid(validationResult.isValid);
  }, [values]);

  useEffect(() => {
    if (Object.values(focus).some((val) => val)) {
      setShowErrors(false);
    }
  }, [focus]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (loading) {
        setLoading(false);
        alert("Congratulations, your company has been successfully verified!");
        goToAdminLoginPage();
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [loading, goToAdminLoginPage]);

  return (
    <div className="form-container">
      <div className="login-form">
        <header>Company Signup</header>
        <form onSubmit={handleSubmit}>
          <div className="field input-field">
            <input
              type="text"
              ref={nameRef}
              required
              aria-invalid={errors.companyName ? "false" : "true"}
              aria-describedby="cnidnote"
              autoComplete="off"
              autoCorrect="off"
              name="companyName"
              placeholder="Company Name"
              onChange={handleChange}
              value={values.companyName}
              onFocus={() =>
                setFocus((prev) => ({ ...prev, companyName: true }))
              }
              onBlur={() =>
                setFocus((prev) => ({ ...prev, companyName: false }))
              }
            />
            <span
              id="cnidnote"
              className={`instructions ${
                (focus.companyName &&
                  values.companyName &&
                  !isValid.companyName) ||
                (values.companyName && showErrors)
                  ? "show"
                  : "hide"
              }`}
            >
              {errors.companyName}
            </span>
            {values.companyName && !errors.companyName && (
              <i className="bx bx-check-circle valid-icon"></i>
            )}
            {values.companyName && errors.companyName && (
              <i className="bx bx-x-circle invalid-icon"></i>
            )}
          </div>
          <div className="field input-field">
            <input
              type="text"
              required
              aria-invalid={errors.firstName ? "false" : "true"}
              aria-describedby="fnidnote"
              autoComplete="off"
              autoCorrect="off"
              name="firstName"
              placeholder="First Name"
              onChange={handleChange}
              value={values.firstName}
              onFocus={() => setFocus((prev) => ({ ...prev, firstName: true }))}
              onBlur={() => setFocus((prev) => ({ ...prev, firstName: false }))}
            />
            <span
              id="fnidnote"
              className={`instructions ${
                (focus.firstName && values.firstName && !isValid.firstName) ||
                (values.firstName && showErrors)
                  ? "show"
                  : "hide"
              }`}
            >
              {errors.firstName}
            </span>
            {values.firstName && !errors.firstName && (
              <i className="bx bx-check-circle valid-icon"></i>
            )}
            {values.firstName && errors.firstName && (
              <i className="bx bx-x-circle invalid-icon"></i>
            )}
          </div>
          <div className="field input-field">
            <input
              type="text"
              required
              aria-invalid={errors.lastName ? "false" : "true"}
              aria-describedby="lnidnote"
              autoComplete="off"
              autoCorrect="off"
              name="lastName"
              placeholder="Last Name"
              onChange={handleChange}
              value={values.lastName}
              onFocus={() => setFocus((prev) => ({ ...prev, lastName: true }))}
              onBlur={() => setFocus((prev) => ({ ...prev, lastName: false }))}
            />
            <span
              id="lnidnote"
              className={`instructions ${
                (focus.lastName && values.lastName && !isValid.lastName) ||
                (values.lastName && showErrors)
                  ? "show"
                  : "hide"
              }`}
            >
              {errors.lastName}
            </span>
            {values.lastName && !errors.lastName && (
              <i className="bx bx-check-circle valid-icon"></i>
            )}
            {values.lastName && errors.lastName && (
              <i className="bx bx-x-circle invalid-icon"></i>
            )}
          </div>
          <div className="field input-field">
            <input
              type="text"
              required
              aria-invalid={errors.email ? "false" : "true"}
              aria-describedby="eidnote"
              autoComplete="off"
              autoCorrect="off"
              name="email"
              placeholder="Email"
              onChange={handleChange}
              value={values.email}
              onFocus={() => setFocus((prev) => ({ ...prev, email: true }))}
              onBlur={() => setFocus((prev) => ({ ...prev, email: false }))}
            />
            <span
              id="eidnote"
              className={`instructions ${
                (focus.email && values.email && !isValid.email) ||
                (values.email && showErrors)
                  ? "show"
                  : "hide"
              }`}
            >
              {errors.email}
            </span>
            {values.email && !errors.email && (
              <i className="bx bx-check-circle valid-icon"></i>
            )}
            {values.email && errors.email && (
              <i className="bx bx-x-circle invalid-icon"></i>
            )}
          </div>
          <div className="field input-field">
            <input
              type="text"
              required
              aria-invalid={errors.confirmEmail ? "false" : "true"}
              aria-describedby="ceidnote"
              autoComplete="off"
              autoCorrect="off"
              name="confirmEmail"
              placeholder="Confirm Email"
              onChange={handleChange}
              value={values.confirmEmail}
              onFocus={() =>
                setFocus((prev) => ({ ...prev, confirmEmail: true }))
              }
              onBlur={() =>
                setFocus((prev) => ({ ...prev, confirmEmail: false }))
              }
            />
            <span
              id="ceidnote"
              className={`instructions ${
                (focus.confirmEmail &&
                  values.confirmEmail &&
                  !isValid.confirmEmail) ||
                (values.confirmEmail && showErrors)
                  ? "show"
                  : "hide"
              }`}
            >
              {errors.confirmEmail}
            </span>
            {values.confirmEmail && !errors.confirmEmail && (
              <i className="bx bx-check-circle valid-icon"></i>
            )}
            {values.confirmEmail && errors.confirmEmail && (
              <i className="bx bx-x-circle invalid-icon"></i>
            )}
          </div>
          <div className="recaptcha-container">
            <ReCAPTCHA
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={handleRecaptchaChange}
            ></ReCAPTCHA>
            <span
              id="reidnote"
              className={`instructions ${
                recaptchaToken === null ? "show" : "hide"
              }`}
            >
              Please Verify You Are Human To Continue.
            </span>
          </div>
          <div className="field button-field">
            <button type="submit">
              {loading ? (
                <div className="button-loader">
                  <Loader width={30} height={20}></Loader>
                </div>
              ) : (
                "Sign Up"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CompanySignup;
