import validator from "validator";

export interface AdminSignupFormValues {
  email: string;
  confirmEmail: string;
  password: string
  confirmPassword: string;
  username: string;
}

export interface AdminSignupFormValidValues {
  email: boolean;
  confirmEmail: boolean;
  password: boolean;
  confirmPassword: boolean;
  username: boolean;
}

interface ValidationResult {
  isValid: Partial<Record<keyof AdminSignupFormValues, boolean>>;
  errors: Partial<Record<keyof AdminSignupFormValues, string>>;
}

/**
 * validateAdminSignupForm - Validates the admin signup form values.
 *
 * @param {AdminSignupFormValues} values - The values of the admin signup form.
 * @returns {ValidationResult} - The validation result including validity and error messages.
 */
export const validateAdminSignupForm = (
  values: AdminSignupFormValues
): ValidationResult => {
  const errors: Partial<Record<keyof AdminSignupFormValues, string>> = {};
  const isValid: Partial<Record<keyof AdminSignupFormValues, boolean>> = {};

  if (!validator.isEmail(values.email)) {
    errors.email = "Invalid email format";
    isValid.email = false;
  } else isValid.email = true;

  if (
    validator.isEmpty(values.confirmEmail) ||
    values.email !== values.confirmEmail
  ) {
    errors.confirmEmail = "Must match the email";
    isValid.confirmEmail = false;
  } else isValid.confirmEmail = true;

  if (
    validator.isEmpty(values.username) ||
    !validator.isLength(values.username, { min: 8 })
  ) {
    errors.username = "Username must contain at least 8 characters";
    isValid.username = false;
  } else isValid.username = true;

  if (
    validator.isEmpty(values.password) ||
    !validator.isLength(values.password, { min: 8 })
  ) {
    errors.password = "Password must contain at least 8 characters";
    isValid.password = false;
  } else isValid.password = true;

  if (
    validator.isEmpty(values.confirmPassword) ||
    values.password !== values.confirmPassword
  ) {
    errors.confirmPassword = "Must match the password";
    isValid.confirmPassword = false;
  } else isValid.confirmPassword = true;

  return {
    isValid,
    errors,
  };
};
