import React from "react";
import { CustomArrowProps } from "react-slick";

/**
 * Custom next arrow component for the content row slider.
 * @param {CustomArrowProps} props
 * @param {string} props.className - CSS class applied to the arrow.
 * @param {object} props.style - Inline styles for the arrow.
 * @param {function} props.onClick - Click handler for the arrow.
 * @returns {JSX.Element} A div representing the next arrow.
 */

const NextArrow: React.FC<CustomArrowProps> = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      style={{ ...style, display: "block", zIndex: 1 }}
    >
      &#10095;
    </div>
  );
};

export default NextArrow;
