import React from "react";
import "./styles/ThumbnailCard.css";

interface ThumbnailCardProps {
  id: number;
  thumbnailUrl: string;
  title: string;
  subtitle?: string;
  onThumbnailCardClick?: (id: number) => void;
}
const ThumbnailCard: React.FC<ThumbnailCardProps> = ({
  id,
  thumbnailUrl,
  title,
  subtitle,
  onThumbnailCardClick,
}) => {
  return (
    <div className="thumbnail-card-container">
      <img
        src={thumbnailUrl}
        alt={title}
        onClick={() => {
          if (onThumbnailCardClick) onThumbnailCardClick(id);
        }}
      />
      <div
        className="thumbnail-card-overlay"
        onClick={() => {
          if (onThumbnailCardClick) onThumbnailCardClick(id);
        }}
      >
        <h3>{title}</h3>
        <p>{subtitle}</p>
      </div>
    </div>
  );
};

export default ThumbnailCard;
