import React, { useEffect, useRef, useState } from "react";
import "./Carousel.css";
import "../../styles/AllComponents.css";
import { CarouselProps } from "../../types/types";

/**
 * Carousel component that displays a rotating set of images.
 * @param {CarouselProps} props
 * @param {Array} props.campaigns - List of campaign objects containing id, heroImageUrl, and title.
 * @param {function} props.onHeroImageClick - Handler called when a hero image is clicked.
 * @param {number} [props.intervalTime=30000] - Time in milliseconds for auto-navigation, default 30 seconds.
 * @returns {JSX.Element} A carousel displaying hero images with navigation controls.
 */

const Carousel: React.FC<CarouselProps> = ({
  campaigns,
  onHeroImageClick,
  intervalTime = 30000,
  heroImagesCount = 4,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const startX = useRef(0);
  const endX = useRef(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const validHeroImagesCount = Math.min(heroImagesCount, campaigns.length);

  const limitedCampaigns = campaigns.slice(0, validHeroImagesCount);

  const next = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % limitedCampaigns.length);
  };

  const prev = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + limitedCampaigns.length) % limitedCampaigns.length
    );
  };

  const handleIndicatorClick = (index: number) => {
    setCurrentIndex(index);
  };

  const handleStart = (event: React.TouchEvent | React.MouseEvent) => {
    if ("touches" in event) {
      startX.current = event.touches[0].clientX;
    } else {
      startX.current = event.clientX;
    }
  };

  const handleMove = (event: React.TouchEvent | React.MouseEvent) => {
    if ("touches" in event) {
      endX.current = event.touches[0].clientX;
    } else {
      endX.current = event.clientX;
    }
  };

  const handleEnd = () => {
    const diffX = startX.current - endX.current;
    if (Math.abs(diffX) > 50) {
      if (diffX > 0) {
        next();
      } else prev();
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % limitedCampaigns.length);
    }, intervalTime);

    return () => clearInterval(interval);
  }, [limitedCampaigns.length, intervalTime]);

  return (
    <div className="component-container">
      <div
        className="carousel-container"
        data-testid="transition"
        ref={containerRef}
        onTouchStart={handleStart}
        onTouchMove={handleMove}
        onTouchEnd={handleEnd}
        onMouseDown={handleStart}
        onMouseMove={handleMove}
        onMouseUp={handleEnd}
      >
        <img
          key={limitedCampaigns[currentIndex].id} 
          src={limitedCampaigns[currentIndex].heroImageUrl}
          alt={limitedCampaigns[currentIndex].title}
          className="carousel-img fade"
          onClick={() => onHeroImageClick(limitedCampaigns[currentIndex].id)}
          draggable="false"
        />
        <div className="transition-indicator-container">
          {limitedCampaigns.map((_, index) => (
            <button
              key={index}
              className={`transition-indicator no-select ${
                index === currentIndex ? "active" : ""
              }`}
              onClick={() => handleIndicatorClick(index)}
            >
              &#9675;
            </button>
          ))}
        </div>
      </div>
      <div className="carousel-footnote">
        <span className="arrow-indicator">Featured</span>
        <span className="carousel-footnote-title">
          {limitedCampaigns[currentIndex].title}
        </span>
      </div>
    </div>
  );
};

export default Carousel;
