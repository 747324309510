import React from "react";
import "./HighlightsRow.css";
import "../../styles/AllComponents.css";
import { HighlightsRowProps } from "../../types/types";
import highlight from "../../assets/highlights/highlights_supermario_odyssey.jpg";

const HighlightsRow: React.FC<HighlightsRowProps> = ({
  campaign,
  onHeroImageClick,
}) => {
  if (!campaign || !campaign.heroImageUrl) {
    return null;
  }
  return (
    <div className="component-container">
      <span className="highlights-header arrow-indicator">HIGHLIGHTS</span>
      <div className="highlights-container">
        {/* <div className="highlights-text"></div> */}
        <img
          key={campaign.id}
          src={highlight}
          alt={campaign.title}
          className="highlights-img"
          onClick={() => onHeroImageClick(campaign.id)}
          draggable="false"
        />
      </div>
    </div>
  );
};

export default HighlightsRow;
