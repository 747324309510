import { Action, Profile } from "../../types/types";
import { Dispatch } from "redux";
import { dispatchable } from "../dispatchable";
import apiClient from "../../mock/apiClient";
import { actions } from "./profile.slice";

export const getAllProfiles = dispatchable(() => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const response = await apiClient.get<Profile[]>(`/profiles`);
      dispatch(actions["profiles/load"](response.data));
      console.log("Fetching all profiles: ", response.data);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        data: null,
      };
    }
  };
});

export const getProfileByUserId = dispatchable((id: string | undefined) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const response = await apiClient.get<Profile>(`/profile/${id}`);
      dispatch(actions["profiles/profile"](response.data));
      console.log("Fetching profile by user id: ", response.data);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        data: null,
      };
    }
  };
});
