import validator from "validator";

export interface CompanySignupFormValues {
  companyName: string;
  firstName: string;
  lastName: string;
  email: string;
  confirmEmail: string;
}

export interface CompanySignupFormValidValues {
  companyName: boolean;
  firstName: boolean;
  lastName: boolean;
  email: boolean;
  confirmEmail: boolean;
}

interface ValidationResult {
  isValid: Partial<Record<keyof CompanySignupFormValues, boolean>>;
  errors: Partial<Record<keyof CompanySignupFormValues, string>>;
}

/**
 * validateCompanySignupForm - Validates the company signup form values.
 *
 * @param {CompanySignupFormValues} values - The values of the company signup form.
 * @returns {ValidationResult} - The validation result including validity and error messages.
 */
export const validateCompanySignupForm = (
  values: CompanySignupFormValues
): ValidationResult => {
  const errors: Partial<Record<keyof CompanySignupFormValues, string>> = {};
  const isValid: Partial<Record<keyof CompanySignupFormValues, boolean>> = {};

  if (validator.isEmpty(values.companyName)) {
    errors.companyName = "Company name is required";
    isValid.companyName = false;
  } else isValid.companyName = true;

  if (validator.isEmpty(values.firstName)) {
    errors.firstName = "First name is required";
    isValid.firstName = false;
  } else isValid.firstName = true;

  if (validator.isEmpty(values.lastName)) {
    errors.lastName = "Last name is required";
    isValid.lastName = false;
  } else isValid.lastName = true;

  if (!validator.isEmail(values.email)) {
    errors.email = "Invalid email format";
    isValid.email = false;
  } else isValid.email = true;

  if (
    validator.isEmpty(values.confirmEmail) ||
    values.email !== values.confirmEmail
  ) {
    errors.confirmEmail = "Must match the email";
    isValid.confirmEmail = false;
  } else isValid.confirmEmail = true;

  return {
    isValid,
    errors,
  };
};
