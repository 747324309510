import { VideoState } from "./video.entity";
import { PayloadAction } from "@reduxjs/toolkit";

const videoReducers = {
  "video/pip": (
    state: VideoState,
    action: PayloadAction<{ videoUrl: string | undefined; videoId: string | undefined }>
  ) => {
    state.videoUrl = action.payload.videoUrl;
    state.videoId = action.payload.videoId;
    state.isActive = true;
  },
  "video/pip/clear": (state: VideoState) => {
    state.videoUrl = undefined;
    state.videoId = undefined;
    state.isActive = false;
  },
};

export default videoReducers;
