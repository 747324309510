import { AuthState } from "./auth.entity";
import { PayloadAction } from "@reduxjs/toolkit";

const authReducers = {
  "auth/login": (state: AuthState) => {
    state.loading = true;
  },
  "auth/login/success": (
    state: AuthState,
    action: PayloadAction<{ user: any; role: string }>
  ) => {
    state.loading = false;
    state.error = null;
    state.status = null;
    state.user = action.payload.user;
    state.role = action.payload.role;
    state.isAuthenticated = true;
    state.email = null;
  },
  "auth/login/failure": (
    state: AuthState,
    action: PayloadAction<{ error: string; status: number }>
  ) => {
    state.loading = false;
    state.error = action.payload.error;
    state.status = action.payload.status;
    state.isAuthenticated = false;
  },
  "auth/logout": (state: AuthState) => {
    state.loading = true;
  },
  "auth/logout/success": (state: AuthState) => {
    state.user = null;
    state.role = null;
    state.error = null;
    state.status = null;
    state.loading = false;
    state.isAuthenticated = false;
  },
  "auth/logout/failure": (
    state: AuthState,
    action: PayloadAction<{ error: string; status: number }>
  ) => {
    state.error = action.payload.error;
    state.status = action.payload.status;
    state.loading = false;
  },
  "auth/signup": (state: AuthState) => {
    state.loading = true;
    state.email = null;
  },
  "auth/signup/success": (
    state: AuthState,
    action: PayloadAction<{ email: string }>
  ) => {
    state.loading = false;
    state.error = null;
    state.status = null;
    state.email = action.payload.email;
  },
  "auth/signup/failure": (
    state: AuthState,
    action: PayloadAction<{ error: string; status: number }>
  ) => {
    state.loading = false;
    state.error = action.payload.error;
    state.status = action.payload.status;
    state.email = null;
  },
  "auth/clear/error": (state: AuthState) => {
    state.error = null;
    state.status = null;
  },
  "auth/info": (state: AuthState) => {
    state.loading = true;
  },
  "auth/info/success": (
    state: AuthState,
    action: PayloadAction<{ info: any }>
  ) => {
    state.loading = false;
    state.error = null;
    state.status = null;
    state.info = action.payload.info;
  },
  "auth/info/failure": (
    state: AuthState,
    action: PayloadAction<{ error: string; status: number }>
  ) => {
    state.loading = false;
    state.error = action.payload.error;
    state.status = action.payload.status;
  },
  "auth/refresh": (state: AuthState) => {
    state.loading = true;
  },
  "auth/refresh/success": (state: AuthState) => {
    state.loading = false;
    state.error = null;
    state.status = null;
  },
  "auth/refresh/failure": (
    state: AuthState,
    action: PayloadAction<{ error: string; status: number }>
  ) => {
    state.loading = false;
    state.error = action.payload.error;
    state.status = action.payload.status;
    state.isAuthenticated = false;
  },
  "auth/navigate": (
    state: AuthState,
    action: PayloadAction<{ path: string }>
  ) => {
    state.path = action.payload.path;
  },
  "auth/clear/navigate": (state: AuthState) => {
    state.path = null;
  },
  "auth/email": (
    state: AuthState,
    action: PayloadAction<{ email: string }>
  ) => {
    state.email = action.payload.email;
  },
  "auth/clear/email": (state: AuthState) => {
    state.email = null;
  },
  "auth/resetpassword/request": (state: AuthState) => {
    state.loading = true;
  },
  "auth/resetpassword/request/success": (state: AuthState) => {
    state.loading = false;
    state.error = null;
    state.status = null;
  },
  "auth/resetpassword/request/failure": (
    state: AuthState,
    action: PayloadAction<{ error: string; status: number }>
  ) => {
    state.loading = false;
    state.error = action.payload.error;
    state.status = action.payload.status;
  },
  "auth/resetpassword": (state: AuthState) => {
    state.loading = true;
  },
  "auth/resetpassword/success": (state: AuthState) => {
    state.loading = false;
    state.error = null;
    state.status = null;
  },
  "auth/resetpassword/failure": (
    state: AuthState,
    action: PayloadAction<{ error: string; status: number }>
  ) => {
    state.loading = false;
    state.error = action.payload.error;
    state.status = action.payload.status;
  },
  "auth/initialize": (
    state: AuthState,
    action: PayloadAction<{ role: string | null; isAuthenticated: boolean }>
  ) => {
    state.role = action.payload.role;
    state.isAuthenticated = action.payload.isAuthenticated;
  },
};

export default authReducers;
