import React, { useRef, useState, useEffect } from "react";
import { RootState, actions, useAppSelector } from "../../store/root.store";
import { useDeviceSelectors } from "react-device-detect";
import "../../components/Forms/styles/Form.css";
import "boxicons/css/boxicons.min.css";
import "./AdminLogin.css";
import { Link } from "react-router-dom";
import { useNav } from "../../utils/navigation";

const AdminLogin: React.FC = () => {
  const fieldRef = useRef<HTMLInputElement>(null);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [hidePassword, setHidePassword] = useState<boolean>(true);
  const error = useAppSelector((state: RootState) => state.auth.error);
  const loading = useAppSelector((state: RootState) => state.auth.loading);
  const { goToAdminPage, redirectToAfterLoginPage } = useNav();
  const [selectors, data] = useDeviceSelectors(window.navigator.userAgent);

  const handleEyeIconClick = () => {
    setHidePassword(!hidePassword);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleLogin();
  };

  const handleLogin = async () => {
    const res = await actions.auth.adminLogin(email, password, data.ua);

    if (res.success && !loading) {
      redirectToAfterLoginPage();
      goToAdminPage();
    }
  };

  useEffect(() => {
    actions.auth.clearAuthError();

    if (fieldRef.current) {
      fieldRef.current.focus();
    }

    return () => {
      actions.auth.clearAuthError();
    };
  }, []);

  return (
    <div className="form-container">
      <div className="login-form">
        <header>Admin Login</header>
        <span
          id="eidnote"
          className={`auth-message ${
            error !== null && !loading ? "show" : "hide"
          }`}
        >
          {error}
        </span>
        <form onSubmit={handleSubmit}>
          <div className="field input-field">
            <input
              type="text"
              ref={fieldRef}
              name="email"
              placeholder="Enter Email"
              autoComplete="off"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="field input-field">
            <input
              type={hidePassword ? "password" : "text"}
              name="password"
              placeholder="Enter Password"
              autoComplete="off"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <i
              className={`bx eye-icon default-eye-icon ${
                hidePassword ? `bx-hide` : `bx-show`
              }`}
              onClick={handleEyeIconClick}
            ></i>
          </div>
          <div className="form-link">
            <Link to={`/user-forgot-password/admin`} className="forgot-pass">
              Forgot password?
            </Link>
          </div>
          <div className="field button-field">
            <button type="submit">Login</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
