import React, { useEffect, useState } from "react";
import "./Content.css";
import { formatDate } from "../../utils/date";
import { Descendant, Node } from "slate";
import { serialize } from "../../utils/slateUtils";
import { Campaign } from "../../types/types";
import SpatialVideo from "../Video/SpatialVideo";
import { Video } from "../Video";
import { USE_LOCAL_MEDIA_SERVER } from "../../mock/mockData";

interface ContentProps {
  campaign: Campaign;
}

enum VideoPlaybackMode {
  standard = "standard",
  sideBySide = "sideBySide",
  spatial = "spatial",
}

const Content: React.FC<ContentProps> = ({ campaign }) => {
  const [articleTextSerialized, setArticleTextSerialized] = useState<
    Descendant[]
  >(serialize(campaign.articleText ? campaign.articleText : ""));
  // Video playback
  const [videoPlaybackMode, setVideoPlaybackMode] = useState<VideoPlaybackMode>(
    VideoPlaybackMode.standard
  );
  const videoSource = campaign.videoLink
    ? campaign.videoLink
    : campaign.youtubeId
    ? campaign.youtubeId
    : "";
  const hasVideo =
    campaign.videoLink ||
    campaign.youtubeId ||
    campaign.spatialVideoUrl ||
    campaign.sideBySideUrl;

  useEffect(() => {
    if (campaign.videoLink || campaign.youtubeId) {
      setVideoPlaybackMode(VideoPlaybackMode.standard);
    } else if (campaign.spatialVideoUrl) {
      setVideoPlaybackMode(VideoPlaybackMode.spatial);
    } else if (campaign.sideBySideUrl) {
      setVideoPlaybackMode(VideoPlaybackMode.sideBySide);
    }
  }, []);

  let videoComponent = null;
  if (
    videoPlaybackMode === VideoPlaybackMode.standard &&
    (campaign.videoLink || campaign.youtubeId)
  ) {
    videoComponent = (
      <>
        <Video
          videos={[videoSource]}
          isYT={campaign.youtubeId !== undefined}
          isVideoId={!USE_LOCAL_MEDIA_SERVER}
        ></Video>
        {campaign.title === `NBA - Nets vs Golden State` && (
          <Video
            videos={["usKmQpuncBI"]}
            isYT={true}
            isVideoId={!USE_LOCAL_MEDIA_SERVER}
          ></Video>
        )}
      </>
    );
  } else if (
    videoPlaybackMode === VideoPlaybackMode.spatial &&
    campaign.spatialVideoUrl
  ) {
    videoComponent = <SpatialVideo key={0} mvUrl={campaign.spatialVideoUrl} />;
  } else if (
    videoPlaybackMode === VideoPlaybackMode.sideBySide &&
    campaign.sideBySideUrl
  ) {
    videoComponent = <SpatialVideo key={1} sbsUrl={campaign.sideBySideUrl} />;
  }
  console.log(videoPlaybackMode);
  // Create a select to control which version of the
  // video is showing
  let videoSelector = null;
  if (hasVideo) {
    const valueToPlaybackMode = (value: string): VideoPlaybackMode => {
      switch (value) {
        case "2D":
          return VideoPlaybackMode.standard;
        case "spatial":
          return VideoPlaybackMode.spatial;
        case "sideBySide":
          return VideoPlaybackMode.sideBySide;
      }
      return VideoPlaybackMode.standard;
    };
    videoSelector = (
      <select
        value={videoPlaybackMode}
        onChange={(e) => {
          setVideoPlaybackMode(valueToPlaybackMode(e.target.value));
        }}
      >
        {campaign.videoLink || campaign.youtubeId ? (
          <option key={0} value="2D">
            Standard
          </option>
        ) : null}
        {campaign.spatialVideoUrl ? (
          <option key={1} value="spatial">
            Apple Vision Pro
          </option>
        ) : null}
        {campaign.sideBySideUrl ? (
          <option key={2} value="sideBySide">
            XR Headset
          </option>
        ) : null}
      </select>
    );
  }
  // Create author info from editor (if exists)
  // and if not, with the company info
  let authorImage =
    "https://t4.ftcdn.net/jpg/00/64/67/27/360_F_64672736_U5kpdGs9keUll8CRQ3p3YaEv2M6qkVY5.jpg";
  let authorName = "Editor or Company Name";
  // if (campaign.editor && campaign.editor.profile) {
  //   authorImage = campaign.editor.profile?.imageUrl;
  //   authorName = `${campaign.editor.profile.firstName} ${campaign.editor.profile.lastName}`;
  // }
  // else if (
  //   campaign.companyProfiles.length > 0 &&
  //   campaign.companyProfiles[0].logoUrl
  // ) {
  //   authorImage = campaign.companyProfiles[0].logoUrl;
  //   authorName = campaign.companyProfiles[0].name;
  // }
  if (campaign.companyProfiles && campaign.companyProfiles.length > 0) {
    authorImage = campaign.companyProfiles[0].logoUrl;
    authorName = campaign.companyProfiles[0].name;
  }

  return (
    <div className="component-container" style={{ paddingTop: 0 }}>
      <div className="content-container">
        <h1>{campaign.title}</h1>
        <footer>
          {formatDate(
            campaign.approvedAt ? campaign.approvedAt : campaign.createdAt,
            "ET"
          )}
        </footer>
        <div className="author">
          <img src={authorImage} alt="default profile" />
          <span>{authorName}</span>
        </div>
        <br />
        {videoComponent}

        <div className="video-selector">
          <span>Video Playback Mode</span>
          {videoSelector}
        </div>

        <div className="article-container">
          <div className="content-text">
            {articleTextSerialized.map((n) => (
              <p key={Node.string(n)}>{Node.string(n)}</p>
            ))}
          </div>
          {campaign.featuredMetadata ? (
            <div className="content-image">
              <img
                src={campaign.featuredMetadata?.galleryImage.url}
                alt={campaign.title}
              />
              {campaign.featuredMetadata?.comment ? (
                <footer>{campaign.featuredMetadata?.comment ?? ""}</footer>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Content;
