import React, { useRef } from 'react';

interface SpatialVideoProps {
    disable?: boolean;
    mvUrl?: string;
    sbsUrl?: string;
    disablePreload?: boolean;
}

const SpatialVideo: React.FC<SpatialVideoProps> = ({ disable, mvUrl, sbsUrl , disablePreload }: SpatialVideoProps) => {
    if (disable !== undefined && disable === true) return null;
    return (
        <>
            <video 
                className="spatial-video-player" 
                preload={disablePreload !== undefined ? 'none' : 'auto'}
                controls>
                <source 
                src={mvUrl ?? sbsUrl}
                type="video/mp4" />
            </video>
        </>
    )
}

export default SpatialVideo;