import { PayloadAction } from "@reduxjs/toolkit";
import { ProfileState } from "./profile.entity";
import { Profile } from "../../types/types";

const profileReducers = {
  "profiles/load": (state: ProfileState, action: PayloadAction<Profile[]>) => {
    state.profiles = action.payload;
  },
  "profiles/profile": (state: ProfileState, action: PayloadAction<Profile>) => {
    state.profile = action.payload;
  },
};

export default profileReducers;
