import React, { useState } from "react";
import "./PreUploadedMultiSelector.css";

interface PreUploadedMultiSelectorProps {
  title: string;
  handleAssetsChange: (assets: string[]) => void;
  assets: any[];
  isVideo: boolean;
  maxAssetCount: number;
}

const PreUploadedMultiSelector: React.FC<PreUploadedMultiSelectorProps> = ({
  handleAssetsChange,
  title,
  assets,
  isVideo,
  maxAssetCount,
}) => {
  const [selectedAssets, setSelectedAssets] = useState<string[]>([]);
  const [openSelector, setOpenSelector] = useState<boolean>(false);

  const handleClick = () => {
    setOpenSelector(true);
  };

  const handleChange = (asset: string) => {
    if (
      !selectedAssets.includes(asset) &&
      selectedAssets.length < maxAssetCount
    ) {
      const updatedAssets = [...selectedAssets, asset];
      setSelectedAssets(updatedAssets);
      handleAssetsChange(updatedAssets);
    }
  };

  const removeAsset = (assetToRemove: string) => {
    const updatedAssets = selectedAssets.filter(
      (asset) => asset !== assetToRemove
    );
    setSelectedAssets(updatedAssets);
    handleAssetsChange(updatedAssets);
  };

  return (
    <>
      <h4>{title}</h4>
      <div
        className={`gallery-image-input ${
          selectedAssets.length ? "has-file" : ""
        }`}
        onClick={handleClick}
      >
        <label>
          {selectedAssets.length ? (
            <div className="remove-files">
              {selectedAssets.map((asset, index) => (
                <div key={index} className="remove-file">
                  <i
                    className="bx bx-x bx-sm remove-file-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      removeAsset(asset);
                    }}
                  ></i>
                  {asset}
                </div>
              ))}
            </div>
          ) : (
            <>
              {isVideo ? (
                <i className="bx bx-video-plus bx-md"></i>
              ) : (
                <i className="bx bx-image-add bx-md"></i>
              )}{" "}
              Browse
            </>
          )}
          {openSelector && (
            <div
              className="asset-selector"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="asset-selector-header">
                <div style={{ color: "#fff" }}>{title}</div>
                <i
                  className="bx bx-x bx-sm asset-close"
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenSelector(false);
                  }}
                ></i>
              </div>
              {assets
                .filter(
                  (asset) =>
                    !selectedAssets.includes(
                      asset.url ? asset.url : asset.imageId
                    )
                )
                .map((asset, index) => (
                  <div
                    key={index}
                    className={`asset-option ${
                      selectedAssets.includes(
                        asset.url ? asset.url : asset.imageId
                      )
                        ? "selected"
                        : ""
                    }`}
                    onClick={() =>
                      handleChange(asset.url ? asset.url : asset.imageId)
                    }
                  >
                    {isVideo ? (
                      <video
                        src={asset.url}
                        className="asset-preview"
                        controls
                      />
                    ) : (
                      <img
                        src={asset.url ? asset.url : asset.imageId}
                        alt={asset.alt ? asset.alt : `Asset ${index}`}
                        className="asset-preview"
                      />
                    )}
                    <p className="asset-name">
                      {asset.alt ? asset.alt : asset.url}
                    </p>
                  </div>
                ))}
            </div>
          )}
        </label>
      </div>
    </>
  );
};

export default PreUploadedMultiSelector;
