import { Dispatch } from "redux";
import { dispatchable } from "../dispatchable";
import { Action } from "../../types/types";
import { actions } from "./auth.slice";
import api from "../../services/api";
import axios from "axios";
import {
  getAccessToken,
  getRefreshToken,
  removeAccessToken,
  removeRefreshToken,
  removeUserRole,
  setAccessToken,
  setRefreshToken,
  setUserRole,
  getUserRole,
} from "../../utils/auth";

// General auth thunks

export const checkAuthentication = dispatchable(() => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(
      actions["auth/initialize"]({
        role: getUserRole(),
        isAuthenticated: !!getAccessToken(),
      })
    );
  };
});

export const logout = dispatchable(() => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(actions["auth/logout"]());

    removeAccessToken();
    removeRefreshToken();
    removeUserRole();

    // logout endpoint for force logout or logout out of all devices

    dispatch(actions["auth/logout/success"]());
  };
});

export const clearAuthError = dispatchable(() => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(actions["auth/clear/error"]());
  };
});

// Standard user thunks

export const standardLogin = dispatchable(
  (email: string, password: string, device: string) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        dispatch(actions["auth/login"]());

        const requestBody = {
          inputEmail: email,
          inputPassword: password,
          inputDevice: device,
        };

        const response = await api.post("/auth/standard/login", requestBody, {
          requiresAuth: false,
        });

        if (response.status === 200) {
          setAccessToken(response.data.info.token.access.token);
          setRefreshToken(response.data.info.token.refresh.token);
          setUserRole("standard");

          dispatch(
            actions["auth/login/success"]({
              user: response.data.info.user,
              role: "standard",
            })
          );

          return {
            success: true,
            data: response.data.info.user,
          };
        } else {
          dispatch(
            actions["auth/login/failure"]({
              error: `Unexpected status code: ${response.status}`,
              status: response.status,
            })
          );
          return {
            success: false,
            data: null,
          };
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            const { status } = error.response;
            console.log(`${status}`, error);

            switch (status) {
              case 400:
                dispatch(
                  actions["auth/login/failure"]({
                    error: "Invalid credentials",
                    status: status,
                  })
                );
                break;
              case 401:
                dispatch(
                  actions["auth/login/failure"]({
                    error:
                      "Unauthorized access. Credentials do not match our records.",
                    status: status,
                  })
                );
                break;
              case 404:
                dispatch(
                  actions["auth/login/failure"]({
                    error: "An error occurred. Please try again later.",
                    status: status,
                  })
                );
                break;
              case 500:
                dispatch(
                  actions["auth/login/failure"]({
                    error: "Server error. Please try again later.",
                    status: status,
                  })
                );
                break;
              default:
                dispatch(
                  actions["auth/login/failure"]({
                    error: `Unexpected status code: ${status}`,
                    status: status,
                  })
                );
            }
          }
        }
        return {
          success: false,
          data: null,
        };
      }
    };
  }
);

export const standardSignup = dispatchable(
  (
    email: string,
    password: string,
    username: string,
    role: string,
    firstName: string,
    lastName: string,
    imageUrl?: string
  ) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        dispatch(actions["auth/signup"]());

        const requestBody = {
          inputEmail: email,
          inputPassword: password,
          inputUsername: username,
          inputRoles: role,
          inputFirstName: firstName,
          inputLastName: lastName,
          inputImageUrl: imageUrl ? imageUrl : "",
        };

        const response = await api.post("/auth/standard/signup", requestBody, {
          requiresAuth: false,
        });

        if (response.status === 200) {
          dispatch(
            actions["auth/signup/success"]({
              email: response.data.info.user.email,
            })
          );

          return {
            success: true,
            data: response.data,
          };
        } else {
          dispatch(
            actions["auth/signup/failure"]({
              error: `Unexpected status code: ${response.status}`,
              status: response.status,
            })
          );
          return {
            success: false,
            data: null,
          };
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            const { status } = error.response;
            console.log(`${status}`, error);

            switch (status) {
              case 400:
                dispatch(
                  actions["auth/signup/failure"]({
                    error: "Invalid signup field",
                    status: status,
                  })
                );
                break;
              case 404:
                dispatch(
                  actions["auth/signup/failure"]({
                    error: "An error occurred. Please try again later.",
                    status: status,
                  })
                );
                break;
              case 500:
                dispatch(
                  actions["auth/signup/failure"]({
                    error: "Server error. Please try again later.",
                    status: status,
                  })
                );
                break;
              default:
                dispatch(
                  actions["auth/signup/failure"]({
                    error: `Unexpected status code: ${status}`,
                    status: status,
                  })
                );
            }
          }
        }
        return {
          success: false,
          data: null,
        };
      }
    };
  }
);

export const standardRefreshToken = dispatchable(() => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      dispatch(actions["auth/refresh"]());

      const refreshToken = getRefreshToken();
      if (!refreshToken) {
        dispatch(
          actions["auth/refresh/failure"]({
            error: "No refresh token found",
            status: 404,
          })
        );

        return {
          success: false,
          data: null,
        };
      }

      const requestBody = {
        refreshToken: refreshToken,
      };

      const response = await api.post("/auth/standard/refresh", requestBody, {
        requiresAuth: false,
      });

      if (response.status === 200) {
        setAccessToken(response.data.token.access.token);

        dispatch(actions["auth/refresh/success"]());

        return {
          success: true,
          data: response.data.user,
        };
      } else {
        dispatch(
          actions["auth/refresh/failure"]({
            error: `Unexpected status code: ${response.status}`,
            status: response.status,
          })
        );

        return {
          success: false,
          data: null,
        };
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          const { status } = error.response;
          console.log(`${status}`, error);

          switch (status) {
            case 400:
              dispatch(
                actions["auth/refresh/failure"]({
                  error: "Invalid refresh token",
                  status: status,
                })
              );
              break;
            case 401:
              dispatch(
                actions["auth/refresh/failure"]({
                  error: "Authorization error. Please login again.",
                  status: status,
                })
              );
              break;
            case 404:
              dispatch(
                actions["auth/refresh/failure"]({
                  error: "An error occurred. Please try again later.",
                  status: status,
                })
              );
              break;
            case 500:
              dispatch(
                actions["auth/refresh/failure"]({
                  error: "Server error. Please try again later.",
                  status: status,
                })
              );
              break;
            default:
              dispatch(
                actions["auth/refresh/failure"]({
                  error: `Unexpected status code: ${status}`,
                  status: status,
                })
              );
          }
        }
      }
      return {
        success: false,
        data: null,
      };
    }
  };
});

export const fetchStandardInfo = dispatchable(() => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      dispatch(actions["auth/info"]());

      const token = getAccessToken();
      if (!token) {
        dispatch(
          actions["auth/info/failure"]({
            error: "No access token found",
            status: 404,
          })
        );

        return {
          success: false,
          data: null,
        };
      }

      const response = await api.get(`/auth/standard/me`, {
        requiresAuth: true,
      });

      if (response.status === 200) {
        dispatch(actions["auth/info/success"]({ info: response.data.info }));

        return {
          success: true,
          data: response.data.info,
        };
      } else {
        dispatch(
          actions["auth/info/failure"]({
            error: `Unexpected status code: ${response.status}`,
            status: response.status,
          })
        );
        return {
          success: false,
          data: null,
        };
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          const { status } = error.response;
          console.log(`${status}`, error);

          switch (status) {
            case 401:
              dispatch(
                actions["auth/info/failure"]({
                  error: "Token invalid. Authentication required.",
                  status: status,
                })
              );
              break;
            case 404:
              dispatch(
                actions["auth/info/failure"]({
                  error: "An error occurred. Please try again later.",
                  status: status,
                })
              );
              break;
            case 500:
              dispatch(
                actions["auth/info/failure"]({
                  error: "Server error. Please try again later.",
                  status: status,
                })
              );
              break;
            default:
              dispatch(
                actions["auth/info/failure"]({
                  error: `Unexpected status code: ${status}`,
                  status: status,
                })
              );
          }
        }
      }
      return {
        success: false,
        data: null,
      };
    }
  };
});

// Admin user thunks

export const adminLogin = dispatchable(
  (email: string, password: string, device: string) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        dispatch(actions["auth/login"]());

        const requestBody = {
          inputEmail: email,
          inputPassword: password,
          inputDevice: device,
        };

        const response = await api.post("/auth/admin/login", requestBody, {
          requiresAuth: false,
        });

        if (response.status === 200) {
          setAccessToken(response.data.info.token.access.token);
          setRefreshToken(response.data.info.token.refresh.token);
          setUserRole("admin");

          fetchAdminInfo();

          dispatch(
            actions["auth/login/success"]({
              user: response.data.info.user,
              role: "admin",
            })
          );

          return {
            success: true,
            data: response.data.info.user,
          };
        } else {
          dispatch(
            actions["auth/login/failure"]({
              error: `Unexpected status code: ${response.status}`,
              status: response.status,
            })
          );
          return {
            success: false,
            data: null,
          };
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            const { status } = error.response;
            console.log(`${status}`, error);

            switch (status) {
              case 400:
                dispatch(
                  actions["auth/login/failure"]({
                    error: "Invalid credentials",
                    status: status,
                  })
                );
                break;
              case 401:
                dispatch(
                  actions["auth/login/failure"]({
                    error:
                      "Unauthorized access. Credentials do not match our records.",
                    status: status,
                  })
                );
                break;
              case 404:
                dispatch(
                  actions["auth/login/failure"]({
                    error: "An error occurred. Please try again later.",
                    status: status,
                  })
                );
                break;
              case 500:
                dispatch(
                  actions["auth/login/failure"]({
                    error: "Server error. Please try again later.",
                    status: status,
                  })
                );
                break;
              default:
                dispatch(
                  actions["auth/login/failure"]({
                    error: `Unexpected status code: ${status}`,
                    status: status,
                  })
                );
            }
          }
        }
        return {
          success: false,
          data: null,
        };
      }
    };
  }
);

export const adminSignup = dispatchable(
  (
    email: string,
    password: string,
    username: string,
    enabled: boolean,
    companyPublicId: string,
    companyName: string,
    companyLogoUrl: string
  ) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        dispatch(actions["auth/signup"]());

        const requestBody = {
          inputEmail: email,
          inputPassword: password,
          inputUsername: username,
          inputEnabled: enabled,
          companyPublicId: companyPublicId,
          companyName: companyName,
          companyLogoUrl: companyLogoUrl,
        };

        const response = await api.post("/auth/admin/signup", requestBody, {
          requiresAuth: false,
        });

        if (response.status === 200) {
          dispatch(
            actions["auth/signup/success"]({
              email: response.data.info.user.email,
            })
          );

          return {
            success: true,
            data: response.data,
          };
        } else {
          dispatch(
            actions["auth/signup/failure"]({
              error: `Unexpected status code: ${response.status}`,
              status: response.status,
            })
          );
          return {
            success: false,
            data: null,
          };
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            const { status } = error.response;
            console.log(`${status}`, error);

            switch (status) {
              case 400:
                dispatch(
                  actions["auth/signup/failure"]({
                    error: "Invalid signup field",
                    status: status,
                  })
                );
                break;
              case 404:
                dispatch(
                  actions["auth/signup/failure"]({
                    error: "An error occurred. Please try again later.",
                    status: status,
                  })
                );
                break;
              case 500:
                dispatch(
                  actions["auth/signup/failure"]({
                    error: "Server error. Please try again later.",
                    status: status,
                  })
                );
                break;
              default:
                dispatch(
                  actions["auth/signup/failure"]({
                    error: `Unexpected status code: ${status}`,
                    status: status,
                  })
                );
            }
          }
        }
        return {
          success: false,
          data: null,
        };
      }
    };
  }
);

export const adminRefreshToken = dispatchable(() => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      dispatch(actions["auth/refresh"]());

      const refreshToken = getRefreshToken();
      if (!refreshToken) {
        dispatch(
          actions["auth/refresh/failure"]({
            error: "No refresh token found",
            status: 404,
          })
        );

        return {
          success: false,
          data: null,
        };
      }

      const requestBody = {
        refreshToken: refreshToken,
      };

      const response = await api.post("/auth/admin/refresh", requestBody, {
        requiresAuth: false,
      });

      if (response.status === 200) {
        setAccessToken(response.data.token.access.token);

        dispatch(actions["auth/refresh/success"]());

        return {
          success: true,
          data: response.data.user,
        };
      } else {
        dispatch(
          actions["auth/refresh/failure"]({
            error: `Unexpected status code: ${response.status}`,
            status: response.status,
          })
        );

        return {
          success: false,
          data: null,
        };
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          const { status } = error.response;
          console.log(`${status}`, error);

          switch (status) {
            case 400:
              dispatch(
                actions["auth/refresh/failure"]({
                  error: "Invalid refresh token",
                  status: status,
                })
              );
              break;
            case 401:
              dispatch(
                actions["auth/refresh/failure"]({
                  error: "Authorization error. Please login again.",
                  status: status,
                })
              );
              break;
            case 404:
              dispatch(
                actions["auth/refresh/failure"]({
                  error: "An error occurred. Please try again later.",
                  status: status,
                })
              );
              break;
            case 500:
              dispatch(
                actions["auth/refresh/failure"]({
                  error: "Server error. Please try again later.",
                  status: status,
                })
              );
              break;
            default:
              dispatch(
                actions["auth/refresh/failure"]({
                  error: `Unexpected status code: ${status}`,
                  status: status,
                })
              );
          }
        }
      }
      return {
        success: false,
        data: null,
      };
    }
  };
});

export const fetchAdminInfo = dispatchable(() => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      dispatch(actions["auth/info"]());

      const token = getAccessToken();
      if (!token) {
        dispatch(
          actions["auth/info/failure"]({
            error: "No access token found",
            status: 404,
          })
        );

        return {
          success: false,
          data: null,
        };
      }

      const response = await api.get(`/auth/admin/me`, {
        requiresAuth: true,
      });

      if (response.status === 200) {
        dispatch(actions["auth/info/success"]({ info: response.data.info }));

        return {
          success: true,
          data: response.data.info,
        };
      } else {
        dispatch(
          actions["auth/info/failure"]({
            error: `Unexpected status code: ${response.status}`,
            status: response.status,
          })
        );
        return {
          success: false,
          data: null,
        };
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          const { status } = error.response;
          console.log(`${status}`, error);

          switch (status) {
            case 401:
              dispatch(
                actions["auth/info/failure"]({
                  error: "Token invalid. Authentication required.",
                  status: status,
                })
              );
              break;
            case 404:
              dispatch(
                actions["auth/info/failure"]({
                  error: "An error occurred. Please try again later.",
                  status: status,
                })
              );
              break;
            case 500:
              dispatch(
                actions["auth/info/failure"]({
                  error: "Server error. Please try again later.",
                  status: status,
                })
              );
              break;
            default:
              dispatch(
                actions["auth/info/failure"]({
                  error: `Unexpected status code: ${status}`,
                  status: status,
                })
              );
          }
        }
      }
      return {
        success: false,
        data: null,
      };
    }
  };
});

// Sudo user thunks

export const sudoLogin = dispatchable((email: string, password: string) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      dispatch(actions["auth/login"]());

      const requestBody = {
        inputEmail: email,
        inputPassword: password,
      };

      const response = await api.post("/auth/sudo/login", requestBody, {
        requiresAuth: false,
      });

      if (response.status === 200) {
        setAccessToken(response.data.info.token.access.token);
        setUserRole("sudo");

        dispatch(
          actions["auth/login/success"]({
            user: response.data.info.sudo,
            role: "sudo",
          })
        );

        return {
          success: true,
          data: response.data.info.sudo,
        };
      } else {
        dispatch(
          actions["auth/login/failure"]({
            error: `Unexpected status code: ${response.status}`,
            status: response.status,
          })
        );
        return {
          success: false,
          data: null,
        };
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          const { status } = error.response;
          console.log(`${status}`, error);

          switch (status) {
            case 400:
              dispatch(
                actions["auth/login/failure"]({
                  error: "Invalid credentials",
                  status: status,
                })
              );
              break;
            case 401:
              dispatch(
                actions["auth/login/failure"]({
                  error:
                    "Unauthorized access. Credentials do not match our records.",
                  status: status,
                })
              );
              break;
            case 404:
              dispatch(
                actions["auth/login/failure"]({
                  error: "An error occurred. Please try again later.",
                  status: status,
                })
              );
              break;
            case 500:
              dispatch(
                actions["auth/login/failure"]({
                  error: "Server error. Please try again later.",
                  status: status,
                })
              );
              break;
            default:
              dispatch(
                actions["auth/login/failure"]({
                  error: `Unexpected status code: ${status}`,
                  status: status,
                })
              );
          }
        }
      }
      return {
        success: false,
        data: null,
      };
    }
  };
});

export const sudoSignup = dispatchable((email: string, password: string) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      dispatch(actions["auth/signup"]());

      const requestBody = {
        inputEmail: email,
        inputPassword: password,
      };

      const response = await api.post("/auth/sudo/signup", requestBody, {
        requiresAuth: false,
      });

      if (response.status === 200) {
        dispatch(
          actions["auth/signup/success"]({ email: response.data.info.email })
        );

        return {
          success: true,
          data: response.data,
        };
      } else {
        dispatch(
          actions["auth/signup/failure"]({
            error: `Unexpected status code: ${response.status}`,
            status: response.status,
          })
        );
        return {
          success: false,
          data: null,
        };
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          const { status } = error.response;
          console.log(`${status}`, error);

          switch (status) {
            case 400:
              dispatch(
                actions["auth/signup/failure"]({
                  error: "Invalid signup field",
                  status: status,
                })
              );
              break;
            case 404:
              dispatch(
                actions["auth/signup/failure"]({
                  error: "An error occurred. Please try again later.",
                  status: status,
                })
              );
              break;
            case 500:
              dispatch(
                actions["auth/signup/failure"]({
                  error: "Server error. Please try again later.",
                  status: status,
                })
              );
              break;
            default:
              dispatch(
                actions["auth/signup/failure"]({
                  error: `Unexpected status code: ${status}`,
                  status: status,
                })
              );
          }
        }
      }
      return {
        success: false,
        data: null,
      };
    }
  };
});

export const fetchSudoInfo = dispatchable(() => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      dispatch(actions["auth/info"]());

      const token = getAccessToken();
      if (!token) {
        dispatch(
          actions["auth/info/failure"]({
            error: "No access token found",
            status: 404,
          })
        );

        return {
          success: false,
          data: null,
        };
      }

      const response = await api.get(`/auth/sudo/me`, {
        requiresAuth: true,
      });

      if (response.status === 200) {
        dispatch(actions["auth/info/success"]({ info: response.data.info }));

        return {
          success: true,
          data: response.data.info,
        };
      } else {
        dispatch(
          actions["auth/info/failure"]({
            error: `Unexpected status code: ${response.status}`,
            status: response.status,
          })
        );
        return {
          success: false,
          data: null,
        };
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          const { status } = error.response;
          console.log(`${status}`, error);

          switch (status) {
            case 401:
              dispatch(
                actions["auth/info/failure"]({
                  error: "Token invalid. Authentication required.",
                  status: status,
                })
              );
              break;
            case 404:
              dispatch(
                actions["auth/info/failure"]({
                  error: "An error occurred. Please try again later.",
                  status: status,
                })
              );
              break;
            case 500:
              dispatch(
                actions["auth/info/failure"]({
                  error: "Server error. Please try again later.",
                  status: status,
                })
              );
              break;
            default:
              dispatch(
                actions["auth/info/failure"]({
                  error: `Unexpected status code: ${status}`,
                  status: status,
                })
              );
          }
        }
      }
      return {
        success: false,
        data: null,
      };
    }
  };
});

export const navigateTo = dispatchable((path: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch(actions["auth/navigate"]({ path: path }));
  };
});

export const clearNavigate = dispatchable(() => {
  return (dispatch: Dispatch<Action>) => {
    dispatch(actions["auth/clear/navigate"]());
  };
});

export const setEmail = dispatchable((email: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch(actions["auth/email"]({ email: email }));
  };
});

export const clearEmail = dispatchable(() => {
  return (dispatch: Dispatch<Action>) => {
    dispatch(actions["auth/clear/email"]());
  };
});

export const requestResetPassword = dispatchable(
  (email: string, recaptchaUserResponse: string, role: string) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        dispatch(actions["auth/resetpassword/request"]());

        const requestBody = {
          email: email,
          recaptchaUserResponse: recaptchaUserResponse,
        };

        const response = await api.post(
          `auth/${role}/requestResetPassword`,
          requestBody,
          { requiresAuth: false }
        );

        if (response.status === 200) {
          dispatch(actions["auth/resetpassword/request/success"]());

          return { success: true, data: null };
        } else {
          dispatch(
            actions["auth/resetpassword/request/failure"]({
              error: response.data.error,
              status: response.status,
            })
          );

          return { success: false, data: null };
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            const { status } = error.response;
            dispatch(
              actions["auth/resetpassword/request/failure"]({
                error: error.message,
                status: status,
              })
            );
          }
        }
        return {
          success: false,
          data: null,
        };
      }
    };
  }
);

export const resetPassword = dispatchable(
  (email: string, otp: string, newPassword: string, role: string) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        dispatch(actions["auth/resetpassword"]());

        const requestBody = {
          email: email,
          otp: otp,
          newPassword: newPassword,
        };

        const response = await api.post(
          `auth/${role}/resetPassword`,
          requestBody,
          { requiresAuth: false }
        );

        if (response.status === 200) {
          dispatch(actions["auth/resetpassword/success"]());

          return { success: true, data: null };
        } else {
          dispatch(
            actions["auth/resetpassword/failure"]({
              error: "Unauthorized",
              status: response.status,
            })
          );

          return { success: false, data: null };
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            const { status } = error.response;
            dispatch(
              actions["auth/resetpassword/failure"]({
                error: error.message,
                status: status,
              })
            );
          }
        }
        return {
          success: false,
          data: null,
        };
      }
    };
  }
);
