import React, { useEffect, useState } from 'react';
import SpatialVideo from "../../components/Video/SpatialVideo";
import './SpatialVideoShowcase.css';
import { Campaign } from '../../types/types';
import { Loader } from '../../components';
import { generateMockCampaign, LOCAL_MEDIA_SERVER_URL, USE_LOCAL_MEDIA_SERVER } from '../../mock/mockData';
import DolbyVisionIcon from '../../assets/logo/dolby-vision-logo.png';
import DolbyAtmosIcon from '../../assets/logo/dolby-atmos-logo.png';

const SpatialVideoShowcase: React.FC = () => {

    return (
        <>
            <SpatialVideoList
                isLoading={false}
                campaigns={spatialCampaigns} />
        </>
    )
}

interface SpatialVideoListProps {
    campaigns: Campaign[];
    isLoading: boolean;
}

const SpatialVideoList: React.FC<SpatialVideoListProps> = ({ campaigns, isLoading }) => {
    if (isLoading) return <Loader />
    return (
       <div className='spatial-video-list'>
            <h1>Dolby Vision / Atmos Spatial Video Showcase</h1>
            {campaigns.map((campaign, i) => {
            return (
                <div className='spatial-video-card' key={campaign.id}>
                    <SpatialVideo
                        mvUrl={undefined}
                        sbsUrl={campaign.spatialVideoUrl} 
                        disablePreload={true}
                    />
                    <div className='spatial-card-header'>
                        <h3>{campaign.title}</h3>
                        <div className='dolby-icons'>
                            <img src={DolbyVisionIcon} alt="Dolby Vision" />
                            <img src={DolbyAtmosIcon} alt="Dolby Atmos" />
                        </div>
                    </div>
                    <p>{campaign.articleText}</p>
                </div>
            )
        })}
       </div>
    )
}

export default SpatialVideoShowcase;


const spatialCampaigns = [
    generateMockCampaign(
        {
          id: 0,
          title: "MTV Challenge 40: Battle of the Eras",
          subtitle: 
          `A video recap of the event that took place August 10, 2024 at Memorial Colliseum in Los Angeles, CA.`,
          heroImageUrl: USE_LOCAL_MEDIA_SERVER ? `${LOCAL_MEDIA_SERVER_URL}/hero-images/xrtrailers_hero_mandalorean_02.png` :
            "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/0ca406bc-b676-4259-994b-0c55bd8ee900/featuredHeader",
          thumbnailUrl: USE_LOCAL_MEDIA_SERVER ? `${LOCAL_MEDIA_SERVER_URL}/thumbnails/xrtrailers_thumbnail_mario_odssy_320x180.jpg` :
            "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/808c1fc2-67ca-4997-8f4c-42297ac36c00/tile",
          articleText: "A video recap of the event that took place August 10, 2024 at Memorial Colliseum in Los Angeles, CA.",
          videoLink: USE_LOCAL_MEDIA_SERVER ? `${LOCAL_MEDIA_SERVER_URL}/video/themandalorian_trailer.mp4` :
            "cc0e1a7157a9185a24346d42dc60b10a",
          spatialVideoLink: USE_LOCAL_MEDIA_SERVER ? `${LOCAL_MEDIA_SERVER_URL}/spatial-dvh/MTVChallenge40_10b_dvh_08112027_16x9_2160p_spatial_eac3.mov` :
            "https://assets.xrtrailers.xyz/spatial-dvh/MTVChallenge40_10b_dvh_08112027_16x9_2160p_spatial_eac3.mov",
          sideBySideUrl: USE_LOCAL_MEDIA_SERVER ? `${LOCAL_MEDIA_SERVER_URL}/sbs/themandalorian_trailer_sbs.mp4` :
            "https://assets.xrtrailers.xyz/sbs/themandalorian_trailer_sbs.mp4"
        }
      ),
      generateMockCampaign(
        {
          id: 1,
          title: "Natural History Museum - Los Angeles",
          subtitle: 
          `An exciting walkthrough of select exhibits at the Natural History Museum in Los Angeles.`,
          heroImageUrl: USE_LOCAL_MEDIA_SERVER ? `${LOCAL_MEDIA_SERVER_URL}/hero-images/xrtrailers_hero_mandalorean_02.png` :
            "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/0ca406bc-b676-4259-994b-0c55bd8ee900/featuredHeader",
          thumbnailUrl: USE_LOCAL_MEDIA_SERVER ? `${LOCAL_MEDIA_SERVER_URL}/thumbnails/xrtrailers_thumbnail_mario_odssy_320x180.jpg` :
            "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/808c1fc2-67ca-4997-8f4c-42297ac36c00/tile",
          articleText: "An exciting walkthrough of select exhibits at the Natural History Museum in Los Angeles.",
          videoLink: USE_LOCAL_MEDIA_SERVER ? `${LOCAL_MEDIA_SERVER_URL}/video/themandalorian_trailer.mp4` :
            "cc0e1a7157a9185a24346d42dc60b10a",
          spatialVideoLink: USE_LOCAL_MEDIA_SERVER ? `${LOCAL_MEDIA_SERVER_URL}/spatial-dvh/NatHist_dvh_atmos.mov` :
            "https://assets.xrtrailers.xyz/spatial-dvh/NatHist_dvh_atmos.mov",
          sideBySideUrl: USE_LOCAL_MEDIA_SERVER ? `${LOCAL_MEDIA_SERVER_URL}/sbs/themandalorian_trailer_sbs.mp4` :
            "https://assets.xrtrailers.xyz/sbs/themandalorian_trailer_sbs.mp4"
        }
      ),
      generateMockCampaign(
        {
            id: 2,
            title: "San Ramon Waterfall Park",
            subtitle: "",
            heroImageUrl: '',
            thumbnailUrl: '',
            articleText: 'A stroll around the beautiful Waterfall Park in San Ramon, CA.',
            spatialVideoLink: USE_LOCAL_MEDIA_SERVER ? `${LOCAL_MEDIA_SERVER_URL}/spatial-dvh/SanRamonWaterfallPark_atmos_master_eac3_v2.mov` : 
                "https://assets.xrtrailers.xyz/spatial-dvh/SanRamonWaterfallPark_atmos_master_eac3_v2.mov"
        }
      ),
      generateMockCampaign(
        {
            id: 3,
            title: "San Ramon Central Park",
            subtitle: "",
            heroImageUrl: '',
            thumbnailUrl: '',
            articleText: 'A walkthrough the gardens of Central Park in San Ramon, CA.',
            spatialVideoLink: USE_LOCAL_MEDIA_SERVER ? `${LOCAL_MEDIA_SERVER_URL}/spatial-dvh/SanRamonCentralPark_SBS_dvh_noaudio_spatial_eac3.mov` : 
                "https://assets.xrtrailers.xyz/spatial-dvh/SanRamonCentralPark_SBS_dvh_noaudio_spatial_eac3.mov"
        }
      ),
      generateMockCampaign(
        {
            id: 4,
            title: "Ralph Gracie Jiu Jitsu - San Francisco, CA",
            subtitle: "",
            heroImageUrl: '',
            thumbnailUrl: '',
            articleText: 'A backstage view of a volumetric capture of jiu jitsu training held at Ralph Gracie Jiu Jitsu in San Francisco, CA.',
            spatialVideoLink: USE_LOCAL_MEDIA_SERVER ? `${LOCAL_MEDIA_SERVER_URL}/spatial-dvh/07272024GracieSF_v2_eac3.mov` : 
                "https://assets.xrtrailers.xyz/spatial-dvh/07272024GracieSF_v2_eac3.mov"
        }
      ),
      generateMockCampaign({
        id: 4,
        title: "Tide Memories",
        subtitle: "Memories vs. stains",
        heroImageUrl: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/hero-images/Tide_hero_banner.jpg`
        : "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/4167f3d1-9491-4312-cdb5-0750d74df700/featuredHeader",
        thumbnailUrl: "",
        articleText:
        "Tide HE Turbo Clean detergents feature Smart Suds™ technology. The quick-collapsing suds of this high-efficiency detergent clean faster and rinse out quicker, even in cold cycles. Try the refreshing Mountain Spring or Clean Breeze scent. Both varieties are available in two formulas for use in regular and HE washing machines.",
        videoLink: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/video/tide_commercial_video.mp4`
        : "e750629e571189e793994dec33a214ea",
        spatialVideoLink: 
        USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/spatial/TideConcept24_atmos_spatial.mov` :
        "https://assets.xrtrailers.xyz/spatial/TideConcept24_atmos_spatial.mov",
        targetLink:
        "https://www.hulu.com/series/wu-tang-an-american-saga-8e4e1643-1254-4d6e-a567-d2c62f7b3e00",
        editor: undefined,
    }
    )
]