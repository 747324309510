import { Dispatch } from "redux";
import apiClient from "../../mock/apiClient";
import { Campaign, Action } from "../../types/types";
import { actions } from "./campaigns.slice";
import { dispatchable } from "../dispatchable";
import api from "../../services/api";
import axios from "axios";

export const getAllCampaigns = dispatchable(() => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const response = await apiClient.get<Campaign[]>(`/campaign`);
      dispatch(actions["campaigns/load"](response.data));
      console.log("Fetching all campaigns: ", response.data);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        data: null,
      };
    }
  };
});

export const getCampaignById = dispatchable((id: string | undefined) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      dispatch(actions["campaigns/campaign"]({ id: id }));
      console.log("Fetching campaign by id: ", id);
      return {
        success: true,
        data: null,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        data: null,
      };
    }
  };
});

export const addCampaign = dispatchable(
  (
    title: string,
    subTitle: string,
    heroImageUrlId: number,
    thumbnailUrlId: number,
    articleText: string,
    videos: any,
    galleryImages: any[],
    targetLink: string,
    companyPublicId: string
  ) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const requestBody = {
          title: title,
          subtitle: subTitle,
          oldHeroImageUrlId: heroImageUrlId,
          oldThumbnailUrlId: thumbnailUrlId,
          experiencesEnabled: false,
          articleText: articleText,
          galleryType: "DUAL_IMAGE",
          targetLink: targetLink,
          oldGalleryImages: galleryImages,
          oldVideos: videos,
          companyCreated: true,
          companyPublicId: [companyPublicId],
        };

        console.log(requestBody);

        const response = await api.post("/campaign", requestBody, {
          requiresAuth: true,
        });

        if (response.status === 200) {
          dispatch(actions["campaigns/add"]());
          console.log(response.data.info);

          return { success: true, data: response.data.info };
        } else {
          return { success: false, data: response.data };
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            const { status } = error.response;
            console.log(`${status}`, error);
          }
        }
        return { success: false, data: null };
      }
    };
  }
);

export const fetchAllCampaigns = dispatchable(
  (queryParams?: Record<string, any>) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const params = queryParams
          ? new URLSearchParams(queryParams).toString()
          : "";

        const response = await api.post(
          `/campaign/list${params ? `?${params}` : ""}`,
          {},
          { requiresAuth: false }
        );

        if (response.status === 200) {
          dispatch(actions["campaigns/fetch"](response.data.info.campaigns));
          console.log(response.data.info.campaigns);

          return { success: true, data: response.data.info.campaigns };
        } else {
          return { success: false, data: response.data };
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            const { status } = error.response;
            console.log(`${status}`, error);
          }
        }
        return { success: false, data: null };
      }
    };
  }
);

export const fetchCampaignById = dispatchable((campaignId: number) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const response = await api.get(`campaign/details/${campaignId}`);

      if (response.status === 200) {
        dispatch(actions["campaigns/campaign/fetch"](response.data.info));

        return { success: true, data: response.data.info };
      } else {
        return { success: false, data: response.data };
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          const { status } = error.response;
          console.log(`${status}`, error);
        }
      }
      return { success: false, data: null };
    }
  };
});
