import { createSlice } from "@reduxjs/toolkit";
import { AuthState } from "./auth.entity";
import authReducers from "./auth.reducer";

export const initialState: AuthState = {
  user: null,
  email: null,
  role: null,
  info: null,
  isAuthenticated: false,
  loading: false,
  error: null,
  status: null,
  path: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: authReducers,
});

export const { actions, reducer } = authSlice;
