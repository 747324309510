import React, { useEffect, useState } from "react";
import "./Admin.css";
import { Descendant } from "slate";
import {
  InputField,
  ParagraphEditor,
  PreUploadedMultiSelector,
  PreUploadedSelector,
} from "../../components";
import {
  serialize,
  deserialize,
  extractTextFromDescendants,
} from "../../utils/slateUtils";
import AdminSideNavBar from "./AdminSideNavBar";
import { RootState, actions, useAppSelector } from "../../store/root.store";
import { getUserRole, getAccessToken } from "../../utils/auth";

const AdminAddCampaign: React.FC = () => {
  const [title, setTitle] = useState<Descendant[]>(serialize(""));
  const [subTitle, setSubTitle] = useState<Descendant[]>(serialize(""));
  const [heroImage, setHeroImage] = useState<any>({});
  const [thumbnail, setThumbnail] = useState<any>({});
  const [articleText, setArticleText] = useState<Descendant[]>(serialize(""));
  const [targetLink, setTargetLink] = useState<Descendant[]>(serialize(""));
  const [next, setNext] = useState<boolean>(false);
  const [video, setVideo] = useState<any>({});
  const [galleryImages, setGalleryImages] = useState<string[]>([]);
  const [error, setError] = useState<string>("");

  const heroImagesToSelect = useAppSelector(
    (state: RootState) => state.media.heros
  );
  const thumbnailImagesToSelect = useAppSelector(
    (state: RootState) => state.media.thumbnails
  );
  const videosToSelect = useAppSelector(
    (state: RootState) => state.media.standardVideos
  );
  const galleryImagesToSelect = useAppSelector(
    (state: RootState) => state.media.galleryImages
  );
  const companyPublicId = useAppSelector(
    (state: RootState) => state.auth.info.company?.publicId
  );

  const handleTitleChange = (title: Descendant[]) => {
    setTitle(title);
  };

  const handleSubTitleChange = (subTitle: Descendant[]) => {
    setSubTitle(subTitle);
  };

  const handleHeroImageChange = (asset: any) => {
    setHeroImage(asset);
  };

  const handleThumbnailChange = (asset: any) => {
    setThumbnail(asset);
  };

  const handleVideoChange = (asset: any) => {
    setVideo(asset);
  };

  const handleGalleryImageChange = (galleryImages: string[]) => {
    setGalleryImages(galleryImages);
  };

  const handleArticleTextChange = (articleText: Descendant[]) => {
    setArticleText(articleText);
  };

  const handleTargetLinkChange = (url: Descendant[]) => {
    setTargetLink(url);
  };

  const isEmptyObject = (obj: any) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const fetchAdminInfo = async () => {
    await actions.auth.fetchAdminInfo();
  };

  const handleAddCampaign = async () => {
    if (
      title === serialize("") ||
      subTitle === serialize("") ||
      isEmptyObject(heroImage) ||
      isEmptyObject(thumbnail) ||
      articleText === serialize("") ||
      targetLink === serialize("") ||
      isEmptyObject(video) ||
      galleryImages.length === 0
    ) {
      setError("Missing fields");
      return;
    }

    const res = await actions.campaign.addCampaign(
      extractTextFromDescendants(title),
      extractTextFromDescendants(subTitle),
      heroImage.id,
      thumbnail.id,
      deserialize(articleText),
      {
        youtubeIds: [],
        standardVideoIds: [{ id: video.id }],
        spatialVideosIds: [],
      },
      galleryImages
        .map((imageId) =>
          galleryImagesToSelect.find(
            (galleryImage) => galleryImage.imageId === imageId
          )
        )
        .map((gallery) => ({ id: gallery.id })),
      extractTextFromDescendants(targetLink),
      companyPublicId
    );

    if (res.success) {
      alert("campaign successfully created");
      console.log(res.data);
      actions.auth.navigateTo("/");
    } else {
      alert(`${res.data}`);
    }
  };

  const handleNext = () => {
    setNext(true);
  };

  const fetchHeroImagesToSelect = async (companyPublicId: string) => {
    await actions.media.fetchPreUploadedHeroImages(companyPublicId);
  };

  const fetchThumbnailImagesToSelect = async (companyPublicId: string) => {
    await actions.media.fetchPreUploadedThumbnailImages(companyPublicId);
  };

  const fetchVideosToSelect = async (companyPublicId: string) => {
    await actions.media.fetchPreUploadedStandardVideos(companyPublicId);
  };

  const fetchGalleryImagesToSelect = async (companyPublicId: string) => {
    await actions.media.fetchPreUploadedGalleryImages(companyPublicId);
  };

  const fetchYoutubeVideosToSelect = async (companyPublicId: string) => {
    await actions.media.fetchPreUploadedYoutubeVideos(companyPublicId);
  };

  const fetchSpatialVideosToSelect = async (companyPublicId: string) => {
    await actions.media.fetchPreUploadedSpatialVideos(companyPublicId);
  };

  useEffect(() => {
    if (getUserRole() === "admin" && !!getAccessToken()) {
      fetchAdminInfo();
    }
  }, []);

  useEffect(() => {
    fetchHeroImagesToSelect(companyPublicId);
    fetchThumbnailImagesToSelect(companyPublicId);
    fetchVideosToSelect(companyPublicId);
    fetchGalleryImagesToSelect(companyPublicId);
    fetchYoutubeVideosToSelect(companyPublicId);
    fetchSpatialVideosToSelect(companyPublicId);
  }, [companyPublicId]);

  return (
    <div className="component-container" style={{ paddingTop: 0 }}>
      <div className="admin-container">
        <AdminSideNavBar></AdminSideNavBar>
        <div className="campaign-creation-container">
          {" "}
          <div className="campaign-form">
            <InputField
              title="Title"
              initialValue={title}
              onChange={handleTitleChange}
              placeholder="Enter campaign title"
            ></InputField>
            <InputField
              title="Subtitle"
              initialValue={subTitle}
              onChange={handleSubTitleChange}
              placeholder="Enter campaign subtitle"
            ></InputField>
            <PreUploadedSelector
              title="Hero Image"
              handleAssetChange={handleHeroImageChange}
              isVideo={false}
              assets={heroImagesToSelect}
            ></PreUploadedSelector>
            <PreUploadedSelector
              title="Thumbnail Image"
              handleAssetChange={handleThumbnailChange}
              isVideo={false}
              assets={thumbnailImagesToSelect}
            ></PreUploadedSelector>
            <ParagraphEditor
              initialValue={articleText}
              onChange={handleArticleTextChange}
              title="Article Text"
              placeholder="Enter article text here (press enter to start new paragraph)"
            />
            <div className="buttons">
              <button className="create-campaign-button" onClick={handleNext}>
                Next
              </button>
            </div>
          </div>
          {next && (
            <div className="campaign-preview">
              <PreUploadedSelector
                title="Video"
                handleAssetChange={handleVideoChange}
                isVideo={true}
                assets={videosToSelect}
              ></PreUploadedSelector>
              <PreUploadedMultiSelector
                title="Gallery Images"
                handleAssetsChange={handleGalleryImageChange}
                assets={galleryImagesToSelect}
                isVideo={false}
                maxAssetCount={6}
              ></PreUploadedMultiSelector>
              <InputField
                title="Target Link"
                initialValue={targetLink}
                onChange={handleTargetLinkChange}
                placeholder="Enter target link"
              ></InputField>
              {error !== "" && <h4>{error}</h4>}
              <div className="buttons">
                <button
                  className="create-campaign-button"
                  onClick={handleAddCampaign}
                >
                  Create Campaign
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminAddCampaign;
