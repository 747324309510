import React, { useEffect, useState } from "react";
import {
  NavBar,
  Carousel,
  ContentRowSlider,
  HighlightsRow,
  Loader,
} from "../../components";
import "./Home.css";
import "../../styles/AllPages.css";
import { useAppSelector } from "../../store/root.store";
import { RootState } from "../../store/root.store";
import { actions } from "../../store/root.store";
import { useNav } from "../../utils/navigation";
import useWindowSize from "../../utils/useWindowSize";

/**
 * Home page for displaying the homepage with various sections.
 */
const Home: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { goToDetailsPage, goToMorePage } = useNav();
  const { width } = useWindowSize();

  // Fetches campaigns, categories, and selected category from the Redux store
  const campaigns = useAppSelector(
    (state: RootState) => state.campaign.campaigns
  );

  const categories = useAppSelector(
    (state: RootState) => state.category.categories
  );

  const selectedCategory = useAppSelector(
    (state: RootState) => state.category.selectedCategory
  );

  const users = useAppSelector((state: RootState) => state.user.users);

  // Fetch all campaigns
  const handleGetCampaigns = async () => {
    setLoading(true);
    await actions.campaign.getAllCampaigns();
    await actions.campaign.fetchAllCampaigns({ page: 1 });
    setLoading(false);
  };

  // Fetch all users
  const handleGetUsers = async () => {
    setLoading(true);
    await actions.user.getAllUsers();
    setLoading(false);
  };

  // Fetch all categories
  const handleGetCategories = async () => {
    setLoading(true);
    await actions.category.getAllCategories();
    setLoading(false);
  };

  const handleChangeCategory = async (id: number) => {
    await actions.category.getCategoryById(id);
  };

  // Fetch campaigns, users, and categories on component mount
  useEffect(() => {
    handleGetCampaigns();
    handleGetUsers();
    handleGetCategories();
  }, []);

  if (loading) {
    return (
      <div className="loader-container">
        <Loader width={50} height={50}></Loader>
      </div>
    );
  }

  if (campaigns.length < 1) {
    return <div>No campaigns found.</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <>
        {width >= 600 && (
          <NavBar
            categories={categories}
            selectedCategory={selectedCategory}
            handleChangeCategory={handleChangeCategory}
          ></NavBar>
        )}
      </>
      <>
        <Carousel
          campaigns={campaigns}
          onHeroImageClick={goToDetailsPage}
          intervalTime={10000}
        ></Carousel>
      </>
      <>
        <ContentRowSlider
          title="UPCOMING"
          campaigns={[...campaigns.slice(2, 3), campaigns[5]]}
          onThumbnailClick={goToDetailsPage}
          onMoreThumbnailClick={goToMorePage}
        ></ContentRowSlider>
      </>
      <>
        <ContentRowSlider
          title="TOP NEWS"
          campaigns={[
            ...campaigns.slice(1, 2),
            ...campaigns.slice(3, 5),
            ...campaigns.slice(6, 8),
          ]}
          onThumbnailClick={goToDetailsPage}
          onMoreThumbnailClick={goToMorePage}
        ></ContentRowSlider>
      </>
      <>
        <ContentRowSlider
          title="BUSINESS & SERVICES"
          campaigns={[...campaigns.slice(8)]}
          onThumbnailClick={goToDetailsPage}
          onMoreThumbnailClick={goToMorePage}
        ></ContentRowSlider>
      </>
      <>
        <HighlightsRow
          campaign={campaigns[2]}
          onHeroImageClick={goToDetailsPage}
        ></HighlightsRow>
      </>
    </>
  );
};

export default Home;
