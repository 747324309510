import {
  Admin,
  Campaign,
  Category,
  Company,
  GalleryImage,
  Profile,
  User,
  ImmersiveMedia,
  CompanyProfile,
} from "../types/types";
import { faker } from "@faker-js/faker";

export const USE_LOCAL_MEDIA_SERVER = false;
export const LOCAL_MEDIA_SERVER_URL = "http://172.31.1.108:8080/media"; // Set to local url ip

// Helper function to generate a random number within a range
const getRandomNumber = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

// Generate a mock user
const generateMockUser = (profileId?: number, profile?: Profile): User => ({
  id: faker.number.int(),
  email: faker.internet.email(),
  password: faker.internet.password(),
  profileId: profileId ?? faker.number.int(),
  createdAt: faker.date.past(),
  updatedAt: faker.date.recent(),
  editorCompanies: [], // To be populated based on relations
  editorCampaigns: [], // To be populated based on relations
  profile: profile,
});

// Generate a mock admin
const generateMockAdmin = (): Admin => ({
  id: faker.number.int(),
  email: faker.internet.email(),
  password: faker.internet.password(),
  roleId: faker.number.int(),
  createdAt: faker.date.past(),
  updatedAt: faker.date.recent(),
  companyId: faker.number.int(),
  company: {} as Company, // To be populated based on relations
  enabled: faker.datatype.boolean(),
  isVerify: true,
  username: faker.string.alphanumeric(),
});

interface MockProfileConfig {
  id?: number;
  firstName?: string;
  lastName?: string;
  userId?: number;
  imageUrl?: string;
}

// Generate a mock profile
const generateMockProfile = (config?: MockProfileConfig): Profile => ({
  id: config?.id ?? faker.number.int(),
  firstName: config?.firstName ?? faker.person.firstName(),
  lastName: config?.lastName ?? faker.person.lastName(),
  userId: config?.userId ?? faker.number.int(),
  imageUrl: config?.imageUrl ?? faker.image.url(),
  categories: [], // To be populated based on relations
  companies: [], // To be populated based on relations
  user: {} as User, // To be populated based on relations
});

// Generate a mock company
const generateMockCompany = (): Company => ({
  id: faker.number.int(),
  name: faker.company.name(),
  adminId: faker.number.int(),
  profiles: [], // To be populated based on relations
  createdAt: faker.date.past(),
  updatedAt: faker.date.recent(),
  editor: [], // To be populated based on relations
  editorId: faker.number.int(),
  admin: [], // To be populated based on relations
});

// Generate a mock category
const generateMockCategory = (name: string): Category => ({
  id: faker.number.int(),
  name: name,
  profiles: [], // To be populated based on relations
  campaigns: [], // To be populated based on relations
});

interface MockCampaignConfig {
  id: number;
  title: string;
  subtitle: string;
  heroImageUrl: string;
  thumbnailUrl: string;
  articleText: string;
  videoLink?: string;
  spatialVideoLink?: string;
  sideBySideUrl?: string;
  targetLink?: string;
  immersiveMedia?: ImmersiveMedia;
  editor?: User;
  youtubeId?: string;
  galleryImages?: GalleryImage[];
}

// Generate a mock campaign
const generateMockCampaign = (config: MockCampaignConfig): Campaign => ({
  id: config.id,
  createdAt: faker.date.past(),
  updatedAt: faker.date.recent(),
  approvedAt: faker.date.recent(),
  title: config.title,
  subtitle: config.subtitle,
  heroImageUrl: config.heroImageUrl,
  thumbnailUrl: config.thumbnailUrl,
  experiencesEnabled: faker.datatype.boolean(),
  immersiveMediaId: faker.number.int(),
  immersiveMedia: config.immersiveMedia ?? {
    id: 0,
    vrUrl: "",
    arUrl: "",
    threeSixtyUrl: "",
  },
  articleText: config.articleText,
  galleryImages: config.galleryImages ?? [], // To be populated based on relations
  categoryId: faker.number.int(),
  category: {} as Category, // To be populated based on relations
  companyCreated: faker.datatype.boolean(),
  companyProfiles: [], // To be populated based on relations
  enabled: faker.datatype.boolean(),
  editorId: config.editor?.id ?? faker.number.int(),
  editor: config.editor ?? ({} as User),
  targetLink: config.targetLink ?? "https://www.youtube.com",
  fallbackLink: "https://www.google.com",
  spatialVideoUrl: config.spatialVideoLink,
  videoLink: config.videoLink,
  sideBySideUrl: config.sideBySideUrl,
  youtubeId: config.youtubeId,
});

// Generate a mock gallery image
const generateMockGalleryImage = (): GalleryImage => ({
  id: faker.number.int(),
  url: faker.image.url({ height: 320 }),
  imageId: "",
  alt: faker.lorem.words(2),
});

// Function to generate mock items for all entities
const generateMockItems = (entity: string, count: number): any[] => {
  switch (entity) {
    case "User":
      return Array.from({ length: count }, () => generateMockUser());
    case "Admin":
      return Array.from({ length: count }, () => generateMockAdmin());
    case "Profile":
      return Array.from({ length: count }, () => generateMockProfile());
    case "Company":
      return Array.from({ length: count }, () => generateMockCompany());
    case "GalleryImage":
      return Array.from({ length: count }, () => generateMockGalleryImage());
    default:
      throw new Error(`Unknown entity type: ${entity}`);
  }
};

const generateMockCategories = (categories: string[]) => {
  return categories.map((category) => generateMockCategory(category));
};

const generateMockEditor = (): User => {
  const profile = generateMockProfile({
    firstName: "Editor",
    lastName: "Name",
    imageUrl:
      "https://t4.ftcdn.net/jpg/00/64/67/27/360_F_64672736_U5kpdGs9keUll8CRQ3p3YaEv2M6qkVY5.jpg",
  });
  const user = generateMockUser(profile.id, profile);
  return user;
};

const generateMockCampaigns = (): Campaign[] => {
  const campaigns: Campaign[] = [];
  const mockEditor = generateMockEditor();

  campaigns.push(
    generateMockCampaign({
      id: 1,
      title: "Star Wars The Mandalorian",
      subtitle: `The travels of a lone bounty hunter in 
          the outer reaches of the galaxy, far 
          from the authority of the New Republic.`,
      heroImageUrl: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/hero-images/xrtrailers_hero_mandalorean_02.png`
        : "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/0ca406bc-b676-4259-994b-0c55bd8ee900/featuredHeader",
      thumbnailUrl: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/thumbnails/xrtrailers_thumbnail_mario_odssy_320x180.jpg`
        : "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/808c1fc2-67ca-4997-8f4c-42297ac36c00/tile",
      articleText:
        "After the fall of the Galactic Empire, a lone gunfighter makes his way through the outer reaches of the lawless galaxy.",
      videoLink: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/video/themandalorian_trailer.mp4`
        : "cc0e1a7157a9185a24346d42dc60b10a",
      spatialVideoLink: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/spatial/themandalorian_trailer_spatial.mov`
        : "https://assets.xrtrailers.xyz/spatial/themandalorian_trailer_spatial.mov",
      sideBySideUrl: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/sbs/themandalorian_trailer_sbs.mp4`
        : "https://assets.xrtrailers.xyz/sbs/themandalorian_trailer_sbs.mp4",
      targetLink:
        "https://www.disneyplus.com/series/the-mandalorian/3jLIGMDYINqD",
      immersiveMedia: {
        id: 5,
        vrUrl:
          "https://www.xrtrailers.io/experience/mandalorian/mandalorian_experience_vr/",
        arUrl: "",
        threeSixtyUrl: "",
      },
      editor: mockEditor,
      galleryImages: [
        {
          id: 0,
          url: USE_LOCAL_MEDIA_SERVER ? 
            `${LOCAL_MEDIA_SERVER_URL}/gallery/mandalorian/playtrailers_mandalorian_gallery_01.jpg` : 
            "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/d9edd254-f5c7-42a4-1137-f9fbb4b04c00/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 1,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/mandalorian/playtrailers_mandalorian_gallery_02.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/c79c4bbe-1430-4458-286c-6e421f277800/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 2,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/mandalorian/playtrailers_mandalorian_gallery_03.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/a102e0fe-7c00-4032-5fe1-00fe1976d600/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 3,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/mandalorian/playtrailers_mandalorian_gallery_04.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/4fde2076-4dbc-4299-1c7d-b68393cfae00/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 4,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/mandalorian/playtrailers_mandalorian_gallery_05.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/ac0ab21b-2608-4340-c6d9-47195d5f4800/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 5,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/mandalorian/playtrailers_mandalorian_gallery_06.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/179b121e-f098-48a4-7889-6ce06e08a800/gallery",
          imageId: '',
          alt: ''
        }
      ]
    })
  );

  campaigns.push(
    generateMockCampaign({
      id: 2,
      title: "Marvel Loki Series",
      subtitle: "Loki",
      heroImageUrl: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/hero-images/xrtrailers_hero_loki_hero_banner.jpg`
        : "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/6ccbc6b1-c1fc-4bc3-f13b-b1f580cb9900/featuredHeader",
      thumbnailUrl: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/thumbnails/xrtrailers_thumbnail_loki_season_1_320x180.jpg`
        : "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/808c1fc2-67ca-4997-8f4c-42297ac36c00/tile",
      articleText: "Loki article text",
      videoLink: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/video/lokiofficialtrailer.mp4`
        : "e999a69b14799118ecef5ec5088d6368",
      spatialVideoLink: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/spatial/loki_trailer_spatial.mov`
        : "https://assets.xrtrailers.xyz/spatial/loki_trailer_spatial.mov",
      sideBySideUrl: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/sbs-video/loki_trailer_sbs.mp4`
        : "https://assets.xrtrailers.xyz/sbs/loki_trailer_sbs.mp4",
      immersiveMedia: {
        id: 3,
        vrUrl: "https://www.xrtrailers.io/experience/loki/loki_experience_vr/",
        arUrl: "",
        threeSixtyUrl: "",
      },
      targetLink: "https://www.disneyplus.com/series/loki/6pARMvILBGzF",
      editor: mockEditor,
      galleryImages: [
        {
          id: 0,
          url: USE_LOCAL_MEDIA_SERVER ? 
            `${LOCAL_MEDIA_SERVER_URL}/gallery/loki/playtrailers_loki_gallery_01.jpg` : 
            "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/610d3203-b985-44fb-19a7-64af86ee4000/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 1,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/loki/playtrailers_loki_gallery_02.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/58a504cb-fa9c-490c-9e48-292d0af36000/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 2,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/loki/playtrailers_loki_gallery_03.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/9eb90b2b-20dc-4641-4afe-c4cfd644a600/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 3,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/loki/playtrailers_loki_gallery_04.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/e422211c-235e-4632-12a5-b2a09b562a00/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 4,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/loki/playtrailers_loki_gallery_05.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/d6710b8b-4648-45d5-b2c7-d57b7877b800/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 5,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/loki/playtrailers_loki_gallery_06.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/babbf8d0-8587-49ef-ada1-b2a15dc8bb00/gallery",
          imageId: '',
          alt: ''
        }
      ]
    })
  );

  campaigns.push(
    generateMockCampaign({
      id: 3,
      title: "Super Mario Odyssey",
      subtitle: "Odyssey",
      heroImageUrl: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/hero-images/xrtrailers_supermarioodyssey.jpg`
        : "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/076d8e08-65d7-4bc3-1326-a187f7e94c00/featuredHeader",
      thumbnailUrl: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/thumbnails/xrtrailers_thumbnail_mario_odssy_320x180.jpg`
        : "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/6df94e03-3dba-49b0-b59d-7f0b8407bb00/tile",
      articleText:
        "Explore incredible places far from the Mushroom Kingdom as you join Mario and his new ally Cappy on a massive, globe-trotting 3D adventure. Use amazing new abilities—like the power to capture and control objects, animals, and enemies—to collect Power Moons so you can power up the Odyssey airship and save Princess Peach from Bowser’s wedding plans!",
      videoLink: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/video/supermarioodyssey_trailer.mp4`
        : "9d4f2f2dd410568b0f419b3e0ab55262",
      immersiveMedia: {
        id: 0,
        vrUrl:
          "https://www.xrtrailers.io/experience/mariodyssey/odyssey_experience_vr_v2/",
        arUrl:
          "https://www.xrtrailers.io/experience/mariodyssey/odyssey_experience_vr/",
        threeSixtyUrl: "",
      },
      targetLink:
        "https://www.nintendo.com/us/store/products/super-mario-odyssey-switch/",
      editor: mockEditor,
      galleryImages: [
        {
          id: 0,
          url: USE_LOCAL_MEDIA_SERVER ? 
            `${LOCAL_MEDIA_SERVER_URL}/gallery/mario/playtrailers_mario_odyssey_gallery_01.jpg` : 
            "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/3ba032c1-fe7a-4381-59eb-12333bbaaf00/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 1,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/mario/playtrailers_mario_odyssey_gallery_02.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/730efd64-8bdc-4833-3892-917e441ab000/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 2,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/mario/playtrailers_mario_odyssey_gallery_03.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/f517e9a6-ebf6-4ae5-ba95-19d33974bd00/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 3,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/mario/playtrailers_mario_odyssey_gallery_04.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/16cf9f1b-4b94-4c4d-ac0e-84922e161900/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 4,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/mario/playtrailers_mario_odyssey_gallery_05.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/229e2031-d4ed-426d-7c71-bed60b56c600/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 5,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/mario/playtrailers_mario_odyssey_gallery_06.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/0c5a5179-59d3-48c8-1187-8d1b7a6f0400/gallery",
          imageId: '',
          alt: ''
        }
      ]
    })
  );

  campaigns.push(
    generateMockCampaign({
      id: 4,
      title: "Hulu Original Series - WuTang Saga",
      subtitle: "Hulu Series - WuTang Saga",
      heroImageUrl: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/hero-images/xrtrailers_wutangsaga_hero.jpg`
        : "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/551a6e7a-0922-4244-08af-4095845b3700/featuredHeader",
      thumbnailUrl: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/thumbnails/xrtrailers_wutang_saga_thumbnail_v7.jpg`
        : "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/25fcfe8c-35f8-41b0-bdc9-aa3f061e2000/tile",
      articleText:
        "EMMY® NOMINATED Wu-Tang: An American Saga is inspired by “The Wu-Tang Manual” and “Tao of Wu”, and based on the true story of the Wu-Tang Clan. Set in early ’90s New York at the height of the crack cocaine epidemic, the show tracks the Clan’s formation, a vision of Bobby Diggs aka The RZA, who strives to unite a dozen young, black men that are torn between music and crime but eventually rise to become the unlikeliest of American success stories.",
      videoLink: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/video/xrtrailers_wusaga_intro.mp4`
        : "a10b8fbdc6bc67d5cf6dad7802174610",
      immersiveMedia: {
        id: 1,
        vrUrl:
          "https://www.xrtrailers.io/experience/wutangsaga/wutangsaga_experience_vr/",
        arUrl: "",
        threeSixtyUrl: "",
      },
      targetLink:
        "https://www.hulu.com/series/wu-tang-an-american-saga-8e4e1643-1254-4d6e-a567-d2c62f7b3e00",
      editor: mockEditor,
      galleryImages: [
        {
          id: 0,
          url: USE_LOCAL_MEDIA_SERVER ? 
            `${LOCAL_MEDIA_SERVER_URL}/gallery/wutang/playtrailers_wutangsaga_gallery_01.jpg` : 
            "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/a027ec4b-fb20-4ad6-fe66-7e6046499500/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 1,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/wutang/playtrailers_wutangsaga_gallery_02.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/c1903d11-f487-41f9-e45a-e75383140300/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 2,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/wutang/playtrailers_wutangsaga_gallery_03.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/790112a7-3a75-43ab-c9df-cc7127632900/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 3,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/wutang/playtrailers_wutangsaga_gallery_04.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/2c108947-cc1e-4328-e632-62730af1a200/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 4,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/wutang/playtrailers_wutangsaga_gallery_05.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/81492074-a481-44fc-e90b-daf5e3806200/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 5,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/wutang/playtrailers_wutangsaga_gallery_06.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/bd777f31-0c58-456b-fb17-12d25bda4200/gallery",
          imageId: '',
          alt: ''
        }
      ]
    })
  );
  

  campaigns.push(
    generateMockCampaign({
      id: 5,
      title: "NBA - Nets vs Golden State",
      subtitle: "NBA",
      heroImageUrl: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/hero-images/xrtrailers_hero_nba_goldenstate_vs_nets.jpg`
        : "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/7702d52d-6977-4bf1-395f-6605a22f6100/featuredHeader",
      thumbnailUrl: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/thumbnails/xrtrailers_nba_nets_vs_goldenstate_thumbnail.jpg`
        : "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/680bba89-4ed1-437d-6b55-197ff2e1be00/tile",
      articleText:
        "Stephen Curry looked at a box score that showed no Warriors but him made a 3-pointer. It certainly wasn't a pretty win, but in what's been a difficult season, Golden State will take them whatever way they come. Curry scored 29 points, Jonathan Kuminga added 28 and the Warriors shook off a poor start to beat the Brooklyn Nets 109-98 on Monday night.",
      immersiveMedia: {
        id: 2,
        vrUrl: "https://www.xrtrailers.io/experience/nba/nba_experience_vr",
        arUrl: "",
        threeSixtyUrl: "",
      },
      targetLink: "https://www.nba.com/game/gsw-vs-bkn-0022300717",
      editor: mockEditor,
      youtubeId: "IO88yp1p8s8",
    })
  );

  campaigns.push(
    generateMockCampaign({
      id: 6,
      title: "Transformers One",
      subtitle:
        "Built like brothers. Forged into enemies. Witness the origin that transformed our world",
      heroImageUrl: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/hero-images/playtrailers_transformers_one_hero_banner.jpg`
        : "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/6cf2f084-a397-4304-631a-782559b37500/featuredHeader",
      thumbnailUrl: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/thumbnails/playtrailers__transformers_one_thumbnail_320x180.jpg`
        : "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/ff21844f-8198-43ae-ad01-0737462bfe00/tile",
      articleText:
        "Built like brothers. Forged into enemies. Witness the origin that transformed our world.",
      videoLink: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/video/transformers_8bit_sdr_800p.mp4`
        : "cf279c0d4b3d85126957f8aaf228c5ba",
      spatialVideoLink: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/spatial/TRANSFORMERS_ONE_spatial.mov`
        : "https://assets.xrtrailers.xyz/spatial/TRANSFORMERS_ONE_spatial.mov",
      targetLink: "https://www.transformersmovie.com/",
      sideBySideUrl: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/sbs-video/transformers-one-trailer_sbs_7588x1600.mp4`
        : "https://assets.xrtrailers.xyz/sbs/transformers-one-trailer_sbs_7588x1600.mp4",
      editor: mockEditor,
      galleryImages: [
        {
          id: 0,
          url: USE_LOCAL_MEDIA_SERVER ? 
            `${LOCAL_MEDIA_SERVER_URL}/gallery/transformers/playtrailers_transformers_gallery_01.jpg` : 
            "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/7b7085db-72aa-4ab6-5354-54b2a94ec800/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 1,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/transformers/playtrailers_transformers_gallery_02.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/8b540529-5d6d-491a-8f41-dcdf37d43b00/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 2,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/transformers/playtrailers_transformers_gallery_03.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/0e9e852e-f8c8-4851-e176-6fac0c29af00/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 3,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/transformers/playtrailers_transformers_gallery_04.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/78136270-02b0-40e1-9158-0aa0b2638b00/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 4,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/transformers/playtrailers_transformers_gallery_05.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/66bf0f07-a72a-4245-c937-1e35c1fe6300/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 5,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/transformers/playtrailers_transformers_gallery_06.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/7908955e-e98f-4c4d-6c34-e4b0a447df00/gallery",
          imageId: '',
          alt: ''
        }
      ]
    })
  );

  campaigns.push(
    generateMockCampaign({
      id: 7,
      title: "Ahsoka",
      subtitle:
        "Former Jedi knight Ahsoka Tano investigates an emerging threat to a vulnerable galaxy.",
      heroImageUrl: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/hero-images/playtrailers_ashoka_hero_banner.jpg`
        : "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/5df05781-9185-4a3d-f1b0-409b6bf67b00/featuredHeader",
      thumbnailUrl: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/thumbnails/playtrailers_ashoka_thumbnail_320x180.jpg`
        : "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/a3bf6228-c25f-4cce-caff-2106602cb300/tile",
      articleText:
        "Former Jedi knight Ahsoka Tano investigates an emerging threat to a vulnerable galaxy.",
      videoLink: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/video/ahsoka_trailer.mp4`
        : "a017f6f4b923801df84c57c6c88998c5",
      spatialVideoLink: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/spatial/ahsoka_trailer_spatial.mov`
        : "https://assets.xrtrailers.xyz/spatial/ahsoka_trailer_spatial.mov",
      targetLink:
        "https://www.disneyplus.com/browse/entity-e64c22fc-fc7c-4ada-bbec-ab8351b8db97",
      sideBySideUrl: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/sbs-video/ahsoka_trailer_sbs.mp4`
        : "https://assets.xrtrailers.xyz/sbs/ahsoka_trailer_sbs.mp4",
      editor: mockEditor,
      galleryImages: [
        {
          id: 0,
          url: USE_LOCAL_MEDIA_SERVER ? 
            `${LOCAL_MEDIA_SERVER_URL}/gallery/ahsoka/playtrailers_ashoka_gallery_01.jpg` : 
            "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/a92107e6-8e70-4c73-f355-709b0a745700/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 1,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/ahsoka/playtrailers_ashoka_gallery_02.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/e91ea3b5-3773-4377-4677-1151aad14500/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 2,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/ahsoka/playtrailers_ashoka_gallery_03.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/e955cdf1-a103-4157-2ed1-4fe3044c8400/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 3,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/ahsoka/playtrailers_ashoka_gallery_04.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/39da4e9e-9804-4ed4-ddff-e49b98ec8d00/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 4,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/ahsoka/playtrailers_ashoka_gallery_05.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/d073d73a-8b69-4fe6-5b15-50e0c4242f00/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 5,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/ahsoka/playtrailers_ashoka_gallery_06.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/8c86cbc3-0713-4198-7f24-32a322449d00/gallery",
          imageId: '',
          alt: ''
        }
      ]
    })
  );

  campaigns.push(
    generateMockCampaign({
      id: 8,
      title: "Captain America: Brave New World",
      subtitle: "Anthony Mackie returns as the high-flying hero Sam Wilson.",
      heroImageUrl: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/hero-images/playtrailers_captain_america_hero_banner.jpg`
        : "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/7aa901f4-6d8e-4c15-a673-80986e136300/featuredHeader",
      thumbnailUrl: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/thumbnails/playtrailers_captain_america_thumbnail_320x180.jpg`
        : "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/5abe37bd-1e44-4519-0946-6186de0f3c00/tile",
      articleText:
        "Anthony Mackie returns as the high-flying hero Sam Wilson, who has officially taken up the mantle of Captain America. After meeting with newly elected U.S. President Thaddeus Ross, Sam finds himself in the middle of an international incident. He must discover the reason behind a nefarious global plot before the true mastermind has the entire world seeing red.",
      videoLink: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/video/captainamerica_brave_new_world_trailer.mp4`
        : "201571def08fa8a01e66d70bebd7cc9a",
      spatialVideoLink: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/spatial/captain_america_brave_spatial.mov`
        : "https://assets.xrtrailers.xyz/spatial/captain_america_brave_spatial.mov",
      sideBySideUrl: USE_LOCAL_MEDIA_SERVER
        ? `${LOCAL_MEDIA_SERVER_URL}/sbs-video/capitan-america-trailer_sbs_7588x1600.mp4`
        : "https://assets.xrtrailers.xyz/sbs/capitan-america-trailer_sbs_7588x1600.mp4",
      targetLink:
        "https://www.marvel.com/movies/captain-america-brave-new-world",
      editor: mockEditor,
      galleryImages: [
        {
          id: 0,
          url: USE_LOCAL_MEDIA_SERVER ? 
            `${LOCAL_MEDIA_SERVER_URL}/gallery/captain_america/playtrailers_captain_america_gallery_01.jpg` : 
            "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/589c4194-f881-4d44-8231-0d1d092c3700/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 1,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/captain_america/playtrailers_captain_america_gallery_02.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/0520d64a-23f8-452c-0048-dbd3c4979400/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 2,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/captain_america/playtrailers_captain_america_gallery_03.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/9c595960-9e41-498a-eddf-7d27cd732700/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 3,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/captain_america/playtrailers_captain_america_gallery_04.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/2c97fa1c-b0c6-4c15-3c01-677343596200/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 4,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/captain_america/playtrailers_captain_america_gallery_05.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/e1a3210e-20c9-49c5-e750-830a97c1ec00/gallery",
          imageId: '',
          alt: ''
        },
        {
          id: 5,
          url: USE_LOCAL_MEDIA_SERVER ? 
          `${LOCAL_MEDIA_SERVER_URL}/gallery/captain_america/playtrailers_captain_america_gallery_06.jpg` : 
          "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/e928a345-9610-43e1-340c-1106ff8d7700/gallery",
          imageId: '',
          alt: ''
        },
      ]
    })
  );

  return campaigns;
};

const categories = [
  "GAMES",
  "SPORTS",
  "JUX",
  "FILM",
  "AUTOMOTIVE",
  "EXPERIENCES",
];

// Generate all entities without relationships
const allUsers = generateMockItems("User", 10) as User[];
const allAdmins = generateMockItems("Admin", 5) as Admin[];
const allProfiles = generateMockItems("Profile", 10) as Profile[];
const allCompanies = generateMockItems("Company", 5) as Company[];
const allCategories = generateMockCategories(categories) as Category[];
const allCampaigns = generateMockCampaigns() as Campaign[];
const allGalleryImages = generateMockItems("GalleryImage", 6) as GalleryImage[];
let previewCampaign = {} as Campaign;

const setPreviewCampaign = (config: MockCampaignConfig) => {
  previewCampaign = generateMockCampaign(config);
};

// Establish relationships between entities
allUsers.forEach((user) => {
  user.editorCompanies = allCompanies
    .slice(0, getRandomNumber(1, allCompanies.length))
    .map((company) => company.id);
  user.editorCampaigns = allCampaigns
    .slice(0, getRandomNumber(1, allCampaigns.length))
    .map((campaign) => campaign.id);
});

allAdmins.forEach((admin) => {
  const company = allCompanies[getRandomNumber(0, allCompanies.length - 1)];
  admin.companyId = company.id;
});

allProfiles.forEach((profile) => {
  profile.categories = allCategories
    .slice(0, getRandomNumber(1, allCategories.length))
    .map((category) => category.id);
  profile.companies = allCompanies
    .slice(0, getRandomNumber(1, allCompanies.length))
    .map((company) => company.id);
  const user = allUsers[getRandomNumber(0, allUsers.length - 1)];
  profile.userId = user.id;
});

allCompanies.forEach((company) => {
  company.profiles = allProfiles
    .slice(0, getRandomNumber(1, allProfiles.length))
    .map((profile) => profile.id);
  company.admin = allAdmins
    .slice(0, getRandomNumber(1, allAdmins.length))
    .map((admin) => admin.id);
  company.editor = allUsers
    .slice(0, getRandomNumber(1, allUsers.length))
    .map((user) => user.id);
});

allCategories.forEach((category) => {
  category.profiles = allProfiles
    .slice(0, getRandomNumber(1, allProfiles.length))
    .map((profile) => profile.id);
  category.campaigns = allCampaigns
    .slice(0, getRandomNumber(1, allCampaigns.length))
    .map((campaign) => campaign.id);
});

allCampaigns.forEach((campaign) => {
  const category = allCategories[getRandomNumber(0, allCategories.length - 1)];
  campaign.categoryId = category.id;
  const editor = allUsers[getRandomNumber(0, allUsers.length - 1)];
  campaign.editorId = editor.id;
});

export {
  generateMockUser,
  generateMockAdmin,
  generateMockProfile,
  generateMockCompany,
  generateMockCategory,
  generateMockCampaign,
  generateMockGalleryImage,
  generateMockItems,
  allUsers,
  allAdmins,
  allProfiles,
  allCompanies,
  allCategories,
  allCampaigns,
  allGalleryImages,
  previewCampaign,
  setPreviewCampaign,
};
