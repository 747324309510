import { Dispatch } from "redux";
import { dispatchable } from "../dispatchable";
import { Action } from "../../types/types";
import { actions } from "./video.slice";

export const persistVideo = dispatchable(
  (videoUrl: string | undefined, videoId: string | undefined) => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(actions["video/pip"]({ videoUrl: videoUrl, videoId: videoId }));
    };
  }
);

export const clearVideo = dispatchable(() => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(actions["video/pip/clear"]());
  };
});
