import apiClient from "../../mock/apiClient";
import { Action, Company } from "../../types/types";
import { dispatchable } from "../dispatchable";
import { Dispatch } from "redux";
import { actions } from "./company.slice";

export const getAllCompanies = dispatchable(() => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const response = await apiClient.get<Company[]>(`/company`);
      dispatch(actions["companies/load"](response.data));
      console.log("Fetching all companies: ", response.data);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        data: null,
      };
    }
  };
});

export const getCompanyById = dispatchable((id: string | undefined) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const response = await apiClient.get<Company>(`/company/${id}`);
      dispatch(actions["companies/company"](response.data));
      console.log("Fetchign company by id: ", response.data);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        data: null,
      };
    }
  };
});
