import { UserState } from "./user.entity";
import { User } from "../../types/types";
import { PayloadAction } from "@reduxjs/toolkit";

const userReducers = {
  "users/load": (state: UserState, action: PayloadAction<User[]>) => {
    state.users = action.payload;
  },
};

export default userReducers;
