import { createSlice } from "@reduxjs/toolkit";
import { UserState } from "./user.entity";
import userReducers from "./user.reducer";

export const initialState: UserState = {
  users: [],
};

const usersSlice = createSlice({
  name: "users",
  initialState: initialState,
  reducers: userReducers,
});

export const { actions, reducer } = usersSlice;
