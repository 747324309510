import { PayloadAction } from "@reduxjs/toolkit";
import { MediaState } from "./media.entity";

const mediaReducers = {
  "media/hero": (state: MediaState, action: PayloadAction<{ heros: [] }>) => {
    state.heros = action.payload.heros;
  },
  "media/thumbnail": (
    state: MediaState,
    action: PayloadAction<{ thumbnails: [] }>
  ) => {
    state.thumbnails = action.payload.thumbnails;
  },
  "media/video/standard": (
    state: MediaState,
    action: PayloadAction<{ videos: [] }>
  ) => {
    state.standardVideos = action.payload.videos;
  },
  "media/video/youtube": (
    state: MediaState,
    action: PayloadAction<{ videos: [] }>
  ) => {
    state.youtubeVideos = action.payload.videos;
  },
  "media/video/spatial": (
    state: MediaState,
    action: PayloadAction<{ videos: [] }>
  ) => {
    state.spatialVideos = action.payload.videos;
  },
  "media/gallery": (
    state: MediaState,
    action: PayloadAction<{ galleryImages: [] }>
  ) => {
    state.galleryImages = action.payload.galleryImages;
  },
};

export default mediaReducers;
