import axios from "axios";
import {
  getRefreshToken,
  getAccessToken,
  setAccessToken,
  saveCurrentLocation,
} from "../utils/auth";
import { useAppSelector, RootState, actions } from "../store/root.store";

const API_BASE_URL = "https://playtrailers-staging.up.railway.app/api/v1";

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// This request interceptor checks if the request requires authentication (config.requiresAuth).
// If true, it adds the Authorization header with the access token to the request.
// If no access token is available, it redirects the user to the login page and cancels the request.
api.interceptors.request.use(
  (config) => {
    if (!config.requiresAuth) {
      return config;
    }

    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
      return config;
    } else {
      saveCurrentLocation();
      actions.auth.navigateTo("user-login");
      return Promise.reject(new axios.Cancel("Redirecting to login"));
    }
  },
  (error) => Promise.reject(error)
);

// // This response interceptor handles responses with a 401 status code, indicating an expired or invalid token.
// // If the original request requires authentication and hasn't been retried yet, it tries to refresh the token.
// // On successful token refresh, it updates the Authorization header with the new access token and retries the original request.
// // If token refresh fails, it logs the user out and redirects to the appropriate login page based on the user's role.
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const role = useAppSelector((state: RootState) => state.auth.role);
    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      originalRequest.requiresAuth
    ) {
      originalRequest._retry = true;
      try {
        const refreshToken = getRefreshToken();
        if (!refreshToken) throw new Error("No refresh token available");

        const response = await axios.post(`/auth/${role}/refresh`, {
          refreshToken: refreshToken,
        });

        const newAccessToken = response.data.token.access.token;

        setAccessToken(newAccessToken);

        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
        return axios(originalRequest);
      } catch (refreshError) {
        const userRole = role;

        saveCurrentLocation();
        actions.auth.logout();

        if (userRole === "admin") {
          actions.auth.navigateTo("admin-login");
        } else actions.auth.navigateTo("user-login");

        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default api;
