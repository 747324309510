import React, { useEffect } from "react";
import { actions, useAppSelector } from "../../store/root.store";
import { useNavigate } from "react-router-dom";

const NavigationListener: React.FC = () => {
  const path = useAppSelector((state) => state.auth.path);
  const navigate = useNavigate();

  useEffect(() => {
    if (path) {
      navigate(path);
      actions.auth.clearNavigate();
    }
  }, [path, navigate]);

  return null;
};

export default NavigationListener;
