import { Navigate, Outlet } from "react-router-dom";
import { RootState, useAppSelector } from "../../store/root.store";

const SudoProtectedRoutes = () => {
  const isAuthenticated = useAppSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  const isSudoUser = useAppSelector(
    (state: RootState) => state.auth.role === "sudo"
  );

  return isAuthenticated && isSudoUser ? (
    <Outlet></Outlet>
  ) : (
    <Navigate to={`/sudo-login`}></Navigate>
  );
};

export default SudoProtectedRoutes;
