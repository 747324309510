import { ProfileState } from "./profile.entity";
import { createSlice } from "@reduxjs/toolkit";
import profileReducers from "./profile.reducer";

export const initialState: ProfileState = {
  profiles: [],
  profile: null,
};

const profilesSlice = createSlice({
  name: "profiles",
  initialState: initialState,
  reducers: profileReducers,
});

export const { actions, reducer } = profilesSlice;
