import {
  Campaign,
  Category,
  CompanyProfile,
  ImmersiveMedia,
} from "../../types/types";
import {
  saveCampaignState,
  isEmptyObject,
  loadCampaignState,
} from "../../utils/sessionStorage";
import { CampaignState } from "./campaigns.entity";
import { PayloadAction } from "@reduxjs/toolkit";

const campaignReducers = {
  "campaigns/load": (
    state: CampaignState,
    action: PayloadAction<Campaign[]>
  ) => {
    state.campaigns = action.payload;
  },
  "campaigns/campaign": (
    state: CampaignState,
    action: PayloadAction<{ id: string | undefined }>
  ) => {
    const activeCampaign = state.campaigns.find(
      (campaign) => campaign.id.toString() === action.payload.id
    ) as Campaign;

    if (
      isEmptyObject(activeCampaign) ||
      activeCampaign === undefined ||
      activeCampaign === null
    ) {
      state.campaign = loadCampaignState();
    } else {
      state.campaign = activeCampaign;
      saveCampaignState(activeCampaign);
    }
  },
  "campaigns/add": (state: CampaignState) => {},
  "campaigns/fetch": (state: CampaignState, action: PayloadAction<any[]>) => {
    const structuredCampaigns: Campaign[] = action.payload.map((campaign) => {
      return {
        id: campaign.id + state.campaigns.length,
        createdAt: campaign.createdAt,
        updatedAt: campaign.updatedAt,
        approvedAt: campaign.approvedAt,
        title: campaign.title,
        subtitle: campaign.subtitle,
        heroImageUrl: campaign.heroImage.url,
        thumbnailUrl: campaign.thumbnail.url,
        experiencesEnabled: campaign.experiencesEnabled,
        immersiveMedia: {} as ImmersiveMedia,
        immersiveMediaId: -1,
        articleText: campaign.articleText,
        galleryImages: campaign.galleryImages.map((galleryImage: any) => ({
          id: galleryImage.id,
          url: galleryImage.imageId,
          imageId: galleryImage.imageId,
          alt: galleryImage.alt,
        })),
        category: {} as Category,
        categoryId: -1,
        companyCreated: campaign.companyCreated,
        companyProfiles: [] as CompanyProfile[],
        enabled: campaign.enabled,
        editor: {},
        editorId: -1,
        targetLink: campaign.targetLink,
        fallbackLink: "",
        videoLink:
          campaign.videoLinks.length > 0
            ? campaign.videoLinks[0].url
            : undefined,
      } as Campaign;
    });
    state.campaigns = state.campaigns.concat(...structuredCampaigns);
  },
  "campaigns/campaign/fetch": (
    state: CampaignState,
    action: PayloadAction<any>
  ) => {
    if (
      isEmptyObject(state.campaign) ||
      state.campaign === undefined ||
      state.campaign === null
    ) {
      state.campaign = loadCampaignState();
    } else {
      state.campaign.companyProfiles = action.payload.companyProfiles;
      saveCampaignState(state.campaign);
    }
  },
};

export default campaignReducers;
