import React, { useEffect } from "react";
import { useAppSelector } from "../../store/root.store";
import "../../components/Forms/styles/Form.css";
import { Link, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

const AccountVerification: React.FC = () => {
  const { role } = useParams<{ role: string }>();
  const loading = useAppSelector((state) => state.auth.loading);
  const error = useAppSelector((state) => state.auth.error);
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    // use query parameters to send verification api request to
    // verify user account
  }, []);

  if (loading) {
    return <div>Verifying your account</div>;
  }

  if (error) {
    return <div> An error occurred please try again</div>;
  }

  return (
    <div className="form-container">
      <div className="login-form">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <i
            className="bx bx-check-circle bx-md"
            style={{ color: "green" }}
          ></i>
          <header style={{ padding: 0, margin: 0 }}>Signup successful</header>
        </div>
        <div className="form-link">
          <span>You have successfully verified your account.</span>
          <div>
            <Link to={"/user-login"} className="login-link">
              Click here to login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountVerification;
