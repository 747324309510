import validator from "validator";

export interface UserPwdResetFormValues {
  password: string;
  confirmPassword: string;
}

export interface UserPwdResetFormValidValues {
  password: boolean;
  confirmPassword: boolean;
}

interface ValidationResult {
  isValid: Partial<Record<keyof UserPwdResetFormValues, boolean>>;
  errors: Partial<Record<keyof UserPwdResetFormValues, string>>;
}

/**
 * validatePwdResetForm - Validates the password reset form values.
 *
 * @param {UserPwdResetFormValues} values - The values of the password reset form.
 * @returns {ValidationResult} - The validation result including validity and error messages.
 *
 * This function validates the password reset form values based on the following rules:
 * 1. Password should be at least 8 characters long.
 * 2. Confirm password should match the password.
 */

export const validatePwdResetForm = (
  values: UserPwdResetFormValues
): ValidationResult => {
  const errors: Partial<Record<keyof UserPwdResetFormValues, string>> = {};
  const isValid: Partial<Record<keyof UserPwdResetFormValues, boolean>> = {};

  if (
    validator.isEmpty(values.password) ||
    !validator.isLength(values.password, { min: 8 })
  ) {
    errors.password = "Password must contain at least 8 characters";
    isValid.password = false;
  } else isValid.password = true;

  if (
    validator.isEmpty(values.confirmPassword) ||
    values.password !== values.confirmPassword
  ) {
    errors.confirmPassword = "Must match the password";
    isValid.confirmPassword = false;
  } else isValid.confirmPassword = true;

  return {
    isValid,
    errors,
  };
};
