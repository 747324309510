import React, { useEffect, useState } from "react";
import { RootState, useAppSelector } from "../../store/root.store";
import { actions } from "../../store/root.store";
import "./SuperUser.css";
import { Loader, SideNavBar } from "../../components";
import "../../styles/AllPages.css";

/**
 * SuperUser page for superusers using the content management system
 * deciding what should go on the home page, receive and approve
 * admin signup request, disabling or enabling admin accounts,
 * registering and managing companies.
 */
const SuperUser: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const sideNavBarData = [
    {
      content: "Accounts",
      subContents: [
        { name: "Inbox", url: "super-user/inbox", icon: "bx bxs-inbox bx-xs" },
        { name: "Users", url: "super-user/users", icon: "bx bxs-user bx-xs" },
        { name: "Admins", url: "super-user/admins", icon: "bx bx-user bx-xs" },
        {
          name: "Companies",
          url: "super-user/companies",
          icon: "bx bxs-business bx-xs",
        },
      ],
    },
    {
      content: "Pages",
      subContents: [
        {
          name: "Home Page",
          url: "super-user/home-page-preview",
          icon: "bx bx-home bx-xs",
        },
      ],
    },
  ];

  const campaigns = useAppSelector(
    (state: RootState) => state.campaign.campaigns
  );

  const handleGetCampaigns = async () => {
    setLoading(true);
    await actions.campaign.getAllCampaigns();
    setLoading(false);
  };

  useEffect(() => {
    handleGetCampaigns();
  }, []);

  if (loading)
    return (
      <div className="loader-container">
        <Loader width={50} height={50}></Loader>
      </div>
    );

  return (
    <div className="superuser-container">
      <SideNavBar data={sideNavBarData}></SideNavBar>
      <main className="main-content">
        <h1>Welcome, Superuser!</h1>
        <p>Manage the content and users of the application.</p>
      </main>
    </div>
  );
};

export default SuperUser;
