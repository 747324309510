export const formatDate = (date: Date | string, timeZone: string): string => {
  const validDate = new Date(date);

  if (isNaN(validDate.getTime())) {
    throw new Error("Invalid date");
  }

  const datePart = validDate.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  const timePart = validDate.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  return `${datePart.toUpperCase()} ${timePart.toUpperCase()} ${timeZone}`;
};
