import React, { useRef, useState, useEffect } from "react";
import "./UserForgotPassword.css";
import "../../components/Forms/styles/Form.css";
import { RootState, actions, useAppSelector } from "../../store/root.store";
import ReCAPTCHA from "react-google-recaptcha";
import { useNav } from "../../utils/navigation";
import { useParams } from "react-router-dom";

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string;

const UserForgotPassword: React.FC = () => {
  const { role } = useParams<{ role: string }>();
  const fieldRef = useRef<HTMLInputElement>(null);
  const [email, setEmail] = useState<string>("");
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const { goToUserPasswordResetPage } = useNav();
  const loading = useAppSelector((state: RootState) => state.auth.loading);
  const error = useAppSelector((state: RootState) => state.auth.error);

  const handleRecaptchaChange = (token: string | null) => {
    setRecaptchaToken(token);
  };

  const handleSendOtp = async (e: React.FormEvent) => {
    e.preventDefault();
    actions.auth.setEmail(email);

    const res = await actions.auth.requestResetPassword(
      email,
      recaptchaToken as string,
      role as string
    );

    if (res.success) {
      goToUserPasswordResetPage(role as string);
    }
  };

  useEffect(() => {
    actions.auth.clearAuthError();

    if (fieldRef.current) {
      fieldRef.current.focus();
    }

    return () => {
      actions.auth.clearAuthError();
    };
  }, []);

  return (
    <div className="form-container">
      <div className="login-form">
        <header>Reset your password</header>
        <span
          id="eidnote"
          className={`auth-message ${
            error !== null && !loading ? "show" : "hide"
          }`}
        >
          {error}
        </span>
        <form onSubmit={handleSendOtp}>
          <span className="instructions">
            Enter your email address associated with your account and we will
            send you a six-digit code for verification.
          </span>
          <div className="field input-field">
            <input
              type="text"
              ref={fieldRef}
              name="email"
              placeholder="Enter Email"
              autoComplete="off"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="recaptcha-container">
            <ReCAPTCHA
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={handleRecaptchaChange}
            ></ReCAPTCHA>
            <span
              id="reidnote"
              className={`instructions ${
                recaptchaToken === null ? "show" : "hide"
              }`}
            >
              Please Verify You Are Human To Continue.
            </span>
          </div>
          <div className="field button-field">
            <button type="submit" disabled={recaptchaToken === null}>
              Send Code
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserForgotPassword;
