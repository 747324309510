import React from "react";
import "./UserSignup.css";
import { UserSignupForm } from "../../components";

/**
 * Signup page for displaying user signup form.
 */
const UserSignup: React.FC = () => {
  return (
    <div className="form-container">
      <UserSignupForm></UserSignupForm>
    </div>
  );
};

export default UserSignup;
