import { createSlice } from "@reduxjs/toolkit";
import { CampaignState } from "./campaigns.entity";
import campaignsReducers from "./campaigns.reducer";
import { loadCampaignState } from "../../utils/sessionStorage";

export const initialState: CampaignState = {
  campaigns: [],
  campaign: loadCampaignState(),
};

const campaignsSlice = createSlice({
  name: "campaigns",
  initialState: initialState,
  reducers: campaignsReducers,
});

export const { actions, reducer } = campaignsSlice;
