import React, { useEffect, useState, useRef } from "react";
import { HeroImage, Video, Gallery, Content, Loader } from "../../components";
import "./Details.css";
import "../../styles/AllPages.css";
import { useParams } from "react-router-dom";
import { RootState, actions, useAppSelector } from "../../store/root.store";
import { previewCampaign } from "../../mock/mockData";
import {
  setDefaultTwitterCardDescription,
  setDefaultTwitterCardImage,
  setDefaultTwitterCardTitle,
  setTwitterCardDescription,
  setTwitterCardImage,
  setTwitterCardTitle,
} from "../../utils/metaTags";
import SpatialVideo from "../../components/Video/SpatialVideo";

/**
 * Details page for displaying details of a specific campaign.
 */

const Details: React.FC = () => {
  const { id } = useParams<{ id: string | undefined }>();
  const [loading, setLoading] = useState<boolean>(true);

  // Fetches the current campaign from the Redux store
  const campaign = useAppSelector(
    (state: RootState) => state.campaign.campaign
  );

  console.log("Details page campaign", campaign);

  // Function to fetch campaign details by ID
  const handleGetCampaignById = async (id: string | undefined) => {
    setLoading(true);
    await actions.campaign.getCampaignById(id);
    if (parseInt(id as string, 10) > 8) {
      await actions.campaign.fetchCampaignById(parseInt(id as string, 10) - 8); // reverting offsetted id to get actual id, id offsetted with hardcoded campaigns length (8) to prevent duplicate id
    }
    setLoading(false);
  };

  // Fetch campaign details on component mount or when ID changes
  useEffect(() => {
    handleGetCampaignById(id);
  }, [id]);

  useEffect(() => {
    if (campaign) {
      document.title = `XRTrailers - ${campaign.title}`;

      setTwitterCardTitle(campaign.title);
      setTwitterCardDescription(campaign.subtitle);
      setTwitterCardImage(campaign.thumbnailUrl);
    }

    return () => {
      document.title = "XRTrailers";

      setDefaultTwitterCardTitle();
      setDefaultTwitterCardDescription();
      setDefaultTwitterCardImage();
    };
  }, [campaign]);

  if (loading) {
    return (
      <div className="loader-container">
        <Loader width={50} height={50}></Loader>
      </div>
    );
  }

  return (
    <>
      {campaign ? (
        <>
          <>
            <HeroImage campaign={campaign}></HeroImage>
          </>
          <>
            <Content campaign={campaign}></Content>
          </>
          <>
            {campaign.galleryImages && (
              <Gallery images={campaign.galleryImages}></Gallery>
            )}
          </>
        </>
      ) : (
        <div className="loader-container">
          <Loader width={50} height={50}></Loader>
        </div>
      )}
    </>
  );
};

export default Details;
