import { configureStore } from "@reduxjs/toolkit";
import { campaignActions, campaignsReducer } from "./campaigns";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import logger from "redux-logger";
import { userActions, userReducer } from "./user";
import { categoryActions, categoryReducer } from "./categories";
import { adminActions, adminReducer } from "./admin";
import { companyActions, companyReducer } from "./company";
import { profileActions, profileReducer } from "./profile";
import { galleryImageActions, galleryImageReducer } from "./galleryImages";
import { authActions, authReducer } from "./auth";
import { videoActions, videoReducer } from "./video";
import { mediaActions, mediaReducer } from "./media";

export const actions = {
  campaign: campaignActions,
  user: userActions,
  category: categoryActions,
  admin: adminActions,
  company: companyActions,
  auth: authActions,
  profile: profileActions,
  galleryImage: galleryImageActions,
  video: videoActions,
  media: mediaActions,
};

const reducers = {
  campaign: campaignsReducer,
  user: userReducer,
  category: categoryReducer,
  admin: adminReducer,
  company: companyReducer,
  auth: authReducer,
  profile: profileReducer,
  galleryImage: galleryImageReducer,
  video: videoReducer,
  media: mediaReducer,
};

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  enhancers: (getDefaultEnhancers) => getDefaultEnhancers(),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
