import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { SearchBar, SearchResult } from "../../components/Search";
import "./Search.css";
import { ThumbnailCard, Loader } from "../../components";
import { useNav } from "../../utils/navigation";
import { useAppSelector, actions, RootState } from "../../store/root.store";

const Search: React.FC = () => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const [searchTerm, setSearchTerm] = useState<string>(
    useQuery().get("searchTerm") || ""
  );
  const [results, setResults] = useState<any[]>([]);
  const { goToDetailsPage } = useNav();
  const [loading, setLoading] = useState<boolean>(false);

  // Fetches campaigns, categories, and selected category from the Redux store
  const campaigns = useAppSelector(
    (state: RootState) => state.campaign.campaigns
  );

  const handleGetCampaigns = async () => {
    setLoading(true);
    await actions.campaign.getAllCampaigns();
    await actions.campaign.fetchAllCampaigns({ page: 1 });
    setLoading(false);
  };

  useEffect(() => {
    if (searchTerm) {
      // call search thunk with search term, filters, and sort by
      const filteredResults = campaigns.filter((campaign) =>
        campaign.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setResults(filteredResults);
      console.log("search api endpoint called");
    } else {
      setResults(campaigns);
    }
  }, [searchTerm, campaigns]);

  return (
    <div className="search-container">
      <div className="search-input-container">
        <SearchBar searchTerm={searchTerm} onChange={setSearchTerm}></SearchBar>
        <i className="bx bx-filter-alt bx-sm filter-icon"></i>
        <i className="bx bx-sort bx-sm sort-icon"></i>
      </div>
      {loading && (
        <div className="loader-container">
          <Loader width={50} height={50}></Loader>
        </div>
      )}
      <SearchResult
        itemsPerPage={12}
        results={results}
        renderResult={(result: any) => (
          <ThumbnailCard
            id={result.id}
            thumbnailUrl={result.thumbnailUrl}
            title={result.title}
            subtitle={result.subtitle}
            onThumbnailCardClick={goToDetailsPage}
            key={result.id}
          ></ThumbnailCard>
        )}
      ></SearchResult>
    </div>
  );
};

export default Search;
