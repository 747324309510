import React, { useState, useRef, useEffect } from "react";
import "./styles/Form.css";
import "boxicons/css/boxicons.min.css";
import { Link } from "react-router-dom";
import { useNav } from "../../utils/navigation";
import { actions, useAppSelector, RootState } from "../../store/root.store";
import { useDeviceSelectors } from "react-device-detect";

/**
 * Login form component for user authentication.
 *
 * @component
 * @example
 * return (
 *   <UserLoginForm  />
 * )
 *
 */

const UserLoginForm: React.FC = () => {
  const nameRef = useRef<HTMLInputElement>(null);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [hidePassword, setHidePassword] = useState<boolean>(true);
  const error = useAppSelector((state: RootState) => state.auth.error);
  const loading = useAppSelector((state: RootState) => state.auth.loading);
  const { goToHomePage, redirectToAfterLoginPage } = useNav();
  const [selectors, data] = useDeviceSelectors(window.navigator.userAgent);

  const handleEyeIconClick = () => {
    setHidePassword(!hidePassword);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleLogin();
  };

  const handleLogin = async () => {
    const res = await actions.auth.standardLogin(email, password, data.ua);

    if (res.success && !loading) {
      redirectToAfterLoginPage();
      goToHomePage();
    }
  };

  useEffect(() => {
    actions.auth.clearAuthError();

    if (nameRef.current) {
      nameRef.current.focus();
    }

    return () => {
      actions.auth.clearAuthError();
    };
  }, []);

  return (
    <div className="login-form">
      <header>Login</header>
      <span
        id="eidnote"
        className={`auth-message ${
          error !== null && !loading ? "show" : "hide"
        }`}
      >
        {error}
      </span>
      <form onSubmit={handleSubmit}>
        <div className="field input-field">
          <input
            type="text"
            ref={nameRef}
            name="email"
            placeholder="Enter Email"
            autoComplete="off"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="field input-field">
          <input
            type={hidePassword ? "password" : "text"}
            name="password"
            placeholder="Enter Password"
            autoComplete="off"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <i
            className={`bx eye-icon default-eye-icon ${
              hidePassword ? `bx-hide` : `bx-show`
            }`}
            onClick={handleEyeIconClick}
          ></i>
        </div>
        <div className="form-link">
          <span>
            <Link to={`/user-forgot-password/standard`}>Forgot password?</Link>
          </span>
        </div>
        <div className="field button-field">
          <button type="submit">Login</button>
        </div>
        <div className="form-link">
          <span>
            Don't have an account? <Link to={`/user-signup`}>Sign Up</Link>
          </span>
        </div>
      </form>
    </div>
  );
};

export default UserLoginForm;
