export const setTwitterCardTitle = (title: string) => {
  document
    .querySelector('meta[name="twitter:title"]')
    ?.setAttribute("content", title);
};

export const setTwitterCardDescription = (desc: string) => {
  document
    .querySelector('meta[name="twitter:description"]')
    ?.setAttribute("content", desc);
};

export const setTwitterCardImage = (imageUrl: string) => {
  document
    .querySelector('meta[name="twitter:image"]')
    ?.setAttribute("content", imageUrl);
};

export const setDefaultTwitterCardTitle = () => {
  const defaultTitle = "defaultTitle";
  document
    .querySelector('meta[name="twitter:title"]')
    ?.setAttribute("content", defaultTitle);
};

export const setDefaultTwitterCardDescription = () => {
  const defaultDescription = "defaultDescription";
  document
    .querySelector('meta[name="twitter:description"]')
    ?.setAttribute("content", defaultDescription);
};

export const setDefaultTwitterCardImage = () => {
  const defaultImage = "#";
  document
    .querySelector('meta[name="twitter:image"]')
    ?.setAttribute("content", defaultImage);
};
