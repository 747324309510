import validator from "validator";

export interface UserSignupFormValues {
  firstName: string;
  lastName: string;
  email: string;
  confirmEmail: string;
  username: string;
  password: string;
  confirmPassword: string;
}

export interface UserSignupFormValidValues {
  firstName: boolean;
  lastName: boolean;
  email: boolean;
  confirmEmail: boolean;
  username: boolean;
  password: boolean;
  confirmPassword: boolean;
}

interface ValidationResult {
  isValid: Partial<Record<keyof UserSignupFormValues, boolean>>;
  errors: Partial<Record<keyof UserSignupFormValues, string>>;
}

/**
 * validateSignupForm - Validates the signup form values.
 *
 * @param {SignupFormValues} values - The values of the signup form.
 * @returns {ValidationResult} - The validation result including validity and error messages.
 *
 * This function validates the signup form values based on the following rules:
 * 1. First name and last name should not be empty.
 * 2. Email should be in a valid format.
 * 3. Confirm email should match the email.
 * 4. Username should be at least 8 characters long.
 * 5. Password should be at least 8 characters long.
 * 6. Confirm password should match the password.
 */

export const validateSignupForm = (
  values: UserSignupFormValues,
): ValidationResult => {
  const errors: Partial<Record<keyof UserSignupFormValues, string>> = {};
  const isValid: Partial<Record<keyof UserSignupFormValues, boolean>> = {};

  if (validator.isEmpty(values.firstName)) {
    errors.firstName = "First name is required";
    isValid.firstName = false;
  } else isValid.firstName = true;

  if (validator.isEmpty(values.lastName)) {
    errors.lastName = "Last name is required";
    isValid.lastName = false;
  } else isValid.lastName = true;

  if (!validator.isEmail(values.email)) {
    errors.email = "Invalid email format";
    isValid.email = false;
  } else isValid.email = true;

  if (
    validator.isEmpty(values.confirmEmail) ||
    values.email !== values.confirmEmail
  ) {
    errors.confirmEmail = "Must match the email";
    isValid.confirmEmail = false;
  } else isValid.confirmEmail = true;

  if (
    validator.isEmpty(values.username) ||
    !validator.isLength(values.username, { min: 8 })
  ) {
    errors.username = "Username must contain at least 8 characters";
    isValid.username = false;
  } else isValid.username = true;

  if (
    validator.isEmpty(values.password) ||
    !validator.isLength(values.password, { min: 8 })
  ) {
    errors.password = "Password must contain at least 8 characters";
    isValid.password = false;
  } else isValid.password = true;

  if (
    validator.isEmpty(values.confirmPassword) ||
    values.password !== values.confirmPassword
  ) {
    errors.confirmPassword = "Must match the password";
    isValid.confirmPassword = false;
  } else isValid.confirmPassword = true;

  return {
    isValid,
    errors,
  };
};
