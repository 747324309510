import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getRefreshToken,
  getUserRole,
  handleLogin,
  handleLogout,
} from "../../utils/auth";

/**
 * This component is responsible for persisting authentication sessions across browser tabs.
 * It listens for changes to the 'accessToken' in the local storage and updates the application state accordingly.
 * When the 'accessToken' changes, it either logs the user in or out based on the new value of the token.
 *
 * Usage:
 * - Include this component at the root level of your application (e.g., in App.js or App.tsx).
 * - It uses the `useDispatch` hook from react-redux to dispatch authentication actions.
 * - When the 'accessToken' is set to `null` or removed, the user is logged out.
 * - When the 'accessToken' is updated with a new value, the user is logged in with the new token.
 */

const AuthListener = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "accessToken") {
        const newAccessToken = event.newValue;
        const refreshToken = getRefreshToken();
        const role = getUserRole();

        if (newAccessToken === null) {
          handleLogout(dispatch);
        } else {
          handleLogin(
            dispatch,
            newAccessToken,
            refreshToken as string,
            role as string
          );
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [dispatch]);

  return null;
};

export default AuthListener;
