import React, { useState } from "react";
import "./More.css";
import { useLocation, useParams } from "react-router-dom";
import { useNav } from "../../utils/navigation";
import { ThumbnailCard, Loader } from "../../components";
import { SearchResult } from "../../components/Search";
import { actions, useAppSelector, RootState } from "../../store/root.store";

const More: React.FC = () => {
  const { category, subCategory } = useParams<{
    category: string;
    subCategory: string;
  }>();
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const { goToDetailsPage } = useNav();
  const itemsPerPage = 12;

  // Fetches campaigns, categories, and selected category from the Redux store
  const campaigns = useAppSelector(
    (state: RootState) => state.campaign.campaigns
  );

  const getQueryParams = () => {
    return new URLSearchParams(location.search);
  };

  const queryParams = getQueryParams();

  const handleGetMoreCampaigns = async () => {
    setLoading(true);
    // get by /category/sub-category/query-params=(attributes)
    setLoading(false);
  };

  const handleGetCampaigns = async () => {
    setLoading(true);
    await actions.campaign.getAllCampaigns();
    await actions.campaign.fetchAllCampaigns({ page: 1 });
    setLoading(false);
  };

  return (
    <div className="more-container">
      <h1>{category}</h1>
      <h2>{subCategory ?? "Review"}</h2>
      {loading && (
        <div className="loader-container">
          <Loader width={50} height={50}></Loader>
        </div>
      )}
      <SearchResult
        itemsPerPage={itemsPerPage}
        results={campaigns}
        renderResult={(result: any) => (
          <ThumbnailCard
            id={result.id}
            thumbnailUrl={result.thumbnailUrl}
            title={result.title}
            subtitle={result.subtitle}
            onThumbnailCardClick={goToDetailsPage}
            key={result.id}
          ></ThumbnailCard>
        )}
      ></SearchResult>
    </div>
  );
};

export default More;
