import React, { useEffect, useState } from "react";
import "./RightSideNavBar.css";
import { Link, useLocation } from "react-router-dom";
import { actions, useAppSelector, RootState } from "../../store/root.store";
import { useNav } from "../../utils/navigation";

const RightSideNavBar: React.FC = () => {
  const [isNavOpen, setIsNavOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const location = useLocation();

  const { goToSearchPage, goToUserLoginPage, goToUserSignupPage } = useNav();

  const isAuthenticated = useAppSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  const categories = useAppSelector(
    (state: RootState) => state.category.categories
  );
  const selectedCategory = useAppSelector(
    (state: RootState) => state.category.selectedCategory
  );

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleLogoutClick = () => {
    actions.auth.logout();
  };

  const handleSearchClick = () => {
    goToSearchPage("");
  };

  const handleChangeCategory = async (id: number) => {
    await actions.category.getCategoryById(id);
  };

  useEffect(() => {
    setIsNavOpen(false);
  }, [location.pathname, selectedCategory]);

  return (
    <>
      <nav className={`right-side-nav ${isNavOpen ? "open" : ""}`}>
        <button className="right-nav-close" onClick={toggleNav}>
          X
        </button>
        <Link to="/user-profile" className="nav-user-profile">
          <img
            src="https://t4.ftcdn.net/jpg/00/64/67/27/360_F_64672736_U5kpdGs9keUll8CRQ3p3YaEv2M6qkVY5.jpg"
            alt="default profile"
            width="100%"
          />
        </Link>
        <div className="right-nav-content">
          <>
            <ul>
              <header>Account</header>
              {isAuthenticated ? (
                <>
                  <li onClick={handleLogoutClick}>
                    <i className="bx bx-log-out"></i> <span>Logout</span>
                  </li>
                  <li onClick={handleSearchClick}>
                    <i className="bx bx-search"></i> <span>Search</span>
                  </li>
                </>
              ) : (
                <>
                  <li onClick={goToUserLoginPage}>
                    <i className="bx bx-log-in"></i> <span>Login</span>
                  </li>
                  <li onClick={goToUserSignupPage}>
                    <i className="bx bx-user-plus"></i> <span>Sign Up</span>
                  </li>
                  <li onClick={handleSearchClick}>
                    <i className="bx bx-search"></i> <span>Search</span>
                  </li>
                </>
              )}
            </ul>
          </>
          <hr className="divider" />
          <>
            <ul>
              <header>Categories</header>
              {categories.map((category) => (
                <li key={category.id}>
                  <span
                    className={
                      category.id === selectedCategory.id
                        ? "selected-category"
                        : ""
                    }
                    onClick={() => handleChangeCategory(category.id)}
                  >
                    {category.name}
                  </span>
                </li>
              ))}
            </ul>
          </>
        </div>
      </nav>
      <button className="right-nav-toggle" onClick={toggleNav}>
        ≡
      </button>
    </>
  );
};

export default RightSideNavBar;
