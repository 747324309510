import React from "react";
import "../../components/Forms/styles/Form.css";
import { useAppSelector } from "../../store/root.store";
import { useParams } from "react-router-dom";

const SignupConfirmation: React.FC = () => {
  const { role } = useParams<{ role: string }>();
  const email = useAppSelector((state) => state.auth.email);
  return (
    <div className="form-container">
      <div className="login-form">
        <header>Verify your account</header>
        <div className="form-link">
          <>
            <span>
              We sent a verification email to{" "}
              <span style={{ color: "#007bff" }}>{email}</span>
            </span>
          </>
          <>
            {" "}
            <span>Please verify your email.</span>
          </>
        </div>
      </div>
    </div>
  );
};

export default SignupConfirmation;
