import { Dispatch } from "redux";
import { dispatchable } from "../dispatchable";
import { Action, User } from "../../types/types";
import apiClient from "../../mock/apiClient";
import { actions } from "./user.slice";

export const getAllUsers = dispatchable(() => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const response = await apiClient.get<User[]>(`/user`);
      dispatch(actions["users/load"](response.data));
      console.log("Fetching all users: ", response.data);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        data: null,
      };
    }
  };
});
