import React from "react";
import "./UserLogin.css";
import { UserLoginForm } from "../../components";

/**
 * Login page for displaying user login form.
 */
const UserLogin: React.FC = () => {
  return (
    <div className="form-container">
      <UserLoginForm></UserLoginForm>
    </div>
  );
};

export default UserLogin;
