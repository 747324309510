import React, { useState } from "react";
import "./ExperienceSelection.css";
import threeSixtyHover from "../../assets/experience-logo/xrtrailers_experience_360_txt_icon.png";
import threeSixtyDisable from "../../assets/experience-logo/xrtrailers_experience_360_txt_icon_v2.png";
import threeSixtyDefault from "../../assets/experience-logo/xrtrailers_experience_360_txt_icon_v3.png";
import arHover from "../../assets/experience-logo/xrtrailers_experience_ar_txt_icon.png";
import arDisable from "../../assets/experience-logo/xrtrailers_experience_ar_txt_icon_v2.png";
import arDefault from "../../assets/experience-logo/xrtrailers_experience_ar_txt_icon_v3.png";
import vrHover from "../../assets/experience-logo/xrtrailers_experience_vr_txt_icon.png";
import vrDisable from "../../assets/experience-logo/xrtrailers_experience_vr_txt_icon_v2.png";
import vrDefault from "../../assets/experience-logo/xrtrailers_experience_vr_txt_icon_v3.png";
import experience from "../../assets/experience-logo/xrtrailers_experience_txt_icon.png";
import { useNav } from "../../utils/navigation";

/**
 * Component for selecting different types of experiences (360, AR, VR).
 *
 * @component
 * @example
 * return (
 *   <ExperienceSelection />
 * )
 */

interface ExperienceSelectionProp {
  threeSixtyUrl?: string;
  arUrl?: string;
  vrUrl?: string;
  threeSixtyDisabled?: boolean;
  arDisabled?: boolean;
  vrDisabled?: boolean;
}

const ExperienceSelection: React.FC<ExperienceSelectionProp> = ({
  threeSixtyDisabled = false,
  arDisabled = false,
  vrDisabled = false,
  threeSixtyUrl,
  arUrl,
  vrUrl,
}) => {
  const [threeSixty, setThreeSixty] = useState<string>(
    threeSixtyDisabled ? threeSixtyDisable : threeSixtyDefault
  );
  const [ar, setAR] = useState<string>(arDisabled ? arDisable : arDefault);
  const [vr, setVR] = useState<string>(vrDisabled ? vrDisable : vrDefault);
  const { openNewTab } = useNav();

  const handleMouseEnter = (exp: string) => {
    switch (exp) {
      case "360":
        if (!threeSixtyDisabled) setThreeSixty(threeSixtyHover);
        break;
      case "AR":
        if (!arDisabled) setAR(arHover);
        break;
      case "VR":
        if (!vrDisabled) setVR(vrHover);
        break;
      default:
        break;
    }
  };

  const handleMouseLeave = (exp: string) => {
    switch (exp) {
      case "360":
        setThreeSixty(
          threeSixtyDisabled ? threeSixtyDisable : threeSixtyDefault
        );
        break;
      case "AR":
        setAR(arDisabled ? arDisable : arDefault);
        break;
      case "VR":
        setVR(vrDisabled ? vrDisable : vrDefault);
        break;
      default:
        break;
    }
  };
  const handleThreeSixtyClick = () => {
    if (!threeSixtyDisabled) {
      openNewTab(threeSixtyUrl as string);
    }
  };

  const handleARClick = () => {
    if (!arDisabled) {
      openNewTab(arUrl as string);
    }
  };

  const handleVRClick = () => {
    if (!vrDisabled) {
      openNewTab(vrUrl as string);
    }
  };

  return (
    <div className="experience-selection-container">
      <span>
        <img src={experience} alt="experience" />
      </span>
      <span
        onClick={handleThreeSixtyClick}
        onMouseEnter={() => handleMouseEnter("360")}
        onMouseLeave={() => handleMouseLeave("360")}
      >
        <img src={threeSixty} alt="three sixty experience" />
      </span>
      <span
        onClick={handleARClick}
        onMouseEnter={() => handleMouseEnter("AR")}
        onMouseLeave={() => handleMouseLeave("AR")}
      >
        <img src={ar} alt="ar experience" />
      </span>
      <span
        onClick={handleVRClick}
        onMouseEnter={() => handleMouseEnter("VR")}
        onMouseLeave={() => handleMouseLeave("VR")}
      >
        <img src={vr} alt="vr experience" />
      </span>
    </div>
  );
};

export default ExperienceSelection;
