import React, { useState } from "react";
import "./SideNavBar.css";
import { actions } from "../../store/root.store";

interface SubContent {
  name: string;
  url: string;
  icon: string;
}
interface SideNavBarContent {
  content: string;
  subContents: SubContent[];
}
interface SideNavBarProps {
  data: SideNavBarContent[];
}

/**
 * SideNavBar component for rendering a dynamic side navigation bar.
 *
 * @param {SideNavBarProps} props - The properties for the SideNavBar component.
 * @returns {JSX.Element} The rendered SideNavBar component.
 *
 * @example
 * // Example usage of SideNavBar
 * const sideNavBarData = [
 *   {
 *     content: "Accounts",
 *     subContents: [
 *       { name: "Inbox", url: "/inbox", icon: "bx bxs-inbox bx-xs" },
 *       { name: "Users", url: "/users", icon: "bx bxs-user bx-xs" },
 *       { name: "Admins", url: "/admins", icon: "bx bx-user bx-xs" },
 *       { name: "Companies", url: "/companies", icon: "bx bxs-business bx-xs" },
 *     ],
 *   },
 *   {
 *     content: "Pages",
 *     subContents: [
 *       { name: "Home Page", url: "/home", icon: "bx bx-home bx-xs" },
 *     ],
 *   },
 *   // Add more sections as needed
 * ];
 *
 * <SideNavBar data={sideNavBarData} />
 */
const SideNavBar: React.FC<SideNavBarProps> = ({ data }) => {
  const [isNavOpen, setIsNavOpen] = useState<boolean>(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <>
      <nav className={`side-nav ${isNavOpen ? "open" : ""}`}>
        <button className="nav-close" onClick={toggleNav}>
          X
        </button>
        <div className="nav-content">
          {data.map((section, index) => (
            <React.Fragment key={index}>
              <ul>
                <header>{section.content}</header>
                {section.subContents.map((subContent, subIndex) => (
                  <li
                    key={subIndex}
                    onClick={() => {
                      actions.auth.navigateTo(subContent.url);
                    }}
                  >
                    <i className={subContent.icon}></i>
                    {subContent.name}
                  </li>
                ))}
              </ul>
              {index < data.length - 1 && <hr className="divider" />}
            </React.Fragment>
          ))}
        </div>
      </nav>
      <button className="nav-toggle" onClick={toggleNav}>
        ≡
      </button>
    </>
  );
};

export default SideNavBar;
