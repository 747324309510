import React from "react";
import "./NavBar.css";
import { Category, NavBarProps } from "../../types/types";
import { actions } from "../../store/root.store";

/**
 * Navigation bar component for displaying categories.
 *
 * @component
 * @example
 * return (
 *   <NavBar categories={categories} selectedCategory={selectedCategory} />
 * )
 *
 * @param {NavBarProps} props - Component properties.
 * @param {Category[]} props.categories - Array of category objects.
 * @param {Category} props.selectedCategory - Selected category object.
 * @param {function} props.handleChangeCategory - function to handle category selection.
 */

const NavBar: React.FC<NavBarProps> = ({
  categories,
  selectedCategory,
  handleChangeCategory,
}) => {
  return (
    <div className="component-container" style={{ paddingTop: 0 }}>
      <div className="nav-bar">
        <nav>
          <ul>
            {categories.map((category) => (
              <li key={category.id}>
                <span
                  className={
                    category.id === selectedCategory.id
                      ? "selected-category"
                      : ""
                  }
                  onClick={() => handleChangeCategory(category.id)}
                >
                  {category.name}
                </span>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default NavBar;
