import React, { useState, useRef, useEffect } from "react";
import {
  AdminSignupFormValues,
  validateAdminSignupForm,
  AdminSignupFormValidValues,
} from "./AdminFormValidator";
import { useParams } from "react-router-dom";
import "../../components/Forms/styles/Form.css";
import "./AdminSignup.css";
import "boxicons/css/boxicons.min.css";
import { RootState, actions, useAppSelector } from "../../store/root.store";
import ReCAPTCHA from "react-google-recaptcha";
import { useNav } from "../../utils/navigation";

/**
 * Admin signup page.
 *
 * @page
 * @example
 * (
 *   <AdminSignup />
 * )
 *
 */

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string;

const AdminSignup: React.FC = () => {
  const { id } = useParams<{ id: string | undefined }>();
  const company = useAppSelector((state: RootState) => state.company.company);
  const nameRef = useRef<HTMLInputElement>(null);
  const [values, setValues] = useState<AdminSignupFormValues>({
    email: "",
    confirmEmail: "",
    username: "",
    password: "",
    confirmPassword: "",
  });
  const [hidePassword, setHidePassword] = useState<boolean>(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState<boolean>(true);
  const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false);
  const [focus, setFocus] = useState<
    Partial<Record<keyof AdminSignupFormValues, boolean>>
  >({});
  const [errors, setErrors] = useState<Partial<AdminSignupFormValues>>({});
  const [isValid, setIsValid] = useState<Partial<AdminSignupFormValidValues>>(
    {}
  );
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const { goToSignupConfirmationPage } = useNav();

  const handleGetCompanyById = async (id: string | undefined) => {
    setLoading(true);
    await actions.company.getCompanyById(id);
    setLoading(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleRecaptchaChange = (token: string | null) => {
    setRecaptchaToken(token);
    console.log(token);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const validationResult = validateAdminSignupForm(values);

    let valid =
      Object.keys(validationResult.errors).length === 0 &&
      agreeToTerms &&
      recaptchaToken !== null;

    if (valid) {
      handleSignup();
    } else {
      setShowErrors(true);
      setErrors(validationResult.errors);
      console.log("Invalid form");
    }
  };

  const handleEyeIconClick = () => {
    setHidePassword(!hidePassword);
  };

  const handleConfirmEyeIconClick = () => {
    setHideConfirmPassword(!hideConfirmPassword);
  };

  const handleSignup = async () => {
    const res = await actions.auth.adminSignup(
      values.email,
      values.password,
      values.username,
      true,
      `${company?.id}`,
      company?.name as string,
      ""
    );

    if (res.success && !loading) {
      goToSignupConfirmationPage("admin");
    }
  };

  useEffect(() => {
    handleGetCompanyById(id);
  }, [id]);

  useEffect(() => {
    if (nameRef.current) {
      nameRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const validationResult = validateAdminSignupForm(values);
    setErrors(validationResult.errors);
    setIsValid(validationResult.isValid);
  }, [values]);

  useEffect(() => {
    if (Object.values(focus).some((val) => val)) {
      setShowErrors(false);
    }
  }, [focus]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="form-container">
      <div className="login-form">
        <header>Admin Sign Up</header>
        <form onSubmit={handleSubmit}>
          <div className="field input-field">
            <input
              type="text"
              required
              disabled
              value={company?.name}
              placeholder="Company Name"
            />
          </div>
          <div className="field input-field">
            <input
              type="text"
              required
              aria-invalid={errors.email ? "false" : "true"}
              aria-describedby="eidnote"
              autoComplete="off"
              autoCorrect="off"
              name="email"
              placeholder="Email"
              onChange={handleChange}
              value={values.email}
              onFocus={() => setFocus((prev) => ({ ...prev, email: true }))}
              onBlur={() => setFocus((prev) => ({ ...prev, email: false }))}
            />
            <span
              id="eidnote"
              className={`instructions ${
                (focus.email && values.email && !isValid.email) ||
                (values.email && showErrors)
                  ? "show"
                  : "hide"
              }`}
            >
              {errors.email}
            </span>
            {values.email && !errors.email && (
              <i className="bx bx-check-circle valid-icon"></i>
            )}
            {values.email && errors.email && (
              <i className="bx bx-x-circle invalid-icon"></i>
            )}
          </div>
          <div className="field input-field">
            <input
              type="text"
              required
              aria-invalid={errors.confirmEmail ? "false" : "true"}
              aria-describedby="ceidnote"
              autoComplete="off"
              autoCorrect="off"
              name="confirmEmail"
              placeholder="Confirm Email"
              onChange={handleChange}
              value={values.confirmEmail}
              onFocus={() =>
                setFocus((prev) => ({ ...prev, confirmEmail: true }))
              }
              onBlur={() =>
                setFocus((prev) => ({ ...prev, confirmEmail: false }))
              }
            />
            <span
              id="ceidnote"
              className={`instructions ${
                (focus.confirmEmail &&
                  values.confirmEmail &&
                  !isValid.confirmEmail) ||
                (values.confirmEmail && showErrors)
                  ? "show"
                  : "hide"
              }`}
            >
              {errors.confirmEmail}
            </span>
            {values.confirmEmail && !errors.confirmEmail && (
              <i className="bx bx-check-circle valid-icon"></i>
            )}
            {values.confirmEmail && errors.confirmEmail && (
              <i className="bx bx-x-circle invalid-icon"></i>
            )}
          </div>
          <div className="field input-field">
            <input
              type="text"
              required
              aria-invalid={errors.username ? "false" : "true"}
              aria-describedby="uidnote"
              autoComplete="off"
              autoCorrect="off"
              name="username"
              placeholder="Create Username"
              onChange={handleChange}
              value={values.username}
              onFocus={() => setFocus((prev) => ({ ...prev, username: true }))}
              onBlur={() => setFocus((prev) => ({ ...prev, username: false }))}
            />
            <span
              id="uidnote"
              className={`instructions ${
                (focus.username && values.username && !isValid.username) ||
                (values.username && showErrors)
                  ? "show"
                  : "hide"
              }`}
            >
              {errors.username}
            </span>
            {values.username && isValid.username && (
              <i className="bx bx-check-circle valid-icon"></i>
            )}
            {values.username && !isValid.username && (
              <i className="bx bx-x-circle invalid-icon"></i>
            )}
          </div>
          <div className="field input-field">
            <input
              type={hidePassword ? "password" : "text"}
              required
              aria-invalid={errors.password ? "false" : "true"}
              aria-describedby="pwdidnote"
              autoComplete="off"
              autoCorrect="off"
              name="password"
              placeholder="Create Password"
              onChange={handleChange}
              value={values.password}
              onFocus={() => setFocus((prev) => ({ ...prev, password: true }))}
              onBlur={() => setFocus((prev) => ({ ...prev, password: false }))}
            />
            <i
              className={`bx eye-icon ${hidePassword ? `bx-hide` : `bx-show`} ${
                values.password ? `move-eye-icon` : `default-eye-icon`
              }`}
              onClick={handleEyeIconClick}
            ></i>
            <span
              id="pwdidnote"
              className={`instructions ${
                (focus.password && values.password && !isValid.password) ||
                (values.password && showErrors)
                  ? "show"
                  : "hide"
              }`}
            >
              {errors.password}
            </span>
            {values.password && !errors.password && (
              <i className="bx bx-check-circle valid-icon"></i>
            )}
            {values.password && errors.password && (
              <i className="bx bx-x-circle invalid-icon"></i>
            )}
          </div>
          <div className="field input-field">
            <input
              type={hideConfirmPassword ? "password" : "text"}
              required
              aria-invalid={errors.confirmPassword ? "false" : "true"}
              aria-describedby="cpwdidnote"
              autoComplete="off"
              autoCorrect="off"
              name="confirmPassword"
              placeholder="Confirm Password"
              onChange={handleChange}
              value={values.confirmPassword}
              onFocus={() =>
                setFocus((prev) => ({ ...prev, confirmPassword: true }))
              }
              onBlur={() =>
                setFocus((prev) => ({ ...prev, confirmPassword: false }))
              }
            />
            <i
              className={`bx eye-icon ${
                hideConfirmPassword ? `bx-hide` : `bx-show`
              } ${
                values.confirmPassword ? `move-eye-icon` : `default-eye-icon`
              }`}
              onClick={handleConfirmEyeIconClick}
            ></i>
            <span
              id="cpwdidnote"
              className={`instructions ${
                (focus.confirmPassword &&
                  values.confirmPassword &&
                  !isValid.confirmPassword) ||
                (values.confirmPassword && showErrors)
                  ? "show"
                  : "hide"
              }`}
            >
              {errors.confirmPassword}
            </span>
            {values.confirmPassword && !errors.confirmPassword && (
              <i className="bx bx-check-circle valid-icon"></i>
            )}
            {values.confirmPassword && errors.confirmPassword && (
              <i className="bx bx-x-circle invalid-icon"></i>
            )}
          </div>
          <div className="checkbox-field">
            <input
              type="checkbox"
              name="agreeToTerms"
              checked={agreeToTerms}
              onChange={() => setAgreeToTerms(!agreeToTerms)}
              required
            />
            <div className="terms-link">
              <span>
                Agree to <a href="#">Terms & Conditions</a>
              </span>
            </div>
          </div>
          <div className="recaptcha-container">
            <ReCAPTCHA
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={handleRecaptchaChange}
            ></ReCAPTCHA>
            <span
              id="reidnote"
              className={`instructions ${
                recaptchaToken === null ? "show" : "hide"
              }`}
            >
              Please Verify You Are Human To Continue.
            </span>
          </div>
          <div className="field button-field">
            <button type="submit">Sign Up</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminSignup;
