import { createSlice } from "@reduxjs/toolkit";
import { VideoState } from "./video.entity";
import videoReducers from "./video.reducer";

export const initialState: VideoState = {
  videoUrl: undefined,
  videoId: undefined,
  isActive: false,
};

const videoSlice = createSlice({
  name: "video",
  initialState: initialState,
  reducers: videoReducers,
});

export const { actions, reducer } = videoSlice;
