import React from "react";
import { HeroImageProps } from "../../types/types";
import { ExperienceSelection } from "../../components";
import "../../styles/AllComponents.css";
import "./HeroImage.css";

/**
 * Hero image component displaying the campaign image and selection options.
 *
 * @component
 * @example
 * return (
 *   <HeroImage campaign={campaign} />
 * )
 *
 * @param {HeroImageProps} props - Component properties.
 * @param {Campaign} props.campaign - Campaign object.
 */

const HeroImage: React.FC<HeroImageProps> = ({ campaign }) => {
  console.log(campaign.immersiveMedia)
  return (
    <div className="component-container" style={{ paddingTop: 0 }}>
      <a
        href={campaign.targetLink}
        target="_blank"
        rel="noopener noreferrer"
        className="hero-img-container"
      >
        <img
          key={campaign.id}
          src={campaign.heroImageUrl}
          alt={campaign.title}
          className="hero-img"
          draggable="false"
          style={{ paddingTop: "20px" }}
        ></img>
      </a>
      <div className="hero-footnote">
        <div style={{ marginTop: "10px" }}>
          <span className="arrow-indicator">Featured</span>
          <span className="hero-footnote-title">{campaign.title}</span>
        </div>
        <>
          <ExperienceSelection
            threeSixtyUrl={campaign.immersiveMedia?.threeSixtyUrl}
            threeSixtyDisabled={!campaign.immersiveMedia?.threeSixtyUrl}
            arUrl={campaign.immersiveMedia?.arUrl}
            arDisabled={!campaign.immersiveMedia?.arUrl}
            vrUrl={campaign.immersiveMedia?.vrUrl}
            vrDisabled={!campaign.immersiveMedia?.vrUrl}
          ></ExperienceSelection>
        </>
      </div>
    </div>
  );
};

export default HeroImage;
