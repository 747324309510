import React, { useEffect } from "react";
import "./styles/VideoEmbed.css";
import { VideoEmbedProps } from "../../types/types";
import { Stream } from "@cloudflare/stream-react";

/**
 * Video embed component for embedding videos.
 *
 * @component
 * @example
 * return (
 *   <VideoEmbed videoUrl={videoUrl} />
 * )
 *
 * @param {VideoEmbedProps} props - Component properties.
 * @param {string} props.videoUrl - URL of the video to embed.
 * @param {number} [props.width=560] - Width of the video.
 * @param {number} [props.height=315] - Height of the video.
 * @param {string} [props.title="Embedded video"] - Title of the video.
 */

const VideoEmbed: React.FC<VideoEmbedProps> = ({
  videoUrl,
  videoId,
  width = 560,
  height = 315,
  title = "Embedded video",
}) => {
  // useEffect(() => {
  //   const handleEnterPip = () => {
  //     actions.video.persistVideo(videoUrl, videoId);
  //   };

  //   const handleExitPip = () => {
  //     actions.video.clearVideo();
  //   };

  //   // Unable to detect whether video from cloudflare is in picture in picture mode due 
  //   // to cross origin restrictions, neither programmatically triggering pip mode
  //   const iframe = document.querySelector("iframe") as HTMLIFrameElement;
  //   if (iframe && iframe.contentWindow) {
  //     const innerDoc = iframe.contentDocument || iframe.contentWindow.document;
  //     const videoElement = innerDoc.querySelector("video") as HTMLVideoElement;

  //     if (videoElement) {
  //       videoElement.addEventListener("enterpictureinpicture", handleEnterPip);
  //       videoElement.addEventListener("leavepictureinpicture", handleExitPip);
  //     }
  //   }
  // });

  return (
    <div className="">
      {videoId ?
        <Stream width="560" height="315" controls src={videoId} /> : null}
      {videoUrl ? <iframe
      style={{ "margin": "0 auto", width: '100%'}}
        width={width}
        height={height}
        src={videoUrl}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={title}
      ></iframe> : null}
    </div>
  );
};

export default VideoEmbed;
