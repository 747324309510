import React, { useCallback, useMemo, useState } from "react";
import { Slate, Editable, withReact, RenderElementProps } from "slate-react";
import { createEditor, Descendant } from "slate";
import "./ParagraphEditor.css";

interface ParagraphEditorProps {
  initialValue: Descendant[];
  onChange: (value: Descendant[]) => void;
  title: string;
  placeholder: string;
}

const ParagraphEditor: React.FC<ParagraphEditorProps> = ({
  initialValue,
  onChange,
  title,
  placeholder,
}) => {
  const editor = useMemo(() => withReact(createEditor()), []);
  const [value, setValue] = useState<Descendant[]>(initialValue);

  const renderElement = useCallback((props: RenderElementProps) => {
    const { attributes, children } = props;
    return <p {...attributes}>{children}</p>;
  }, []);

  return (
    <Slate
      editor={editor}
      initialValue={value}
      onChange={(newValue) => {
        setValue(newValue);
        onChange(newValue);
      }}
    >
      <h4>{title}</h4>
      <Editable
        className="editor"
        renderElement={renderElement}
        placeholder={placeholder}
      />
    </Slate>
  );
};

export default ParagraphEditor;
