import { Dispatch } from "redux";
import { Action } from "../types/types";
import { actions } from "../store/auth/auth.slice";

export const getAccessToken = () => {
  return localStorage.getItem("accessToken");
};

export const setAccessToken = (accessToken: string) => {
  localStorage.setItem("accessToken", accessToken);
};

export const removeAccessToken = () => {
  localStorage.removeItem("accessToken");
};

export const getRefreshToken = () => {
  return localStorage.getItem("refreshToken");
};

export const setRefreshToken = (refreshToken: string) => {
  localStorage.setItem("refreshToken", refreshToken);
};

export const removeRefreshToken = () => {
  localStorage.removeItem("refreshToken");
};

export const setUserRole = (role: string) => {
  localStorage.setItem("role", role);
};

export const getUserRole = () => {
  return localStorage.getItem("role");
};

export const removeUserRole = () => {
  localStorage.removeItem("role");
};

export const handleLogout = (dispatch: Dispatch<Action>) => {
  removeAccessToken();
  removeRefreshToken();
  removeUserRole();

  dispatch(actions["auth/logout/success"]());
};

export const handleLogin = (
  dispatch: Dispatch<Action>,
  accessToken: string,
  refreshToken: string,
  role: string
) => {
  setAccessToken(accessToken);
  setRefreshToken(refreshToken);
  setUserRole(role);

  dispatch(actions["auth/login/success"]({ user: null, role: role }));
};

export const saveCurrentLocation = () => {
  const currentLocation = window.location.pathname + window.location.search;
  sessionStorage.setItem("redirectAfterLogin", currentLocation);
};
