import React from "react";
import { SideNavBar } from "../../components";

const AdminSideNavBar: React.FC = () => {
  const sideNavBarData = [
    {
      content: "Campaigns",
      subContents: [
        {
          name: "Add campaign",
          url: "/admin/add-campaign",
          icon: "bx bx-plus-circle bx-xs",
        },
      ],
    },
  ];
  return <SideNavBar data={sideNavBarData}></SideNavBar>;
};

export default AdminSideNavBar;
