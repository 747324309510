import React, { useState, useEffect } from "react";
import { ThumbnailCard } from "../ContentRowSlider";
import "./Gallery.css";
import { NextArrow, PrevArrow } from "../Arrows";
import { GalleryImage } from "../../types/types";

interface GalleryProps {
  images: GalleryImage[];
}

const Gallery: React.FC<GalleryProps> = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [fadingOut, setFadingOut] = useState(false);

  const openModal = (index: number) => {
    setCurrentIndex(index);
    setModalOpen(true);
  };

  const closeModal = () => {
    setFadingOut(true);

    setTimeout(() => {
      setFadingOut(false);
      setModalOpen(false);
    }, 900);
  };

  const navSlides = (n: number) => {
    setCurrentIndex(
      (prevIndex) => (prevIndex + n + images.length) % images.length
    );
  };

  useEffect(() => {
    if (modalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [modalOpen]);

  return (
    <div className="gallery-container">
      {images.map((image, index) => {
        return (
          <div className="gallery-item" key={index}>
            <ThumbnailCard
              title="GalleryImage title"
              subtitle="GalleryImage subtitle"
              thumbnailUrl={image.url}
              id={1}
              onThumbnailCardClick={() => openModal(index)}
            ></ThumbnailCard>
          </div>
        );
      })}
      {modalOpen && (
        <div
          id="myModal"
          className={`modal ${
            modalOpen ? (fadingOut ? "fade-out" : "fade") : "hidden"
          }`}
        >
          <span className="close cursor" onClick={closeModal}>
            &times;
          </span>
          <div className="modal-container fade">
            <div className="modal-content">
              {images.map((img, index) => (
                <div
                  className={`mySlides ${
                    index === currentIndex ? "active" : ""
                  }`}
                  key={index}
                >
                  <div className="numbertext">
                    {index + 1} / {images.length}
                  </div>
                  <div className="img-container">
                    <img src={img.url} alt="gallery caption" />
                  </div>
                </div>
              ))}

              <PrevArrow className="prev" onClick={() => navSlides(-1)} />
              <NextArrow className="next" onClick={() => navSlides(1)} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
