import React, { useState, useRef } from "react";
import ReactCrop, {
  Crop,
  PixelCrop,
  centerCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useDebounceEffect } from "../../utils/useDebounceEffect";
import { canvasPreview } from "../../utils/canvasPreview";
import { readAndCompressImage } from "browser-image-resizer";

interface ImageUploadAndProcessProps {
  title: string;
  aspect: number;
  width: number;
  height: number;
  onProcessed: (processedImage: string) => void;
}

const centerAspectCrop = (
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
};

const ImageUploadAndProcess: React.FC<ImageUploadAndProcessProps> = ({
  title,
  aspect,
  width,
  height,
  onProcessed,
}) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setSelectedImage(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  const handleProcessedImage = async () => {
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error("Crop canvas does not exist");
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      throw new Error("No 2d context");
    }

    canvas.width = completedCrop.width * scaleX;
    canvas.height = completedCrop.height * scaleY;

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      canvas.width,
      canvas.height
    );

    canvas.toBlob(async (blob) => {
      if (!blob) {
        console.error("Canvas is empty");
        return;
      }

      const file = new File([blob], "croppedImage.png", {
        type: "image/png",
      });

      const options = {
        quality: 1,
        maxWidth: width,
        maxHeight: height,
        mimeType: "image/png",
      };

      try {
        const resizedImageBlob = await readAndCompressImage(file, options);
        const resizedImageUrl = URL.createObjectURL(resizedImageBlob);
        onProcessed(resizedImageUrl);
      } catch (error) {
        console.error("Error resizing image", error);
      }
    });
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop]
  );

  return (
    <div>
      <h4>{title}</h4>
      <input type="file" accept="image/*" onChange={handleImageUpload} />
      {!!selectedImage && (
        <ReactCrop
          crop={crop}
          aspect={aspect}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(newCrop) => setCompletedCrop(newCrop)}
          minHeight={100}
        >
          <img
            ref={imgRef}
            src={selectedImage}
            alt="crop"
            onLoad={onImageLoad}
          />
        </ReactCrop>
      )}
      <h4>Crop Preview</h4>
      {!!completedCrop && (
        <>
          <div>
            <canvas
              ref={previewCanvasRef}
              style={{
                objectFit: "contain",
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          </div>
        </>
      )}
      <button onClick={handleProcessedImage}>Process Image</button>
    </div>
  );
};

export default ImageUploadAndProcess;
