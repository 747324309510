import { createSlice } from "@reduxjs/toolkit";
import { AdminState } from "./admin.entity";
import adminReducers from "./admin.reducer";

export const initialState: AdminState = {
  admins: [],
  admin: null,
};

const adminsSlice = createSlice({
  name: "admins",
  initialState: initialState,
  reducers: adminReducers,
});

export const { actions, reducer } = adminsSlice;
