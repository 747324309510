import axios from "axios";
import MockAdapter from "axios-mock-adapter";
import {
  allUsers,
  allAdmins,
  allCategories,
  allCompanies,
  allCampaigns,
  allGalleryImages,
  allProfiles,
} from "./mockData";

const apiClient = axios.create({
  baseURL: "/",
  headers: { "Content-Type": "application/json" },
});

const mock = new MockAdapter(apiClient, { delayResponse: 500 });

// Mock GET requests

// Get All User
mock.onGet("/user").reply(200, allUsers);

// Get User by id
mock.onGet(/\user\/\d+/).reply((config) => {
  const id = config.url?.split("/").pop();
  const user = allUsers.find((user) => user.id.toString() === id);
  return user ? [200, user] : [404, {}];
});

// Get All Admin
mock.onGet("/admin").reply(200, allAdmins);

// Get Admin by id
mock.onGet(/\/admin\/\d+/).reply((config) => {
  const id = config.url?.split("/").pop();
  const admin = allAdmins.find((admin) => admin.id.toString() === id);
  return admin ? [200, admin] : [404, {}];
});

// Get Admin by companyId
mock.onGet(/\/admin\/company\/\d+/).reply((config) => {
  const companyId = config.url?.split("/").pop();
  const admin = allAdmins.find(
    (admin) => admin.companyId.toString() === companyId
  );
  return admin ? [200, admin] : [404, {}];
});

// Get All Category
mock.onGet("/category").reply(200, allCategories);

// Get Category by id
mock.onGet(/\/category\/\d+/).reply((config) => {
  const id = config.url?.split("/").pop();
  const category = allCategories.find(
    (category) => category.id.toString() === id
  );
  return category ? [200, category] : [404, {}];
});

// Get All Company
mock.onGet("/company").reply(200, allCompanies);

// Get Company by id
mock.onGet(/\/company\/\d+/).reply((config) => {
  const id = config.url?.split("/").pop();
  const company = allCompanies.find((company) => company.id.toString() === id);
  return company ? [200, company] : [404, {}];
});

// Get Company by editorId
mock.onGet(/\/company\/editor\/\d+/).reply((config) => {
  const editorId = config.url?.split("/").pop();
  const companies = allCompanies.filter(
    (company) => company.editorId.toString() === editorId
  );
  return [200, companies];
});

// Get Company by adminId
mock.onGet(/\/company\/admin\/\d+/).reply((config) => {
  const adminId = config.url?.split("/").pop();
  const companies = allCompanies.filter(
    (company) => company.adminId.toString() === adminId
  );
  return [200, companies];
});

// Get all Campaign
mock.onGet("/campaign").reply(200, allCampaigns)
//mock.onGet("/campaign").reply(200, allCampaigns);

// Get Campaign by id
mock.onGet(/\/campaign\/\d+/).reply((config) => {
  const id = config.url?.split("/").pop();
  const campaign = allCampaigns.find(
    (campaign) => campaign.id.toString() === id
  );
  return campaign ? [200, campaign] : [404, {}];
});

// Get Campaign by categoryId
mock.onGet(/\/campaign\/category\/\d+/).reply((config) => {
  const categoryId = config.url?.split("/").pop();
  const campaigns = allCampaigns.filter(
    (campaign) => campaign.categoryId.toString() === categoryId
  );
  return [200, campaigns];
});

// Get All Gallery Images
mock.onGet("/galleryImages").reply(200, allGalleryImages);

// Get Gallery Image by id
mock.onGet(/\/galleryImages\/\d+/).reply((config) => {
  const id = config.url?.split("/").pop();
  const galleryImage = allGalleryImages.find(
    (image) => image.id.toString() === id
  );
  return galleryImage ? [200, galleryImage] : [404, {}];
});

// Get All Profiles
mock.onGet("/profile").reply(200, allProfiles);

// Get Profile by user id
mock.onGet(/\/profile\/\d+/).reply((config) => {
  const id = config.url?.split("/").pop();
  const profile = allProfiles.find(
    (profile) => profile.userId.toString() === id
  );
  return profile ? [200, profile] : [404, {}];
});

export default apiClient;
