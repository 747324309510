import React, { ReactNode } from "react";
import "./FullPreviewPage.css";
import "../../styles/AllPages.css";
import { useParams } from "react-router-dom";
import { Details, Home } from "../../pages";

const FullPreviewPage: React.FC = () => {
  const { page } = useParams<{ page: string | undefined }>();
  const pageComponent: ReactNode =
    page === "home" ? <Home></Home> : <Details></Details>;

  const getPageName = (node: React.ReactNode): string | null => {
    if (React.isValidElement(node) && typeof node.type === "function") {
      return node.type.name || null;
    } else if (React.isValidElement(node) && typeof node.type === "string") {
      return node.type;
    }
    return null;
  };

  return (
    <div className="fullscreen-container app-container">
      <h1>{getPageName(pageComponent)} page preview</h1>
      {pageComponent}
    </div>
  );
};

export default FullPreviewPage;
