import { createSlice } from "@reduxjs/toolkit";
import { CategoryState } from "./categories.entity";
import categoryReducers from "./categories.reducer";
import { Category } from "../../types/types";

export const initialState: CategoryState = {
  categories: [],
  selectedCategory: {} as Category,
};

const categorySlice = createSlice({
  name: "categories",
  initialState: initialState,
  reducers: categoryReducers,
});

export const { actions, reducer } = categorySlice;
