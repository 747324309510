import { createSlice } from "@reduxjs/toolkit";
import { MediaState } from "./media.entity";
import mediaReducers from "./media.reducer";

export const initialState: MediaState = {
  heros: [],
  thumbnails: [],
  standardVideos: [],
  youtubeVideos: [],
  spatialVideos: [],
  galleryImages: [],
};

const mediaSlice = createSlice({
  name: "media",
  initialState: initialState,
  reducers: mediaReducers,
});

export const { actions, reducer } = mediaSlice;
