import React from "react";
import { VideoProps } from "../../types/types";
import "./styles/Video.css";
import VideoEmbed from "./VideoEmbed";
import YouTubeVideoEmbed from "./YouTubeVideoEmbed";

/**
 * Video component for displaying multiple videos.
 *
 * @component
 * @example
 * return (
 *   <Video videos={videos} />
 * )
 *
 * @param {VideoProps} props - Component properties.
 * @param {string[]} props.videos - Array of video URLs or YouTube video IDs.
 * @param {boolean} [props.isYT=false] - Flag to determine if videos are from YouTube.
 */

const Video: React.FC<VideoProps> = ({ videos, isYT = false, isVideoId = false }) => {
  return (
    <div
      className={`video-container ${
        videos.length === 1
          ? "one-video"
          : videos.length === 2
          ? "two-video"
          : "four-video"
      }`}
    >
      {/* Change index to video id */}
      {videos.map((video, index) =>
        isYT ? (
          <YouTubeVideoEmbed key={index} videoId={video} width={800} height={394}></YouTubeVideoEmbed>
        ) : (
          <VideoEmbed key={index} videoUrl={isVideoId ? undefined : video} videoId={isVideoId ? video : undefined}></VideoEmbed>
        )
      )}
    </div>
  );
};

export default Video;
