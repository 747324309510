import { Navigate, Outlet } from "react-router-dom";
import { RootState, useAppSelector } from "../../store/root.store";

const SignupProtectedRoutes = () => {
  const isRegistered = useAppSelector((state: RootState) => state.auth.email);

  return isRegistered ? (
    <Outlet></Outlet>
  ) : (
    <Navigate to={`/user-signup`}></Navigate>
  );
};

export default SignupProtectedRoutes;
