import React from "react";
import "./Footer.css";

/**
 * Footer component displaying copyright information.
 *
 * @component
 * @example
 * return (
 *   <Footer />
 * )
 */

const Footer: React.FC = () => {
  return (
    <div className="footer-container">
      <footer className="footer">
        Copyright © 2020 - 2024 XRTrailers, Engage VR, LLC, All Rights Reserved
      </footer>
    </div>
  );
};

export default Footer;
