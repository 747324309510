import { Action } from "redux";
import { dispatchable } from "../dispatchable";
import apiClient from "../../mock/apiClient";
import { Category } from "../../types/types";
import { Dispatch } from "redux";
import { actions } from "./categories.slice";

export const getAllCategories = dispatchable(() => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const response = await apiClient.get<Category[]>(`/category`);
      dispatch(actions["categories/load"](response.data));
      console.log("Fetching all categories: ", response.data);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        data: null,
      };
    }
  };
});

export const getCategoryById = dispatchable((id: number) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const response = await apiClient.get<Category>(`/category/${id}`);
      dispatch(actions["categories/category"](response.data));
      console.log("Fetching category by id: ", response.data);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        data: null,
      };
    }
  };
});
