import { PayloadAction } from "@reduxjs/toolkit";
import { CompanyState } from "./company.entity";
import { Company } from "../../types/types";

const companyReducers = {
  "companies/load": (state: CompanyState, action: PayloadAction<Company[]>) => {
    state.companies = action.payload;
  },
  "companies/company": (
    state: CompanyState,
    action: PayloadAction<Company>
  ) => {
    state.company = action.payload;
  },
};

export default companyReducers;
