import React, { useRef, useState, useEffect } from "react";
import "../../components/Forms/styles/Form.css";
import "./SudoLogin.css";
import { Link } from "react-router-dom";
import { useNav } from "../../utils/navigation";
import { actions, useAppSelector, RootState } from "../../store/root.store";

const SudoLogin: React.FC = () => {
  const fieldRef = useRef<HTMLInputElement>(null);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [hidePassword, setHidePassword] = useState<boolean>(true);
  const error = useAppSelector((state: RootState) => state.auth.error);
  const loading = useAppSelector((state: RootState) => state.auth.loading);
  const { goToSuperUserPage } = useNav();

  const handleEyeIconClick = () => {
    setHidePassword(!hidePassword);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleLogin();
  };

  const handleLogin = async () => {
    const res = await actions.auth.sudoLogin(email, password);

    if (res.success && !loading) {
      goToSuperUserPage();
    }
  };

  useEffect(() => {
    actions.auth.clearAuthError();

    if (fieldRef.current) {
      fieldRef.current.focus();
    }

    return () => {
      actions.auth.clearAuthError();
    };
  }, []);

  return (
    <div className="form-container">
      <div className="login-form">
        <header>Sudo Login</header>
        <span
          id="eidnote"
          className={`auth-message ${
            error !== null && !loading ? "show" : "hide"
          }`}
        >
          {error}
        </span>
        <form onSubmit={handleSubmit}>
          <div className="field input-field">
            <input
              type="text"
              ref={fieldRef}
              name="email"
              placeholder="Enter Email"
              autoComplete="off"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="field input-field">
            <input
              type={hidePassword ? "password" : "text"}
              name="password"
              placeholder="Enter Password"
              autoComplete="off"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <i
              className={`bx eye-icon default-eye-icon ${
                hidePassword ? `bx-hide` : `bx-show`
              }`}
              onClick={handleEyeIconClick}
            ></i>
          </div>
          <div className="field button-field">
            <button type="submit">Login</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SudoLogin;
