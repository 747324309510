import { PayloadAction } from "@reduxjs/toolkit";
import { GalleryImageState } from "./galleryImages.entity";
import { GalleryImage } from "../../types/types";

const galleryImageReducers = {
  "galleryImages/load": (
    state: GalleryImageState,
    action: PayloadAction<GalleryImage[]>
  ) => {
    state.galleryImages = action.payload;
  },
};

export default galleryImageReducers;
