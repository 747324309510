import React, { useEffect } from "react";
import { useAppSelector, RootState } from "../../store/root.store";
import { VideoEmbed } from "../Video";

const PiPVideoProvider: React.FC = () => {
  const video = useAppSelector((state: RootState) => state.video);

  useEffect(() => {
    if (document.pictureInPictureElement) {
      const video = document.pictureInPictureElement as HTMLVideoElement;
      video.requestPictureInPicture();
    }
  }, []);

  return (
    <>
      {video.isActive && (
        <VideoEmbed
          videoId={video.videoId}
          videoUrl={video.videoUrl}
          title="active picture in picture video"
        ></VideoEmbed>
      )}
    </>
  );
};

export default PiPVideoProvider;
