import "./styles/SearchBar.css";
import { DebounceInput } from "react-debounce-input";

interface SearchBarProps {
  searchTerm: string;
  onChange: (searchTerm: string) => void;
  placeholder?: string;
  debounceTimeout?: number;
  minLength?: number;
}

const SearchBar: React.FC<SearchBarProps> = ({
  searchTerm,
  onChange,
  placeholder = "Search",
  debounceTimeout = 500,
  minLength = 1,
}) => {
  return (
    <div className="field">
      <DebounceInput
        placeholder={placeholder}
        value={searchTerm}
        minLength={minLength}
        debounceTimeout={debounceTimeout}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChange(e.target.value)
        }
      ></DebounceInput>
      <i className="bx bx-search search-icon"></i>
    </div>
  );
};

export default SearchBar;
