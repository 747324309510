import apiClient from "../../mock/apiClient";
import { Action } from "../../types/types";
import { dispatchable } from "../dispatchable";
import { Dispatch } from "redux";
import { actions } from "./admin.slice";
import { Admin } from "../../types/types";

export const getAllAdmins = dispatchable(() => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const response = await apiClient.get<Admin[]>(`/admin`);
      dispatch(actions["admins/load"](response.data));
      console.log("Fetching all admins: ", response.data);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        data: null,
      };
    }
  };
});

export const getAdminById = dispatchable((id: string | undefined) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const response = await apiClient.get<Admin>(`/admin/${id}`);
      dispatch(actions["admins/admin"](response.data));
      console.log("Fetching admin by id: ", response.data);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        data: null,
      };
    }
  };
});
