import { createSlice } from "@reduxjs/toolkit";
import { GalleryImageState } from "./galleryImages.entity";
import galleryImageReducers from "./galleryImages.reducer";

export const initialState: GalleryImageState = {
  galleryImages: [],
};

const galleryImagesSlice = createSlice({
  name: "galleryImages",
  initialState: initialState,
  reducers: galleryImageReducers,
});

export const { actions, reducer } = galleryImagesSlice;
