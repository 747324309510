import { Campaign } from "../types/types";

export const saveCampaignState = (state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem("campaign", serializedState);
  } catch (error) {
    console.error("Error saving campaign state to seesion storage", error);
  }
};

export const loadCampaignState = () => {
  try {
    const serializedState = sessionStorage.getItem("campaign");
    if (!serializedState) {
      return {} as Campaign;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    console.error("Error loading state from session storage", error);
    return {} as Campaign;
  }
};

export const isEmptyObject = (obj: any) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};
