import React from "react";
import "./styles/ContentRowSlider.css";
import { ContentRowSliderProps } from "../../types/types";
import { useAppSelector } from "../../store/root.store";
import { RootState } from "../../store/root.store";
import ThumbnailCard from "./ThumbnailCard";

/**
 * ContentRowSlider component that displays a row of thumbnails in a slider format.
 * @param {ContentRowSliderProps} props
 * @param {string} props.title - Title for the slider.
 * @param {Array} props.campaigns - List of campaign objects.
 * @param {function} props.onThumbnailClick - Handler called when a thumbnail is clicked.
 * @param {function} props.onMoreThumbnailClick - Handler called when the more thumbnail is clicked.
 * @param {number} [props.thumbnailsCount=6] - Number of thumbnails in the subcarousel, default 6 thumbnails.
 * @returns {JSX.Element} A slider displaying a row of thumbnails with navigation controls.
 */

const ContentRowSlider: React.FC<ContentRowSliderProps> = ({
  title,
  campaigns,
  onThumbnailClick,
  onMoreThumbnailClick,
  thumbnailsCount = 6,
}) => {
  const selectedCategory = useAppSelector(
    (state: RootState) => state.category.selectedCategory
  );

  return (
    <div className="component-container">
      <span className="slider-header arrow-indicator">{title}</span>
      <div className="slider-content">
        <div className="slider-container">
          {Array.from({ length: thumbnailsCount }).map((_, index) => {
            if (index < campaigns.length) {
              const campaign = campaigns[index];
              return (
                <div className="slider-item" key={campaign.id}>
                  <ThumbnailCard
                    id={campaign.id}
                    thumbnailUrl={campaign.thumbnailUrl}
                    title={campaign.title}
                    subtitle={campaign.subtitle}
                    onThumbnailCardClick={onThumbnailClick}
                    key={campaign.id}
                  ></ThumbnailCard>
                </div>
              );
            } else {
              return (
                <div className="slider-item" key={`default-${index}`}>
                  <div className="default-thumbnail">
                    {/* <i className="bx bx-image bx-lg"></i> */}
                  </div>
                </div>
              );
            }
          })}
          <div className="slider-item">
            <div
              className="more-thumbnail"
              onClick={() =>
                onMoreThumbnailClick(selectedCategory.name, "Review")
              }
            >
              <h4>Explore More</h4>
            </div>
          </div>
        </div>
        <div className="next">
          <i className="bx bx-chevron-right bx-lg"></i>
        </div>
      </div>
    </div>
  );
};

export default ContentRowSlider;
