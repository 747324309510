import React from "react";
import Lottie from "react-lottie-player";
import loaderAnimation from "../../assets/loader/loader.json";
import "./Loader.css";

interface LoaderProps {
  width?: number;
  height?: number;
}

const Loader: React.FC<LoaderProps> = ({ width = 150, height = 150 }) => {
  return (
    <Lottie
      loop
      animationData={loaderAnimation}
      play
      style={{ width, height }}
    />
  );
};

export default Loader;
