import { Descendant, Node } from "slate";

// Deserialize Slate value with paragraph markers to string
export const deserialize = (value: Descendant[]): string => {
  return value.map((n) => Node.string(n)).join("\n\n");
};

// Serialize string to Slate value with paragraph markers
export const serialize = (text: string): Descendant[] => {
  return text.split("\n\n").map((paragraph) => ({
    type: "paragraph",
    children: [{ text: paragraph }],
  }));
};

// Extract string field from Descendant[]
export const extractTextFromDescendants = (
  descendants: Descendant[]
): string => {
  return descendants
    .map((descendant) => {
      if ("text" in descendant) {
        return descendant.text;
      }
      if ("children" in descendant) {
        return extractTextFromDescendants(descendant.children);
      }
      return "";
    })
    .join(" ");
};
