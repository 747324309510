import { Dispatch } from "redux";
import { dispatchable } from "../dispatchable";
import { Action } from "../../types/types";
import api from "../../services/api";
import { actions } from "./media.slice";
import axios from "axios";

export const fetchPreUploadedHeroImages = dispatchable(
  (companyPublicId: string) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const response = await api.get(
          `/reuse-media/hero-image/${companyPublicId}`,
          { requiresAuth: true }
        );

        if (response.status === 200) {
          dispatch(
            actions["media/hero"]({ heros: response.data.info.heroImages })
          );

          return { success: true, data: response.data };
        } else {
          return { success: false, data: null };
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            const { status } = error.response;
            console.log(`${status}`, error);
          }
        }
        return { success: false, data: null };
      }
    };
  }
);

export const fetchPreUploadedThumbnailImages = dispatchable(
  (companyPublicId: string) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const response = await api.get(
          `/reuse-media/thumbnail-image/${companyPublicId}`,
          { requiresAuth: true }
        );

        if (response.status === 200) {
          dispatch(
            actions["media/thumbnail"]({
              thumbnails: response.data.info.thumbnailImages,
            })
          );

          return { success: true, data: response.data };
        } else {
          return { success: false, data: null };
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            const { status } = error.response;
            console.log(`${status}`, error);
          }
        }
        return { success: false, data: null };
      }
    };
  }
);

export const fetchPreUploadedStandardVideos = dispatchable(
  (companyPublicId: string) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const response = await api.get(
          `/reuse-media/standard-video/${companyPublicId}`,
          { requiresAuth: true }
        );

        if (response.status === 200) {
          dispatch(
            actions["media/video/standard"]({
              videos: response.data.info.standardVideos,
            })
          );

          return { success: true, data: response.data };
        } else {
          return { success: false, data: null };
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            const { status } = error.response;
            console.log(`${status}`, error);
          }
        }
        return { success: false, data: null };
      }
    };
  }
);

export const fetchPreUploadedYoutubeVideos = dispatchable(
  (companyPublicId: string) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const response = await api.get(
          `/reuse-media/youtube/${companyPublicId}`,
          { requiresAuth: true }
        );

        if (response.status === 200) {
          dispatch(
            actions["media/video/youtube"]({
              videos: response.data.info.youtubeIds,
            })
          );

          return { success: true, data: response.data };
        } else {
          return { success: false, data: null };
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            const { status } = error.response;
            console.log(`${status}`, error);
          }
        }
        return { success: false, data: null };
      }
    };
  }
);

export const fetchPreUploadedSpatialVideos = dispatchable(
  (companyPublicId: string) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const response = await api.get(
          `/reuse-media/spatial-video/${companyPublicId}`,
          { requiresAuth: true }
        );

        if (response.status === 200) {
          dispatch(
            actions["media/video/spatial"]({
              videos: response.data.info.spatialVideos,
            })
          );

          return { success: true, data: response.data };
        } else {
          return { success: false, data: null };
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            const { status } = error.response;
            console.log(`${status}`, error);
          }
        }
        return { success: false, data: null };
      }
    };
  }
);

export const fetchPreUploadedGalleryImages = dispatchable(
  (companyPublicId: string) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const response = await api.get(
          `/reuse-media/gallery-image/${companyPublicId}`,
          { requiresAuth: true }
        );

        if (response.status === 200) {
          dispatch(
            actions["media/gallery"]({
              galleryImages: response.data.info.galleryImages,
            })
          );

          return { success: true, data: response.data };
        } else {
          return { success: false, data: null };
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            const { status } = error.response;
            console.log(`${status}`, error);
          }
        }
        return { success: false, data: null };
      }
    };
  }
);
