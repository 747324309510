import { createSlice } from "@reduxjs/toolkit";
import { CompanyState } from "./company.entity";
import companyReducers from "./company.reducer";

export const initialState: CompanyState = {
  companies: [],
  company: null,
};

const companiesSlice = createSlice({
  name: "companies",
  initialState: initialState,
  reducers: companyReducers,
});

export const { actions, reducer } = companiesSlice;
