import { Action, Dispatch } from "redux";
import { dispatchable } from "../dispatchable";
import apiClient from "../../mock/apiClient";
import { GalleryImage } from "../../types/types";
import { actions } from "./galleryImages.slice";

export const getAllGalleryImages = dispatchable(() => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const response = await apiClient.get<GalleryImage[]>(`/galleryImages`);
      dispatch(actions["galleryImages/load"](response.data));
      console.log("Fetching all gallery images: ", response.data);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        data: null,
      };
    }
  };
});
