import React, { useEffect, useState } from "react";
import "./styles/SearchResult.css";

interface SearchResultProp {
  results: any[];
  renderResult: (result: any) => React.ReactNode;
  itemsPerPage: number;
}

const SearchResult: React.FC<SearchResultProp> = ({
  results,
  renderResult,
  itemsPerPage,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(results.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const paginatedResults = results.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentPage]);

  return (
    <>
      <div className="search-result-container">
        {paginatedResults.length > 0 ? (
          paginatedResults.map((result, index) => (
            <div key={index} className="search-item">
              {renderResult(result)}
            </div>
          ))
        ) : (
          <div className="search-result-empty">No results found.</div>
        )}
      </div>
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            disabled={currentPage === index + 1}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </>
  );
};

export default SearchResult;
