import React, { useEffect } from "react";
import "./Admin.css";
import AdminSideNavBar from "./AdminSideNavBar";
import { actions } from "../../store/root.store";
import { getUserRole, getAccessToken } from "../../utils/auth";

const Admin: React.FC = () => {
  const fetchAdminInfo = async () => {
    await actions.auth.fetchAdminInfo();
  };

  useEffect(() => {
    if (getUserRole() === "admin" && !!getAccessToken()) {
      fetchAdminInfo();
    }
  }, []);

  return (
    <div className="component-container" style={{ paddingTop: 0 }}>
      <div className="admin-container">
        <AdminSideNavBar></AdminSideNavBar>
      </div>
    </div>
  );
};

export default Admin;
