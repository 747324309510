import { PayloadAction } from "@reduxjs/toolkit";
import { CategoryState } from "./categories.entity";
import { Category } from "../../types/types";

const categoryReducers = {
  "categories/load": (
    state: CategoryState,
    action: PayloadAction<Category[]>
  ) => {
    state.categories = action.payload;
    state.selectedCategory = action.payload[0];
  },
  "categories/category": (
    state: CategoryState,
    action: PayloadAction<Category>
  ) => {
    state.selectedCategory = action.payload;
  },
};

export default categoryReducers;
