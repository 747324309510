import { Navigate, Outlet } from "react-router-dom";
import { getAccessToken, getUserRole } from "../../utils/auth";

const AdminProtectedRoutes = () => {
  const isAuthenticated = !!getAccessToken();
  const isAdminUser = getUserRole() === "admin";

  return isAuthenticated && isAdminUser ? (
    <Outlet></Outlet>
  ) : (
    <Navigate to={`/admin-login`}></Navigate>
  );
};

export default AdminProtectedRoutes;
