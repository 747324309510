import { useCallback, useEffect, useState } from "react";

const useFullScreen = (elementRef: React.RefObject<HTMLElement>) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [disableShortcut, setDisableShortcut] = useState(false);

  const goInFullscreen = useCallback(() => {
    const element = elementRef.current;
    if (element?.requestFullscreen) {
      element.requestFullscreen();
    }
    setIsFullscreen(true);
  }, [elementRef]);

  const goOutFullscreen = useCallback(() => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
    setIsFullscreen(false);
  }, []);

  const toggleFullscreen = useCallback(() => {
    if (isFullscreen) {
      goOutFullscreen();
    } else {
      goInFullscreen();
    }
  }, [isFullscreen, goInFullscreen, goOutFullscreen]);

  useEffect(() => {
    const handleFocusIn = (event: FocusEvent) => {
      const target = event.target as HTMLElement;
      if (
        target.tagName === "INPUT" ||
        target.tagName === "TEXTAREA" ||
        target.isContentEditable
      ) {
        setDisableShortcut(true);
      }
    };

    const handleFocusOut = () => {
      setDisableShortcut(false);
    };

    document.addEventListener("focusin", handleFocusIn);
    document.addEventListener("focusout", handleFocusOut);

    return () => {
      document.removeEventListener("focusin", handleFocusIn);
      document.removeEventListener("focusout", handleFocusOut);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (disableShortcut) return;

      if (event.key === "d") {
        toggleFullscreen();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [toggleFullscreen, disableShortcut]);

  return { isFullscreen, toggleFullscreen, goInFullscreen, goOutFullscreen };
};

export default useFullScreen;
