import React from "react";
import { CustomArrowProps } from "react-slick";

/**
 * Custom previous arrow component for the content row slider.
 * @param {CustomArrowProps} props
 * @param {string} props.className - CSS class applied to the arrow.
 * @param {object} props.style - Inline styles for the arrow.
 * @param {function} props.onClick - Click handler for the arrow.
 * @returns {JSX.Element} A div representing the previous arrow.
 */

const PrevArrow: React.FC<CustomArrowProps> = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      style={{ ...style, display: "block", zIndex: 1 }}
    >
      &#10094;
    </div>
  );
};

export default PrevArrow;
