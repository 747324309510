import React from "react";
import { YouTubeVideoEmbedProps } from "../../types/types";
import "./styles/VideoEmbed.css";

/**
 * YouTube video embed component for embedding YouTube videos.
 *
 * @component
 * @example
 * return (
 *   <YouTubeVideoEmbed videoId={videoId} />
 * )
 *
 * @param {YouTubeVideoEmbedProps} props - Component properties.
 * @param {string} props.videoId - YouTube video ID.
 * @param {number} [props.width=560] - Width of the video.
 * @param {number} [props.height=315] - Height of the video.
 * @param {string} [props.title="Embedded YouTube video"] - Title of the video.
 */

const YouTubeVideoEmbed: React.FC<YouTubeVideoEmbedProps> = ({
  videoId,
  width = 560,
  height = 315,
  title = "Embedded YouTube video",
}) => {
  const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  return (
    <div className="video-embed">
      <iframe
        width={width}
        height={height}
        src={embedUrl}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
        title={title}
      ></iframe>
    </div>
  );
};

export default YouTubeVideoEmbed;
