import React, { useEffect, useRef } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { actions, store } from "../store/root.store";
import {
  AdminSignup,
  CompanySignup,
  Home,
  UserPasswordReset,
  UserProfile,
  UserSignup,
  Details,
  UserLogin,
  SuperUser,
  Search,
  More,
  UserForgotPassword,
  AdminLogin,
  SudoLogin,
  Admin,
  SignupConfirmation,
  AdminAddCampaign,
} from "../pages";
import {
  Header,
  UnAuthProtectedRoutes,
  StandardProtectedRoutes,
  Footer,
  AuthListener,
  SudoProtectedRoutes,
  NavigationListener,
  SignupProtectedRoutes,
  FullPreviewPage,
  AdminProtectedRoutes,
  PiPVideoProvider,
} from "../components";
import AccountVerification from "../pages/AccountVerification";
import SpatialVideoShowcase from "../pages/SpatialVideoShowcase";
import useFullScreen from "../utils/useFullScreen";
import TideComponent from "../pages/Misc/TideConcept/TideConcept";

function App() {
  const fullViewRef = useRef(null);
  const { isFullscreen, toggleFullscreen } = useFullScreen(fullViewRef);

  const initializeAuth = async () => {
    await actions.auth.checkAuthentication();
  };

  useEffect(() => {
    initializeAuth();
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <NavigationListener />
        <PiPVideoProvider></PiPVideoProvider>
        <div
          ref={fullViewRef}
          className={`app-container ${isFullscreen ? `full-screen-mode` : ``}`}
        >
          <AuthListener></AuthListener>
          <Header></Header>
          <Routes>
            <Route path="/" element={<Home></Home>} />
            <Route path="/details/:id" element={<Details></Details>} />
            <Route
              element={<SudoProtectedRoutes></SudoProtectedRoutes>}
            ></Route>
            <Route path="/super-user" element={<SuperUser></SuperUser>} />
            <Route element={<UnAuthProtectedRoutes></UnAuthProtectedRoutes>}>
              <Route path="/user-login" element={<UserLogin></UserLogin>} />
              <Route path="/user-signup" element={<UserSignup></UserSignup>} />
              <Route path="/admin-login" element={<AdminLogin></AdminLogin>} />
              <Route path="/sudo-login" element={<SudoLogin></SudoLogin>} />
              <Route
                path="/user-password-reset/:role"
                element={<UserPasswordReset></UserPasswordReset>}
              />
              <Route
                path="/user-forgot-password/:role"
                element={<UserForgotPassword></UserForgotPassword>}
              />
            </Route>
            <Route element={<SignupProtectedRoutes></SignupProtectedRoutes>}>
              <Route
                path="/signup-confirmation/:role"
                element={<SignupConfirmation></SignupConfirmation>}
              />
            </Route>
            <Route
              path="/account-verify/:role"
              element={<AccountVerification></AccountVerification>}
            />
            {/* Notify server side to include role identifier to differentiate between 
            admin and standard for sign up verification */}
            <Route
              path="/more/:category/:subCategory"
              element={<More></More>}
            />
            <Route path="/search" element={<Search></Search>} />
            <Route
              element={<StandardProtectedRoutes></StandardProtectedRoutes>}
            >
              <Route
                path="/user-profile"
                element={<UserProfile></UserProfile>}
              />
            </Route>
            <Route
              path="/company-signup"
              element={<CompanySignup></CompanySignup>}
            />
            <Route
              path="/admin-signup/:id"
              element={<AdminSignup></AdminSignup>}
            />
            <Route element={<AdminProtectedRoutes></AdminProtectedRoutes>}>
              <Route path="/admin" element={<Admin></Admin>}></Route>
              <Route
                path="/admin/add-campaign"
                element={<AdminAddCampaign></AdminAddCampaign>}
              ></Route>
            </Route>
            <Route
              path="/preview-page/home"
              element={<FullPreviewPage></FullPreviewPage>}
            />
            <Route
              path="/preview-page/details/:id"
              element={<FullPreviewPage></FullPreviewPage>}
            />
            <Route
              path="/spatial-video-showcase"
              element={<SpatialVideoShowcase />}
            />
            <Route path="/tide-concept-24" element={<TideComponent />} />
          </Routes>
          <Footer></Footer>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
