import React, { useEffect, useState, useRef } from "react";
import { HeroImage, Video, Gallery, Content, Loader } from "../../../components";
import "../../Details/Details.css";
import "../../../styles/AllPages.css";
import { useParams } from "react-router-dom";
import { RootState, actions, useAppSelector } from "../../../store/root.store";

import {
  setDefaultTwitterCardDescription,
  setDefaultTwitterCardImage,
  setDefaultTwitterCardTitle,
  setTwitterCardDescription,
  setTwitterCardImage,
  setTwitterCardTitle,
} from "../../../utils/metaTags";
import { generateMockCampaign, LOCAL_MEDIA_SERVER_URL, USE_LOCAL_MEDIA_SERVER } from "../../../mock/mockData";


/**
 * Details page for displaying details of a specific campaign.
 */

const TideComponent: React.FC = () => {
  const { id } = useParams<{ id: string | undefined }>();
  const [loading, setLoading] = useState<boolean>(true);

  const campaign = TideCampaign;

  // Function to fetch campaign details by ID
  const handleGetCampaignById = async (id: string | undefined) => {
    setLoading(true);
    await actions.campaign.getCampaignById(id);
    setLoading(false);
  };

  // Fetch campaign details on component mount or when ID changes
  useEffect(() => {
    handleGetCampaignById(id);
  }, [id]);

  useEffect(() => {
    document.title = `XRTrailers - ${campaign.title}`;

    setTwitterCardTitle(campaign.title);
    setTwitterCardDescription(campaign.subtitle);
    setTwitterCardImage(campaign.thumbnailUrl);

    return () => {
      document.title = "XRTrailers";

      setDefaultTwitterCardTitle();
      setDefaultTwitterCardDescription();
      setDefaultTwitterCardImage();
    };
  }, [campaign]);

  if (loading) {
    return (
      <div className="loader-container">
        <Loader width={50} height={50}></Loader>
      </div>
    );
  }

  

  return (
    <>
      <>
        <HeroImage campaign={campaign}></HeroImage>
      </>
      
      <>
        <Content
          campaign={campaign}
        ></Content>
      </>
      
      <>
        <Gallery
          images={campaign.galleryImages}
        ></Gallery>
      </>
    </>
  );
};

export default TideComponent;


const TideCampaign = generateMockCampaign({
    id: 4,
    title: "Tide Memories",
    subtitle: "Memories vs. stains",
    heroImageUrl: USE_LOCAL_MEDIA_SERVER
    ? `${LOCAL_MEDIA_SERVER_URL}/hero-images/Tide_hero_banner.jpg`
    : "https://imagedelivery.net/ETWtoYsfsTHsL5CbKjKrXg/4167f3d1-9491-4312-cdb5-0750d74df700/featuredHeader",
    thumbnailUrl: "",
    articleText:
    "Tide HE Turbo Clean detergents feature Smart Suds™ technology. The quick-collapsing suds of this high-efficiency detergent clean faster and rinse out quicker, even in cold cycles. Try the refreshing Mountain Spring or Clean Breeze scent. Both varieties are available in two formulas for use in regular and HE washing machines.",
    videoLink: USE_LOCAL_MEDIA_SERVER
    ? `${LOCAL_MEDIA_SERVER_URL}/video/tide_commercial_video.mp4`
    : "e750629e571189e793994dec33a214ea",
    spatialVideoLink: 
    USE_LOCAL_MEDIA_SERVER
    ? `${LOCAL_MEDIA_SERVER_URL}/spatial/TideConcept24_atmos_spatial.mov` :
    "https://assets.xrtrailers.xyz/spatial/TideConcept24_atmos_spatial.mov",
    targetLink:
    "https://tide.com/en-us/shop/type/liquid/tide-original-liquid",
    editor: undefined,
})