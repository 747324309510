import React, { useState, useRef, useEffect } from "react";
import "./styles/Form.css";
import "boxicons/css/boxicons.min.css";
import { RootState, actions } from "../../store/root.store";
import {
  UserSignupFormValues,
  validateSignupForm,
  UserSignupFormValidValues,
} from "./UserFormValidator";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../store/root.store";
import { useNav } from "../../utils/navigation";

/**
 * Signup form component for new user registration.
 *
 * @component
 * @example
 * return (
 *   <UserSignupForm  />
 * )
 *
 */

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string;

const UserSignupForm: React.FC = () => {
  const nameRef = useRef<HTMLInputElement>(null);
  const [values, setValues] = useState<UserSignupFormValues>({
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    username: "",
    password: "",
    confirmPassword: "",
  });
  const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false);
  const [focus, setFocus] = useState<
    Partial<Record<keyof UserSignupFormValues, boolean>>
  >({});
  const [hidePassword, setHidePassword] = useState<boolean>(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState<boolean>(true);
  const [errors, setErrors] = useState<Partial<UserSignupFormValues>>({});
  const [isValid, setIsValid] = useState<Partial<UserSignupFormValidValues>>(
    {}
  );
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const { goToSignupConfirmationPage } = useNav();
  const loading = useAppSelector((state: RootState) => state.auth.loading);
  const error = useAppSelector((state: RootState) => state.auth.error);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleRecaptchaChange = (token: string | null) => {
    setRecaptchaToken(token);
  };

  const handleEyeIconClick = () => {
    setHidePassword(!hidePassword);
  };

  const handleConfirmEyeIconClick = () => {
    setHideConfirmPassword(!hideConfirmPassword);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const validationResult = validateSignupForm(values);
    console.log(validationResult);

    let valid =
      Object.keys(validationResult.errors).length === 0 &&
      agreeToTerms &&
      recaptchaToken !== null;

    if (valid) {
      handleSignup();
    } else {
      setShowErrors(true);
      setErrors(validationResult.errors);
      console.log("Invalid form");
    }
  };

  const handleSignup = async () => {
    const res = await actions.auth.standardSignup(
      values.email,
      values.password,
      values.username,
      "STANDARD",
      values.firstName,
      values.lastName
    );

    if (res.success && !loading) {
      goToSignupConfirmationPage("standard");
    }
  };

  useEffect(() => {
    actions.auth.clearAuthError();

    if (nameRef.current) {
      nameRef.current.focus();
    }

    return () => {
      actions.auth.clearAuthError();
    };
  }, []);

  useEffect(() => {
    const validationResult = validateSignupForm(values);
    setErrors(validationResult.errors);
    setIsValid(validationResult.isValid);
    console.log(validationResult);
  }, [
    values,
    values.firstName,
    values.lastName,
    values.email,
    values.password,
    values.confirmPassword,
  ]);

  useEffect(() => {
    if (Object.values(focus).some((val) => val)) {
      setShowErrors(false);
    }
  }, [focus]);

  return (
    <div className="login-form">
      <header>Sign Up</header>
      <span
        id="eidnote"
        className={`auth-message ${
          error !== null && !loading ? "show" : "hide"
        }`}
      >
        {error}
      </span>
      <form onSubmit={handleSubmit}>
        <div className="field input-field">
          <input
            type="text"
            ref={nameRef}
            required
            aria-invalid={errors.firstName ? "false" : "true"}
            aria-describedby="fnidnote"
            autoComplete="off"
            autoCorrect="off"
            name="firstName"
            placeholder="Enter First Name"
            onChange={handleChange}
            value={values.firstName}
            onFocus={() => setFocus((prev) => ({ ...prev, firstName: true }))}
            onBlur={() => setFocus((prev) => ({ ...prev, firstName: false }))}
          />
          <span
            id="fnidnote"
            className={`instructions ${
              (focus.firstName && values.firstName && !isValid.firstName) ||
              (values.firstName && showErrors)
                ? "show"
                : "hide"
            }`}
          >
            {errors.firstName}
          </span>
          {values.firstName && !errors.firstName && (
            <i className="bx bx-check-circle valid-icon"></i>
          )}
          {values.firstName && errors.firstName && (
            <i className="bx bx-x-circle invalid-icon"></i>
          )}
        </div>
        <div className="field input-field">
          <input
            type="text"
            required
            aria-invalid={errors.lastName ? "false" : "true"}
            aria-describedby="lnidnote"
            autoComplete="off"
            autoCorrect="off"
            name="lastName"
            placeholder="Enter Last Name"
            onChange={handleChange}
            value={values.lastName}
            onFocus={() => setFocus((prev) => ({ ...prev, lastName: true }))}
            onBlur={() => setFocus((prev) => ({ ...prev, lastName: false }))}
          />
          <span
            id="lnidnote"
            className={`instructions ${
              (focus.lastName && values.lastName && !isValid.lastName) ||
              (values.lastName && showErrors)
                ? "show"
                : "hide"
            }`}
          >
            {errors.lastName}
          </span>
          {values.lastName && !errors.lastName && (
            <i className="bx bx-check-circle valid-icon"></i>
          )}
          {values.lastName && errors.lastName && (
            <i className="bx bx-x-circle invalid-icon"></i>
          )}
        </div>
        <div className="field input-field">
          <input
            type="text"
            required
            aria-invalid={errors.email ? "false" : "true"}
            aria-describedby="eidnote"
            autoComplete="off"
            autoCorrect="off"
            name="email"
            placeholder="Enter Email"
            onChange={handleChange}
            value={values.email}
            onFocus={() => setFocus((prev) => ({ ...prev, email: true }))}
            onBlur={() => setFocus((prev) => ({ ...prev, email: false }))}
          />
          <span
            id="eidnote"
            className={`instructions ${
              (focus.email && values.email && !isValid.email) ||
              (values.email && showErrors)
                ? "show"
                : "hide"
            }`}
          >
            {errors.email}
          </span>
          {values.email && isValid.email && (
            <i className="bx bx-check-circle valid-icon"></i>
          )}
          {values.email && !isValid.email && (
            <i className="bx bx-x-circle invalid-icon"></i>
          )}
        </div>
        <div className="field input-field">
          <input
            type="text"
            required
            aria-invalid={errors.confirmEmail ? "false" : "true"}
            aria-describedby="ceidnote"
            autoComplete="off"
            autoCorrect="off"
            name="confirmEmail"
            placeholder="Confirm Email"
            onChange={handleChange}
            value={values.confirmEmail}
            onFocus={() =>
              setFocus((prev) => ({ ...prev, confirmEmail: true }))
            }
            onBlur={() =>
              setFocus((prev) => ({ ...prev, confirmEmail: false }))
            }
          />
          <span
            id="ceidnote"
            className={`instructions ${
              (focus.confirmEmail &&
                values.confirmEmail &&
                !isValid.confirmEmail) ||
              (values.confirmEmail && showErrors)
                ? "show"
                : "hide"
            }`}
          >
            {errors.confirmEmail}
          </span>
          {values.confirmEmail && isValid.confirmEmail && (
            <i className="bx bx-check-circle valid-icon"></i>
          )}
          {values.confirmEmail && !isValid.confirmEmail && (
            <i className="bx bx-x-circle invalid-icon"></i>
          )}
        </div>
        <div className="field input-field">
          <input
            type="text"
            required
            aria-invalid={errors.username ? "false" : "true"}
            aria-describedby="uidnote"
            autoComplete="off"
            autoCorrect="off"
            name="username"
            placeholder="Create Username"
            onChange={handleChange}
            value={values.username}
            onFocus={() => setFocus((prev) => ({ ...prev, username: true }))}
            onBlur={() => setFocus((prev) => ({ ...prev, username: false }))}
          />
          <span
            id="uidnote"
            className={`instructions ${
              (focus.username && values.username && !isValid.username) ||
              (values.username && showErrors)
                ? "show"
                : "hide"
            }`}
          >
            {errors.username}
          </span>
          {values.username && isValid.username && (
            <i className="bx bx-check-circle valid-icon"></i>
          )}
          {values.username && !isValid.username && (
            <i className="bx bx-x-circle invalid-icon"></i>
          )}
        </div>
        <div className="field input-field">
          <input
            type={hidePassword ? "password" : "text"}
            required
            aria-invalid={errors.password ? "false" : "true"}
            aria-describedby="pwdidnote"
            autoComplete="off"
            autoCorrect="off"
            name="password"
            placeholder="Create Password"
            onChange={handleChange}
            value={values.password}
            onFocus={() => setFocus((prev) => ({ ...prev, password: true }))}
            onBlur={() => setFocus((prev) => ({ ...prev, password: false }))}
          />
          <i
            className={`bx eye-icon ${hidePassword ? `bx-hide` : `bx-show`} ${
              values.password ? `move-eye-icon` : `default-eye-icon`
            }`}
            onClick={handleEyeIconClick}
          ></i>
          <span
            id="pwdidnote"
            className={`instructions ${
              (focus.password && values.password && !isValid.password) ||
              (values.password && showErrors)
                ? "show"
                : "hide"
            }`}
          >
            {errors.password}
          </span>
          {values.password && !errors.password && (
            <i className="bx bx-check-circle valid-icon"></i>
          )}
          {values.password && errors.password && (
            <i className="bx bx-x-circle invalid-icon"></i>
          )}
        </div>
        <div className="field input-field">
          <input
            type={hideConfirmPassword ? "password" : "text"}
            required
            aria-invalid={errors.confirmPassword ? "false" : "true"}
            aria-describedby="cpwdidnote"
            autoComplete="off"
            autoCorrect="off"
            name="confirmPassword"
            placeholder="Confirm Password"
            onChange={handleChange}
            value={values.confirmPassword}
            onFocus={() =>
              setFocus((prev) => ({ ...prev, confirmPassword: true }))
            }
            onBlur={() =>
              setFocus((prev) => ({ ...prev, confirmPassword: false }))
            }
          />
          <i
            className={`bx eye-icon ${hideConfirmPassword ? `bx-hide` : `bx-show`} ${
              values.confirmPassword ? `move-eye-icon` : `default-eye-icon`
            }`}
            onClick={handleConfirmEyeIconClick}
          ></i>
          <span
            id="cpwdidnote"
            className={`instructions ${
              (focus.confirmPassword &&
                values.confirmPassword &&
                !isValid.confirmPassword) ||
              (values.confirmPassword && showErrors)
                ? "show"
                : "hide"
            }`}
          >
            {errors.confirmPassword}
          </span>
          {values.confirmPassword && !errors.confirmPassword && (
            <i className="bx bx-check-circle valid-icon"></i>
          )}
          {values.confirmPassword && errors.confirmPassword && (
            <i className="bx bx-x-circle invalid-icon"></i>
          )}
        </div>
        <div className="checkbox-field">
          <input
            type="checkbox"
            name="agreeToTerms"
            checked={agreeToTerms}
            onChange={() => setAgreeToTerms(!agreeToTerms)}
            required
          />
          <div className="terms-link">
            <span>
              Agree to <a href="#">Terms & Conditions</a>
            </span>
          </div>
        </div>
        <div className="recaptcha-container">
          <ReCAPTCHA
            sitekey={RECAPTCHA_SITE_KEY}
            onChange={handleRecaptchaChange}
          ></ReCAPTCHA>
          <span
            id="reidnote"
            className={`instructions ${
              recaptchaToken === null ? "show" : "hide"
            }`}
          >
            Please Verify You Are Human To Continue.
          </span>
        </div>
        <div className="field button-field">
          <button type="submit">Sign Up</button>
        </div>
        <div className="form-link">
          <span>
            Already have an account?{" "}
            <Link to={`/user-login`} className="login-link">
              Login
            </Link>
          </span>
        </div>
      </form>
    </div>
  );
};

export default UserSignupForm;
