import { actions } from "../store/root.store";

export const useNav = () => {
  const goToHomePage = () => {
    actions.auth.navigateTo("/");
  };

  const goToUserLoginPage = () => {
    actions.auth.navigateTo("/user-login");
  };

  const goToUserSignupPage = () => {
    actions.auth.navigateTo("/user-signup");
  };

  const goToDetailsPage = (id: number) => {
    actions.auth.navigateTo(`/details/${id}`);
  };

  const goToSuperUserPage = () => {
    actions.auth.navigateTo("/super-user");
  };

  const goToMorePage = (category: string, subCategory: string) => {
    actions.auth.navigateTo(`/more/${category}/${subCategory}`);
  };

  const goToSearchPage = (searchTerm: string | undefined) => {
    actions.auth.navigateTo(`/search?searchTerm=${searchTerm}`);
  };

  const goToUserPasswordResetPage = (role: string) => {
    actions.auth.navigateTo(`/user-password-reset/${role}`);
  };

  const goToUserForgotPasswordPage = (role: string) => {
    actions.auth.navigateTo(`/user-forgot-password/${role}`);
  };

  const goToAdminLoginPage = () => {
    actions.auth.navigateTo("/admin-login");
  };

  const goToAdminPage = () => {
    actions.auth.navigateTo("/admin");
  };

  const redirectToAfterLoginPage = () => {
    const redirectAfterLogin = sessionStorage.getItem("redirectAfterLogin");
    if (!redirectAfterLogin) return;
    sessionStorage.removeItem("redirectAfterLogin");
    actions.auth.navigateTo(redirectAfterLogin);
  };

  const goToSignupConfirmationPage = (role: string) => {
    actions.auth.navigateTo(`/signup-confirmation/${role}`);
  };

  const openFullPreviewPage = (page: string) => {
    const pageUrl = page === "/" ? "home" : page.substring(1);
    window.open(`http://localhost:3000/preview-page/${pageUrl}`); // replace with host env url
  };

  const openNewTab = (url: string) => {
    window.open(url);
  };

  return {
    goToHomePage,
    goToUserLoginPage,
    goToUserSignupPage,
    goToDetailsPage,
    goToSuperUserPage,
    goToMorePage,
    goToSearchPage,
    goToUserPasswordResetPage,
    goToUserForgotPasswordPage,
    goToAdminLoginPage,
    redirectToAfterLoginPage,
    goToAdminPage,
    goToSignupConfirmationPage,
    openFullPreviewPage,
    openNewTab,
  };
};
