import React, { useEffect, useState } from "react";
import "./Header.css";
import { Link, useLocation } from "react-router-dom";
import "boxicons/css/boxicons.min.css";
import { useNav } from "../../utils/navigation";
import { RootState, actions, useAppSelector } from "../../store/root.store";
import { DebounceInput } from "react-debounce-input";
import logo from "../../assets/logo/xrtrailers_logo_02_04.png";
import useWindowSize from "../../utils/useWindowSize";
import RightSideNavBar from "../RightSideNavBar";

/**
 * Header component with a logo, login/sign-up link, and search bar.
 *
 * @component
 * @example
 * return (
 *   <Header />
 * )
 */

const Header: React.FC = () => {
  const { width } = useWindowSize();
  const { goToSearchPage } = useNav();
  const isAuthenticated = useAppSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [hideSearchBar, setHideSearchBar] = useState<boolean>(false);
  const location = useLocation();

  const handleLogoutClick = () => {
    actions.auth.logout();
  };

  const handleSearchIconClick = () => {
    goToSearchPage(searchTerm);
  };

  const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearchIconClick();
    }
  };

  useEffect(() => {}, [isAuthenticated]);

  useEffect(() => {
    if (location.pathname === "/search") {
      setHideSearchBar(true);
    } else {
      setSearchTerm("");
      setHideSearchBar(false);
    }
  }, [location.pathname]);

  return (
    <div className="component-container" style={{paddingTop: 0}}>
      <div className="header">
        <Link to="/">
          <img src={logo} className="logo" alt="logo" />
        </Link>
        {width < 600 ? (
          <>
            <RightSideNavBar></RightSideNavBar>
          </>
        ) : (
          <div className="login-search">
            <Link to="/user-profile" style={{ width: "10%" }}>
              <img
                src="https://t4.ftcdn.net/jpg/00/64/67/27/360_F_64672736_U5kpdGs9keUll8CRQ3p3YaEv2M6qkVY5.jpg"
                alt="default profile"
                width="100%"
              />
            </Link>
            <div>
              {isAuthenticated ? (
                <div className="user-link">
                  <span
                    onClick={handleLogoutClick}
                    style={{ cursor: "pointer" }}
                  >
                    Logout
                  </span>
                </div>
              ) : (
                <>
                  <Link to="/user-login" className="user-link">
                    <span>Login</span>
                  </Link>
                  {" / "}
                  <Link to="/user-signup" className="user-link">
                    <span>Sign Up</span>
                  </Link>
                </>
              )}
            </div>
            {!hideSearchBar && (
              <div className="field">
                <DebounceInput
                  placeholder="Search"
                  value={searchTerm}
                  minLength={1}
                  debounceTimeout={0}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSearchTerm(e.target.value)
                  }
                  onKeyDown={handleSearchKeyDown}
                ></DebounceInput>
                <i
                  className="bx bx-search search-icon"
                  onClick={handleSearchIconClick}
                ></i>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
