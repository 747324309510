import React, { useRef, useState, useEffect } from "react";
import "./UserPasswordReset.css";
import "../../components/Forms/styles/Form.css";
import { RootState, actions, useAppSelector } from "../../store/root.store";
import { useNav } from "../../utils/navigation";
import {
  UserPwdResetFormValues,
  UserPwdResetFormValidValues,
  validatePwdResetForm,
} from "./UserPwdResetFormValidator";
import { OtpInput } from "../../components";
import ReCAPTCHA from "react-google-recaptcha";
import { useParams } from "react-router-dom";

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string;

const UserPasswordReset: React.FC = () => {
  const { role } = useParams<{ role: string }>();
  const email = useAppSelector((state) => state.auth.email);
  const fieldRef = useRef<HTMLInputElement>(null);
  const [otp, setOtp] = useState<string>("");
  const [values, setValues] = useState<UserPwdResetFormValues>({
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState<Partial<UserPwdResetFormValues>>({});
  const [focus, setFocus] = useState<
    Partial<Record<keyof UserPwdResetFormValues, boolean>>
  >({});
  const [isValid, setIsValid] = useState<Partial<UserPwdResetFormValidValues>>(
    {}
  );
  const [verified, setVerified] = useState<boolean>(false);
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(true);
  const [timerCount, setTimer] = useState<number>(60);
  const [passwordChanged, setPasswordChanged] = useState<boolean>(false);
  const [hidePassword, setHidePassword] = useState<boolean>(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState<boolean>(true);
  const { goToUserLoginPage } = useNav();
  const loading = useAppSelector((state: RootState) => state.auth.loading);
  const error = useAppSelector((state: RootState) => state.auth.error);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

  const handleRecaptchaChange = (token: string | null) => {
    setRecaptchaToken(token);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleOtpChange = (value: string) => setOtp(value);

  const handlePasswordUpdated = (e: React.FormEvent) => {
    e.preventDefault();
    goToUserLoginPage();
  };

  const handleResendOtp = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (disable || !recaptchaToken) return;
    actions.auth.requestResetPassword(
      email as string,
      recaptchaToken,
      role as string
    );
    setTimer(60);
    setDisable(true);
  };

  const handleVerification = (e: React.FormEvent) => {
    e.preventDefault();

    if (otp.length === 6) setVerified(true);
  };

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();

    const validationResult = validatePwdResetForm(values);

    let valid = Object.keys(validationResult.errors).length === 0;

    if (valid) {
      const res = await actions.auth.resetPassword(
        email as string,
        otp,
        values.password,
        role as string
      );

      if (res.success) {
        setPasswordChanged(true);
      } else {
        setVerified(false);
      }
    } else {
      setShowErrors(true);
      setErrors(validationResult.errors);
    }
  };

  const handleEyeIconClick = () => {
    setHidePassword(!hidePassword);
  };

  const handleConfirmEyeIconClick = () => {
    setHideConfirmPassword(!hideConfirmPassword);
  };

  useEffect(() => {
    actions.auth.clearAuthError();

    if (fieldRef.current) {
      fieldRef.current.focus();
    }

    return () => {
      actions.auth.clearAuthError();
    };
  }, []);

  useEffect(() => {
    const validationResult = validatePwdResetForm(values);
    setErrors(validationResult.errors);
    setIsValid(validationResult.isValid);
  }, [values, values.password, values.confirmPassword]);

  useEffect(() => {
    if (Object.values(focus).some((val) => val)) {
      setShowErrors(false);
    }
  }, [focus]);

  useEffect(() => {
    let interval = setInterval(() => {
      setTimer((lastTimerCount) => {
        lastTimerCount <= 1 && clearInterval(interval);
        if (lastTimerCount <= 1) setDisable(false);
        if (lastTimerCount <= 0) return lastTimerCount;
        return lastTimerCount - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [timerCount]);

  if (!verified) {
    return (
      <div className="form-container">
        <div className="login-form">
          <header>Email Verification</header>
          <form onSubmit={handleVerification}>
            <div className="otp-instructions">
              <span className="instructions">
                We have sent a six-digit code to{" "}
                <span style={{ color: "#007bff" }}>{email}</span>.
              </span>
            </div>
            <div className="otp-container">
              <OtpInput
                value={otp}
                valueLength={6}
                onChange={handleOtpChange}
              ></OtpInput>
            </div>
            <div className="field button-field">
              <button type="submit">Verify Account</button>
            </div>
            <div className="form-link">
              <span>
                Did not receive code?{" "}
                {disable ? (
                  <>Resend code in {timerCount}s</>
                ) : (
                  <>
                    <button onClick={(e) => handleResendOtp(e)}>
                      Resend code
                    </button>
                  </>
                )}
              </span>
            </div>
            <div className="recaptcha-container">
              <ReCAPTCHA
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={handleRecaptchaChange}
              ></ReCAPTCHA>
              <span
                id="reidnote"
                className={`instructions ${
                  recaptchaToken === null ? "show" : "hide"
                }`}
              >
                Please verify you are human to resend code.
              </span>
            </div>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="form-container">
      <div className="login-form">
        <header>Change password</header>
        {passwordChanged ? (
          <>
            <form onSubmit={handlePasswordUpdated}>
              <span className="instructions">
                New password successfully updated.
              </span>
              <div className="field button-field">
                <button type="submit">Return to login</button>
              </div>
            </form>
          </>
        ) : (
          <>
            <span
              id="eidnote"
              className={`auth-message ${
                error !== null && !loading ? "show" : "hide"
              }`}
            >
              {error}
            </span>
            <form onSubmit={handleResetPassword}>
              <div className="field input-field">
                <input
                  type={hidePassword ? "password" : "text"}
                  required
                  aria-invalid={errors.password ? "false" : "true"}
                  aria-describedby="pwdidnote"
                  autoComplete="off"
                  autoCorrect="off"
                  name="password"
                  placeholder="Create New Password"
                  onChange={handleChange}
                  value={values.password}
                  onFocus={() =>
                    setFocus((prev) => ({ ...prev, password: true }))
                  }
                  onBlur={() =>
                    setFocus((prev) => ({ ...prev, password: false }))
                  }
                />
                <i
                  className={`bx eye-icon ${
                    hidePassword ? `bx-hide` : `bx-show`
                  } ${values.password ? `move-eye-icon` : `default-eye-icon`}`}
                  onClick={handleEyeIconClick}
                ></i>
                <span
                  id="pwdidnote"
                  className={`instructions ${
                    (focus.password && values.password && !isValid.password) ||
                    (values.password && showErrors)
                      ? "show"
                      : "hide"
                  }`}
                >
                  {errors.password}
                </span>
                {values.password && !errors.password && (
                  <i className="bx bx-check-circle valid-icon"></i>
                )}
                {values.password && errors.password && (
                  <i className="bx bx-x-circle invalid-icon"></i>
                )}
              </div>
              <div className="field input-field">
                <input
                  type={hideConfirmPassword ? "password" : "text"}
                  required
                  aria-invalid={errors.confirmPassword ? "false" : "true"}
                  aria-describedby="cpwdidnote"
                  autoComplete="off"
                  autoCorrect="off"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  onChange={handleChange}
                  value={values.confirmPassword}
                  onFocus={() =>
                    setFocus((prev) => ({ ...prev, confirmPassword: true }))
                  }
                  onBlur={() =>
                    setFocus((prev) => ({ ...prev, confirmPassword: false }))
                  }
                />
                <i
                  className={`bx eye-icon ${
                    hideConfirmPassword ? `bx-hide` : `bx-show`
                  } ${
                    values.confirmPassword
                      ? `move-eye-icon`
                      : `default-eye-icon`
                  }`}
                  onClick={handleConfirmEyeIconClick}
                ></i>
                <span
                  id="cpwdidnote"
                  className={`instructions ${
                    (focus.confirmPassword &&
                      values.confirmPassword &&
                      !isValid.confirmPassword) ||
                    (values.confirmPassword && showErrors)
                      ? "show"
                      : "hide"
                  }`}
                >
                  {errors.confirmPassword}
                </span>
                {values.confirmPassword && !errors.confirmPassword && (
                  <i className="bx bx-check-circle valid-icon"></i>
                )}
                {values.confirmPassword && errors.confirmPassword && (
                  <i className="bx bx-x-circle invalid-icon"></i>
                )}
              </div>
              <div className="field button-field">
                <button type="submit">Change Password</button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default UserPasswordReset;
