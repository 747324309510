import React, { useState } from "react";
import "./PreUploadedSelector.css";

interface PreUploadedSelectorProps {
  title: string;
  handleAssetChange: (asset: any) => void;
  assets: any[];
  isVideo: boolean;
}

const PreUploadedSelector: React.FC<PreUploadedSelectorProps> = ({
  handleAssetChange,
  title,
  assets,
  isVideo,
}) => {
  const [selected, setSelected] = useState<string>("");
  const [openSelector, setOpenSelector] = useState<boolean>(false);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!openSelector) {
      setOpenSelector(true);
    }
  };

  const handleChange = (asset: any) => {
    setSelected(asset.url);
    setOpenSelector(false);
    handleAssetChange(asset);
  };

  const clearSelected = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setSelected("");
    handleAssetChange("");
  };

  return (
    <>
      <h4>{title}</h4>
      <div
        className={`image-input  ${selected ? "has-file" : ""}`}
        onClick={handleClick}
      >
        <label>
          {selected !== "" ? (
            <div className="remove-file">
              <i
                className="bx bx-x bx-sm remove-file-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  clearSelected(e);
                }}
              ></i>
              {selected}
            </div>
          ) : (
            <>
              <>
                {isVideo ? (
                  <i className="bx bx-video-plus bx-md"></i>
                ) : (
                  <i className="bx bx-image-add bx-md"></i>
                )}
                Browse
              </>
              {openSelector && (
                <>
                  <div className="asset-selector">
                    <div className="asset-selector-header">
                      <div style={{ color: "#fff" }}>{title}</div>
                      <i
                        className="bx bx-x bx-sm asset-close"
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpenSelector(false);
                        }}
                      ></i>
                    </div>
                    {assets.map((asset, index) => (
                      <div
                        key={index}
                        className="asset-option"
                        onClick={() => handleChange(asset)}
                      >
                        {isVideo ? (
                          <video
                            src={asset.url ? asset.url : asset.videoId}
                            className="asset-preview"
                            controls
                          />
                        ) : (
                          <img
                            src={asset.url}
                            alt={`Asset ${index}`}
                            className="asset-preview"
                          />
                        )}
                        <p className="asset-name">
                          {isVideo
                            ? asset.url
                              ? asset.url
                              : asset.videoId
                            : asset.alt
                            ? asset.alt
                            : asset.url}
                        </p>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </>
          )}
        </label>
      </div>
    </>
  );
};

export default PreUploadedSelector;
