import { PayloadAction } from "@reduxjs/toolkit";
import { AdminState } from "./admin.entity";
import { Admin } from "../../types/types";

const adminReducers = {
  "admins/load": (state: AdminState, action: PayloadAction<Admin[]>) => {
    state.admins = action.payload;
  },
  "admins/admin": (state: AdminState, action: PayloadAction<Admin>) => {
    state.admin = action.payload;
  },
};

export default adminReducers;
